const macOSPlatforms = new Set(['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']);
const isMacOS = () => macOSPlatforms.has(navigator.platform);

const isWindows = () => navigator.platform.includes('Win');

const isAndroid = () => navigator.userAgent.toLowerCase().includes('android');

const isIOS = () =>
  (/iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ||
    (navigator.userAgent.includes('Mobile') && navigator.userAgent.includes('Safari'))) &&
  !window.MSStream;

const isMobile = () => isAndroid() || isIOS();

const resolvePlatform = () => {
  if (isMacOS()) {
    return 'macos' as const;
  }
  if (isIOS()) {
    return 'ios' as const;
  }
  if (isAndroid()) {
    return 'android' as const;
  }
  if (isWindows()) {
    return 'windows' as const;
  }
};

export type Platform = ReturnType<typeof resolvePlatform>;

export { isIOS, isAndroid, isMacOS, isWindows, isMobile, resolvePlatform };
