import { FormattedMessage } from 'react-intl';
import { type UserProfile } from '../../../utils/api/schema/profileSchema';
import SectionTitle from '../layout/SectionTitle';
import BillingInfoBlock from './BillingInfoBlock';
import DiiaBlock from './DiiaBlock';

interface BillingInforSectionProps {
  profile: UserProfile;
  premiumLink: string;
  coupon: string | undefined;
}

const BillingInforSection = ({ profile, premiumLink, coupon }: BillingInforSectionProps) => (
  <>
    <SectionTitle>
      <FormattedMessage id="profile.billingInfoSection.title" defaultMessage="Billing info" />
    </SectionTitle>
    <BillingInfoBlock profile={profile} premiumLink={premiumLink} coupon={coupon} />
    <DiiaBlock profile={profile} />
  </>
);

export default BillingInforSection;
