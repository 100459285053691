import React, { useState } from 'react';
import t from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useCardFormListeners, onChangeMethodCardSubmit } from '../../../../utils/payments/useCardFormListeners';
import { desktopFieldStyles } from '../../../../utils/payments/fieldComponentOptions';
import TabsSelector from '../../../common/TabsSelector';
import SubmitButton from '../../../Enter/fields/SubmitButton';
import { genericErrorMessage } from '../../../../utils/lang/commonErrors';
import PaymentRequestForm from './ChangeMethodDialogComponents/PaymentRequestForm';
import closeDialogIcon from '../../../../../assets/icons/close-dialog-icon.svg';
import styles from './ChangeMethodDialog.scss';

const ChangeMethodDialog = ({ profile, isDialogShown, onCancel }) => {
  const intl = useIntl();
  const [availableMethods, setAvailableMethod] = useState([
    intl.formatMessage({
      id: 'profile.billing.changeMethodModal.creditCardLabel',
      defaultMessage: 'Credit Card',
      description: 'Label of the payment method selector for credit card',
    }),
  ]);

  const [currentMethod, setCurrentMethod] = useState(availableMethods[0]);
  const onCategorySelected = (category) => setCurrentMethod(category);
  const trainClasses = `${styles.train} ${styles[`trainPos${availableMethods.indexOf(currentMethod)}`]}`;

  const [cardValidation, setCardValidation] = useState({
    card: { empty: true, complete: false, error: false },
    expiration: { empty: true, complete: false, error: false },
    cvc: { empty: true, complete: false, error: false },
  });
  const { handleNumberChange, handleExpirationChange, handleCSCChange } = useCardFormListeners(setCardValidation);

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const createStripeFieldOptions = (placeholder) => ({
    placeholder,
    style: desktopFieldStyles,
    disabled: isLoading,
  });

  const stripe = useStripe();
  const elements = useElements();
  const onCardFormSubmit = (e) => {
    e.preventDefault();

    if (cardValidation.card.complete && cardValidation.expiration.complete && cardValidation.cvc.complete) {
      setLoading(true);
      onChangeMethodCardSubmit(elements, stripe).then(
        () => location.reload(),
        () => {
          setLoading(false);
          setError(intl.formatMessage(genericErrorMessage));
        },
      );
    } else {
      setError(
        intl.formatMessage({
          id: 'profile.billing.changeMethodModal.error.requiredFieldsEmpty',
          defaultMessage: 'Please fill out the form first',
          description: 'Error shown on credit card form to indicate that not all required fields are filled',
        }),
      );
    }
  };

  const onGooglePayAvailable = () =>
    setAvailableMethod((methods) => (methods.includes('GooglePay') ? methods : [...methods, 'GooglePay']));
  const onApplePayAvailable = () =>
    setAvailableMethod((methods) => (methods.includes('ApplePay') ? methods : [...methods, 'ApplePay']));

  return (
    <>
      {isDialogShown ? (
        <div className={styles.overlay}>
          <div className={styles.dialog}>
            <button className={styles.closeDialogButton} onClick={onCancel}>
              <img src={closeDialogIcon} width="14" height="14" alt="" />
            </button>

            <h3 className={styles.title}>
              <FormattedMessage
                id="profile.billing.changeMethodModal.title"
                defaultMessage="Change a payment method"
                description="Title of modal, where user can update payment method charged for their subscription"
              />
            </h3>

            <div className={styles.wrapper}>
              <TabsSelector
                currentCategory={currentMethod}
                categories={availableMethods}
                className={styles.methodSelector}
                disabled={isLoading}
                onCategorySelected={onCategorySelected}
              />

              <div className={trainClasses}>
                <form className={styles.cardForm} onSubmit={onCardFormSubmit}>
                  <div
                    className={`${styles.cardFormField} ${styles.cardFormNumberField} ${
                      cardValidation.card.error ? styles.cardFormError : ''
                    }`}
                  >
                    <CardNumberElement
                      id="credit-card-number-input"
                      options={createStripeFieldOptions(
                        intl.formatMessage({
                          id: 'profile.billing.changeMethodModal.cardNumberFieldPlaceholder',
                          defaultMessage: 'Card Number',
                          description: 'Placeholder of the card number field',
                        }),
                      )}
                      onChange={handleNumberChange}
                    />
                  </div>

                  <div
                    className={`${styles.cardFormField} ${cardValidation.expiration.error ? styles.cardFormError : ''}`}
                  >
                    <CardExpiryElement
                      id="credit-card-expiry-input"
                      options={createStripeFieldOptions(
                        intl.formatMessage({
                          id: 'profile.billing.changeMethodModal.cardExpirationFieldPlaceholder',
                          defaultMessage: 'Exp. Date',
                          description: 'Placeholder of the card expiration date field',
                        }),
                      )}
                      onChange={handleExpirationChange}
                    />
                  </div>

                  <div className={`${styles.cardFormField} ${cardValidation.cvc.error ? styles.cardFormError : ''}`}>
                    <CardCvcElement
                      id="credit-card-cvc-input"
                      options={createStripeFieldOptions(
                        intl.formatMessage({
                          id: 'profile.billing.changeMethodModal.cardCSCFieldPlaceholder',
                          defaultMessage: 'CSC Code',
                          description: 'Placeholder of the card CSC code field',
                        }),
                      )}
                      onChange={handleCSCChange}
                    />
                  </div>

                  <SubmitButton
                    isLoading={isLoading}
                    className={styles.cardFormSubmit}
                    type="button"
                    text={intl.formatMessage({
                      id: 'profile.billing.changeMethodModal.cardFormSubmit',
                      defaultMessage: 'Save',
                      description: 'Submit button saves inputted card details as the payment method for next charge',
                    })}
                  />

                  <span className={styles.cardFormEncryptionLabel}>
                    <FormattedMessage
                      id="profile.billing.changeMethodModal.encryptionText"
                      defaultMessage="Secure 128-bit SSL encrypted payment"
                      description="Text under the card form"
                    />
                  </span>

                  <span className={styles.cardFormErrorLabel}>{error}</span>
                </form>

                <PaymentRequestForm
                  profile={profile}
                  onApplePayAvailable={onApplePayAvailable}
                  onGooglePayAvailable={onGooglePayAvailable}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

ChangeMethodDialog.propTypes = {
  profile: t.object.isRequired,
  isDialogShown: t.bool,
  onCancel: t.func.isRequired,
};

export default ChangeMethodDialog;
