const BillingIcon = ({ isSelected }: { isSelected: boolean }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="crown">
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.28422 2.59299C1.62759 2.41561 2.04786 2.49761 2.29938 2.79105L6.52982 7.72656L9.27647 2.91993C9.42484 2.66028 9.70095 2.50004 10 2.50004C10.299 2.50004 10.5752 2.66028 10.7235 2.91993L13.4702 7.72656L17.7006 2.79105C17.9521 2.49761 18.3724 2.41561 18.7158 2.59299C19.0592 2.77036 19.2355 3.16055 19.1418 3.53549L16.6418 13.5355C16.549 13.9065 16.2157 14.1667 15.8333 14.1667H4.16667C3.78428 14.1667 3.45096 13.9065 3.35822 13.5355L0.858217 3.53549C0.764481 3.16055 0.940843 2.77036 1.28422 2.59299ZM3.33129 6.55592L4.81732 12.5H15.1827L16.6687 6.55592L13.966 9.70903C13.7897 9.91479 13.5243 10.0219 13.2545 9.9963C12.9847 9.97067 12.7443 9.81545 12.6098 9.58016L10 5.01301L7.3902 9.58016C7.25575 9.81545 7.01528 9.97067 6.7455 9.9963C6.47572 10.0219 6.21032 9.91479 6.03396 9.70903L3.33129 6.55592ZM3.33334 16.6667C3.33334 16.2065 3.70643 15.8334 4.16667 15.8334H15.8333C16.2936 15.8334 16.6667 16.2065 16.6667 16.6667C16.6667 17.1269 16.2936 17.5 15.8333 17.5H4.16667C3.70643 17.5 3.33334 17.1269 3.33334 16.6667Z"
        fill={isSelected ? '#ED3984' : '#212231'}
        fillOpacity={isSelected ? 1 : 0.64}
      />
    </g>
  </svg>
);

export default BillingIcon;
