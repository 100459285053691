import React, { useState, useEffect } from 'react';
import t from 'prop-types';
import { useStripe } from '@stripe/react-stripe-js';
import { onGooglePaySubmit, onApplePaySubmit } from '../../../../../utils/payments/useCardFormListeners';
import GooglePayIcon from './GooglePayIcon';
import ApplePayIcon from './ApplePayIcon';
import styles from './PaymentRequestForm.scss';

const PaymentRequestForm = ({ profile, onGooglePayAvailable, onApplePayAvailable }) => {
  const stripe = useStripe();

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [availableMethods, setAvailableMethods] = useState(null);

  useEffect(() => {
    if (!stripe?.paymentRequest) {
      return;
    }

    const request = stripe.paymentRequest({
      currency: profile.subscription.price.currency.toLowerCase(),
      country: 'CY',
      total: {
        amount: Number.parseInt(profile.subscription.price.amount),
        label: profile.subscription.name,
      },
      disableWallets: ['browserCard'],
    });

    request.canMakePayment().then((result) => {
      console.log('payment request available:', result);
      if (!result) {
        return;
      }

      setPaymentRequest(request);
      setAvailableMethods(result);

      if (result?.applePay) {
        onApplePayAvailable();
        request.on('paymentmethod', ({ paymentMethod, complete }) => {
          console.log('ApplePay payment method:', paymentMethod);
          onApplePaySubmit(stripe, paymentMethod, (status) => {
            complete(status);
            window.location.reload();
          });
        });
      } else if (result?.googlePay) {
        onGooglePayAvailable();
        request.on('paymentmethod', ({ paymentMethod, complete }) => {
          console.log('GooglePay payment method:', paymentMethod);
          onGooglePaySubmit(stripe, paymentMethod, (status) => {
            complete(status);
            window.location.reload();
          });
        });
      }
    });
  }, []);

  const onClick = (e) => {
    e.preventDefault();
    paymentRequest.show();
  };

  return (
    <div className={styles.wrapper}>
      {paymentRequest ? (
        <button className={styles.button} onClick={onClick}>
          {availableMethods?.applePay ? <ApplePayIcon isSelected /> : null}
          {availableMethods?.googlePay ? <GooglePayIcon whiteFont /> : null}
        </button>
      ) : null}
    </div>
  );
};

PaymentRequestForm.propTypes = {
  profile: t.object.isRequired,
  onGooglePayAvailable: t.func.isRequired,
  onApplePayAvailable: t.func.isRequired,
};

export default PaymentRequestForm;
