import { type UserProfile } from '../../../../utils/api/schema/profileSchema';
import setappIcon from '../../../../../assets/icons/oauth/setapp-oauth-icon.svg';
import appleIcon from '../../../../../assets/icons/oauth/apple-oauth-icon.svg';
import googleIcon from '../../../../../assets/icons/oauth/google-oauth-icon.svg';
import fecebookIcon from '../../../../../assets/icons/oauth/facebook-oauth-icon.svg';
import styles from './EmailLabel.scss';

type OAuthCredentialVendor = UserProfile['oauth_credentials']['vendor'];

interface EmailLabelProps {
  oAuthVendor: OAuthCredentialVendor;
  isSetappUser: boolean;
}

const EmailLabel = ({ oAuthVendor, isSetappUser }: EmailLabelProps) => {
  if (isSetappUser) {
    return (
      <div className={styles.wrapper}>
        <img src={setappIcon} width={32} height={32} alt="" />
      </div>
    );
  }

  switch (oAuthVendor) {
    case 'account_oauth_apple': {
      return (
        <div className={styles.wrapper}>
          <img src={appleIcon} width={32} height={32} alt="" />
        </div>
      );
    }

    case 'account_oauth_google': {
      return (
        <div className={styles.wrapper}>
          <img src={googleIcon} width={32} height={32} alt="" />
        </div>
      );
    }

    case 'account_oauth_facebook': {
      return (
        <div className={styles.wrapper}>
          <img src={fecebookIcon} width={32} height={32} alt="" />
        </div>
      );
    }

    default: {
      return null;
    }
  }
};

export default EmailLabel;
