/* eslint-disable react/no-unused-state -- will need debugging */
import React from 'react';
import t from 'prop-types';
import { logError } from '../../services/errorReporting';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logError(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: t.node,
};

export default ErrorBoundary;
