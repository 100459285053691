import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Spacer from '../common/Spacer';
import { validateLength } from '../../utils/validation';
import { sendSignInWithAccountCodeRequest } from '../../utils/api/accountApi';
import { genericErrorMessage } from '../../utils/lang/commonErrors';
import useQuery from '../../utils/useQuery';
import { isApiError } from '../../utils/api/clearApiClient';
import InputField from './fields/InputField';
import SubmitButton from './fields/SubmitButton';
import styles from './AccountCodeForm.scss';

const AccountCodeForm = () => {
  const history = useHistory();
  const query = useQuery();

  const [codeValue, setCodeValue] = useState('');
  const onCodeChange = (e) => setCodeValue(e.target.value);

  const [isLoading, setLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const intl = useIntl();

  const onSubmit = (code = '') => {
    if (code.length === 0) {
      code = codeValue;
    }

    setLoading(true);
    const codeError = validateLength(code, 8);

    if (codeError) {
      setFormErrors({
        code: codeError,
      });
      setLoading(false);
    } else {
      sendSignInWithAccountCodeRequest(code).then(
        () => {
          history.push('/profile');
        },
        (error) => {
          console.error('Sign Up request failed', error);
          if (isApiError(error) && (error.response.data.code === 3 || error.response.data.code === 16)) {
            setFormErrors({
              code: intl.formatMessage({
                id: 'accountCodeEnter.form.error.invalidCode',
                defaultMessage: 'Invalid code',
              }),
            });
          } else {
            setFormErrors({
              code: intl.formatMessage(genericErrorMessage),
            });
          }
          setLoading(false);
        },
      );
    }
  };

  useEffect(() => {
    if (query?.code?.length > 0) {
      setCodeValue(query.code);
      onSubmit(query.code);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <form className={styles.form}>
        <h2 className={styles.title}>
          <FormattedMessage
            id="accountCodeEnter.title"
            defaultMessage="Have an account code?"
            description="Title of the account code page"
          />
        </h2>
        <p className={styles.subtitle}>
          <FormattedMessage
            id="accountCodeEnter.subtitle"
            defaultMessage="If you already have an account, please, <link>Log In</link>"
            description="Subtitle of the account code page"
            values={{
              link: (chunk) => <Link to="/enter">{chunk}</Link>,
            }}
          />
        </p>

        <InputField
          hideEmptyError
          value={codeValue}
          error={formErrors.code}
          placeholder={intl.formatMessage({
            id: 'accountCodeEnter.form.placeholder',
            defaultMessage: 'Account code',
            description: 'Placeholder of the account code field within the account code form',
          })}
          onChange={onCodeChange}
        />
        {!formErrors.code && <Spacer height={20} />}

        <SubmitButton
          isLoading={isLoading}
          className={styles.cta}
          text={intl.formatMessage({
            id: 'accountCodeEnter.form.submitButton',
            defaultMessage: 'Enter',
            description: 'Content of the submit button of the the account code form',
          })}
          onSubmit={onSubmit}
        />
      </form>
    </div>
  );
};

export default AccountCodeForm;
