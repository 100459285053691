import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import LinkCurrentDeviceButton from '../LinkCurrentDeviceButton';
import { isMobile } from '../../../../../utils/platformResolver';
import styles from './DesktopInstallCol.scss';

interface MobilePairColProps {
  downloadToken: string;
  refreshDownloadToken: () => Promise<void>;
}

const MobilePairCol = ({ downloadToken, refreshDownloadToken }: MobilePairColProps) => {
  const [isMobileVersion] = useState(isMobile());

  return (
    <section className={styles.desktop}>
      <h3>
        <FormattedMessage
          id="profile.downloadModal.mobileSlide.pair.title"
          defaultMessage="2. Connect your account"
          description="Title of the pair column instructions for mobile slide of Download Modal"
        />
      </h3>
      <span>
        {isMobileVersion ? (
          <FormattedMessage
            id="profile.downloadModal.mobileSlide.pair.subtitleWithButton"
            defaultMessage="Connect your mobile device by tapping the button below"
            description="Subtitle of the pair column instructions for mobile slide of Download Modal, urges to click the button"
          />
        ) : (
          <FormattedMessage
            id="profile.downloadModal.mobileSlide.pair.subtitleWithQr"
            defaultMessage="Connect your mobile device by scanning QR code below"
            description="Subtitle of the pair column instructions for mobile slide of Download Modal, urges to scan a QR"
          />
        )}
      </span>

      {isMobileVersion ? (
        <LinkCurrentDeviceButton
          downloadToken={downloadToken}
          refreshDownloadToken={refreshDownloadToken}
          kind="secondary"
        >
          <FormattedMessage
            id="profile.downloadModal.desktopSlide.pair.pairButton"
            defaultMessage="Pair device"
            description="Button of the section for pairing device with the account, opens app via deeplink to authenticate user with the same account"
          />
        </LinkCurrentDeviceButton>
      ) : (
        <QRCodeSVG
          marginSize={4}
          value={`https://clearvpn.onelink.me/uPP4/1q2xto27?deep_link_sub1=${downloadToken}`}
          size={120}
          bgColor="#fff"
        />
      )}
    </section>
  );
};

export default MobilePairCol;
