import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { isMobile, resolvePlatform } from '../../../../utils/platformResolver';
import { lockBackgroundScroll, unlockBackgroundScroll } from '../../../../utils/lockBackgroundScroll';
import DesktopInstallCol from './DownloadModalComponents/DesktopInstallCol';
import MobileInstallCol from './DownloadModalComponents/MobileInstallCol';
import DesktopPairCol from './DownloadModalComponents/DesktopPairCol';
import MobilePairCol from './DownloadModalComponents/MobilePairCol';
import closeDialogIcon from '../../../../../assets/icons/close-dialog-icon.svg';
import styles from './DownloadModal.scss';

const DESKTOP_SLIDE = 'Desktop';
const MOBILE_SLIDE = 'Mobile';

interface DownloadModalProps {
  isOpen: boolean;
  onClose: () => void;
  downloadToken: string;
  requestNewDownloadToken: () => Promise<void>;
}

const DownloadModal = ({ isOpen, onClose, downloadToken, requestNewDownloadToken }: DownloadModalProps) => {
  const [platform] = useState(resolvePlatform());

  const [selectedSlide, setSelectedSlide] = useState(DESKTOP_SLIDE);

  useEffect(() => {
    if (isMobile()) {
      setSelectedSlide(MOBILE_SLIDE);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      lockBackgroundScroll();
    } else {
      unlockBackgroundScroll();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div aria-modal className={styles.overlay}>
      <div className={styles.dialog}>
        <button className={styles.closeDialogButton} title="Close download modal" onClick={onClose}>
          <img src={closeDialogIcon} alt="Close pop-up button" width="14" height="14" />
        </button>

        <div className={styles.container}>
          <h2 className={styles.title}>
            <FormattedMessage
              id="profile.downloadModal.title"
              defaultMessage="Get started in 2 steps"
              description="Title of the Download Modal"
            />
          </h2>

          <div className={styles.slideControls}>
            <label
              aria-selected={selectedSlide === DESKTOP_SLIDE}
              className={clsx(
                styles.slideControlsButton,
                selectedSlide === DESKTOP_SLIDE && styles.slideControlsButtonSelected,
              )}
            >
              <input
                type="radio"
                name="Download Modal Section"
                value={DESKTOP_SLIDE}
                onChange={() => {
                  setSelectedSlide(DESKTOP_SLIDE);
                }}
              />
              <FormattedMessage
                id="profile.downloadModal.desktopSlide.selector"
                defaultMessage="Desktop"
                description="Button that switches instructions to desktop app installation"
              />
            </label>
            <label
              aria-selected={selectedSlide === MOBILE_SLIDE}
              className={clsx(
                styles.slideControlsButton,
                selectedSlide === MOBILE_SLIDE && styles.slideControlsButtonSelected,
              )}
            >
              <input
                type="radio"
                name="Download Modal Section"
                value={MOBILE_SLIDE}
                onChange={() => {
                  setSelectedSlide(MOBILE_SLIDE);
                }}
              />
              <FormattedMessage
                id="profile.downloadModal.mobileSlide.selector"
                defaultMessage="Mobile"
                description="Button that switches instructions to mobile app installation"
              />
            </label>
          </div>

          <div className={styles.viewPort}>
            <div className={clsx(styles.slider, styles[`sliderPos${selectedSlide}`])}>
              <div
                className={styles.columns}
                aria-hidden={selectedSlide !== DESKTOP_SLIDE}
                data-testid="download-modal-desktop-content"
              >
                <DesktopInstallCol platform={platform} />
                <DesktopPairCol downloadToken={downloadToken} refreshDownloadToken={requestNewDownloadToken} />
              </div>

              <div
                className={styles.columns}
                aria-hidden={selectedSlide !== MOBILE_SLIDE}
                data-testid="download-modal-mobile-content"
              >
                <MobileInstallCol />
                <MobilePairCol downloadToken={downloadToken} refreshDownloadToken={requestNewDownloadToken} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
