import { collectCarryThroughParams } from '../ carryThroughParams';
import { type ClearApiRequestMeta } from '../../types/axios';
import { removeTokens, saveTokens } from '../auth';
import { CAPTCHA_KEY, getCaptchaToken } from '../captchaHelper';
import { clearApiClient } from './clearApiClient';

interface TokenResponse {
  access_token: string;
  refresh_token: string;
}

export const REFRESH_TOKEN_ROUTE = '/web/v1/account/refresh';

/**
 * Sends refresh token request and saves tokens to Cookies
 * @param {string} refreshToken
 */
export const sendTokenRefreshRequest = (refreshToken: string) =>
  clearApiClient.post<TokenResponse>(REFRESH_TOKEN_ROUTE, { refresh_token: refreshToken }).then(
    (response) => {
      saveTokens(response.data.access_token, response.data.refresh_token);
    },
    (error) => {
      throw error;
    },
  );

/**
 * Exchanges `permanent_token` for a pair of regular ones (`access_token` and `refresh_token`) and saves them to Cookies
 * @param {string} permanentToken
 */
export const sendPermTokenRequest = (permanentToken: string, requestMeta?: ClearApiRequestMeta) =>
  clearApiClient
    .post<TokenResponse>(
      '/web/v1/account/sign-in/perm-token',
      { perm_token: permanentToken },
      requestMeta && { meta: requestMeta },
    )
    .then((response) => {
      saveTokens(response.data.access_token, response.data.refresh_token);
    });

/**
 * Creates a permanent auth token for the current user
 * @returns permanent token, that can be exchanged via `/web/v1/account/sign-in/perm-token`
 */
export const sendCreatePermanentTokenRequest = (requestMeta?: ClearApiRequestMeta): Promise<string> =>
  clearApiClient
    .post<{ perm_token: string }>('/web/v1/account/session/perm-token', undefined, requestMeta && { meta: requestMeta })
    .then((response) => response.data.perm_token);

/**
 * Signs in a user via account code.
 */
export const sendSignInWithAccountCodeRequest = (code: string) =>
  clearApiClient.post<TokenResponse>('/web/v1/account/account-code/sign-in', { code }).then((response) => {
    saveTokens(response.data.access_token, response.data.refresh_token);
  });

/**
 * Gets a personalized deeplink to Diia Mobile App.
 */
export const sendDiiaDeepLinkRequest = () =>
  clearApiClient.get<{ deep_link: string }>('/web/v1/account/get-diia-deep-link').then((response) => response.data);

/**
 * Updates user's account setting(s).
 */
export const sendUpdateSettingsRequest = (isSubscribedToNewsletter: boolean) =>
  clearApiClient.post('/web/v1/account/update-settings', { receive_newsletters: isSubscribedToNewsletter });

/**
 * Removes device from the account.
 */
export const sendRemoveDeviceRequest = (deviceId: string) =>
  clearApiClient.delete('/web/v1/account/device', { data: { device_id: deviceId }, meta: {} });

/**
 * Marks user account for termination.
 */
export const sendAccountDeletionRequest = (refreshToken: string) =>
  clearApiClient.post('/web/v1/account/delete', { refresh_token: refreshToken });

/**
 * Unmarks user account for termination.
 */
export const sendCancelAccountDeletionRequest = () => clearApiClient.post('/web/v1/account/delete/cancel');

/**
 * Sends an email to the specified email address.
 * The email contains a 6-digit code and a magic link, that has these params encoded.
 */
export const sendOneTimeCodeEmailRequest = (email: string) =>
  getCaptchaToken().then((captchaToken) =>
    clearApiClient.post('/web/v1/account/one-time-code/request', {
      email,
      analytics: collectCarryThroughParams({}),
      recaptcha_token: captchaToken,
      recaptcha_site_key: CAPTCHA_KEY,
    }),
  );

/**
 * Verifies the code sent to the email address, saves tokens to Cookies.
 */
export const sendOneTimeCodeVerifyRequest = (email: string, code: string) =>
  getCaptchaToken().then((captchaToken) =>
    clearApiClient
      .post<TokenResponse>('/web/v1/account/one-time-code/verify', {
        email,
        code,
        recaptcha_token: captchaToken,
        recaptcha_site_key: CAPTCHA_KEY,
      })
      .finally(() => {
        removeTokens();
      })
      .then(
        (response) => {
          if (response.data.access_token) {
            saveTokens(response.data.access_token, response.data.refresh_token);
          } else {
            throw new Error('No access token in the response');
          }
        },
        (error) => {
          throw error;
        },
      ),
  );

/**
 * Sends an email to user with a link to the change email page.
 */
export const sendChangeEmailRequest = () => clearApiClient.post('/web/v1/account/email/request');

/**
 * Confirms user's intention to change email.
 * @param {string} email - new email to transfer the account to
 * @param {string} token - token from the email sent to the old email
 */
export const sendChangeEmailVerifyRequest = (email: string, token: string) =>
  clearApiClient.post('/web/v1/account/email/verify', { new_email: email, unique_token: token });

/**
 * Completes user's intention to change email.
 */
export const sendChangeEmailApplyRequest = (otcCode: string) =>
  clearApiClient.post('/web/v1/account/email/apply', { otc_code: otcCode });
