import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isMobile } from '../../../utils/platformResolver';
import { isDiiaUser } from '../../../utils/billingInfoParser';
import { sendDiiaDeepLinkRequest } from '../../../utils/api/accountApi';
import { genericErrorMessage } from '../../../utils/lang/commonErrors';
import { logDiiaQRShown } from '../../../utils/analytics/diiaEvents';
import useQuery from '../../../utils/useQuery';
import Loader from '../../common/Loader';
import DiiaModal from './components/DiiaModal';
import diiaButtonImage from '../../../../assets/icons/diia/diia-button.svg';
import styles from './DiiaBlock.scss';

const DiiaBlock = ({ profile }) => {
  const intl = useIntl();

  const [isModalOpen, setModalOpen] = useState(false);
  const onModalClose = () => setModalOpen(false);

  const { diia_success_url: successRedirectURL } = useQuery();

  useEffect(() => {
    if (profile && successRedirectURL) {
      setModalOpen(true);
    }
  }, [profile]);

  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const onDiiaButtonClick = () => {
    if (isMobile()) {
      setLoading(true);
      sendDiiaDeepLinkRequest()
        .then((res) => {
          setLoading(false);
          logDiiaQRShown();
          setTimeout(() => {
            // letting analytics event sink in
            location.href = res.deep_link;
          }, 300);
        })
        .catch((error_) => {
          console.error('Could not fetch Diia deeplink:', error_);
          setError(intl.formatMessage(genericErrorMessage));
        });
    } else {
      setModalOpen(true);
    }
  };

  if (
    isDiiaUser(profile) ||
    profile?.email?.endsWith('@anonymous.device') ||
    profile.subscription.hasActiveSubscription
  ) {
    return null;
  }

  return (
    <section className={styles.section}>
      <div className={styles.block}>
        <div>
          <h4 className={styles.blockTitle}>
            <FormattedMessage
              id="profile.diia.title"
              defaultMessage="Ukraine stands together!"
              description="Title of the Diia block"
            />
            <span role="img" aria-label="Ukrainian flag emoji">
              🇺🇦
            </span>
          </h4>
          <span className={styles.blockSubtitle}>
            <FormattedMessage
              id="profile.diia.subtitle"
              defaultMessage="Verify your Ukrainian citizenship to unlock all ClearVPN features for free!"
              description="Subtitle of the Diia block"
            />
          </span>
        </div>

        <button disabled={isLoading} onClick={onDiiaButtonClick}>
          {isLoading ? (
            <Loader />
          ) : (
            <img
              src={diiaButtonImage}
              width="48"
              height="48"
              alt="Click to authenticate with Diia — national e-signature service of Ukraine"
            />
          )}
        </button>

        {error ? <span className={styles.error}>{error}</span> : null}
      </div>

      <DiiaModal isOpen={isModalOpen} successRedirectURL={successRedirectURL} onClose={onModalClose} />
    </section>
  );
};

export default DiiaBlock;
