import axios, { type AxiosError, type InternalAxiosRequestConfig, isAxiosError } from 'axios';
import { getAccessToken, getRefreshToken, redirectToAuth, removeTokens } from '../auth';
import { getLocaleFromCookie } from '../../components/lang/LocalizationProvider';
import { logApiError } from '../../services/errorReporting';
import { sendTokenRefreshRequest, REFRESH_TOKEN_ROUTE } from './accountApi';

const appIdentityHeaderKey = 'App-Identity';
const appIdentity = 'my.clearvpn.com';
const traceIDHeaderKey = 'Trace-Id';

interface DefaulClearAPIError {
  code: number;
  message: string;
}

const clearApiClient = axios.create({
  baseURL: process.env.API_BASE_URL,
  headers: {
    [appIdentityHeaderKey]: appIdentity,
    'Content-Type': 'application/json',
    'User-Locale': getLocaleFromCookie(),
  },
  withCredentials: false,
  meta: { shouldDropToLogin: true },
});

const unauthenticatedHTTPCode = 401;
const unauthenticatedResponseCode = 16;

const injectHeaders = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  config.headers.Authorization = getAccessToken();
  config.headers[traceIDHeaderKey] = crypto.randomUUID();

  return config;
};

let refreshPromise: Promise<void> | null = null;

clearApiClient.interceptors.request.use((config) => {
  const configWithHeaders = injectHeaders(config);

  if (refreshPromise && config.url !== REFRESH_TOKEN_ROUTE) {
    return refreshPromise.then(() => configWithHeaders);
  }

  return configWithHeaders;
});

clearApiClient.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line sonarjs/cognitive-complexity -- sowwy
  (error) => {
    if (isAxiosError<DefaulClearAPIError>(error)) {
      if (
        error.response?.status === unauthenticatedHTTPCode &&
        error.response.data.code === unauthenticatedResponseCode &&
        error.config?.url !== REFRESH_TOKEN_ROUTE
      ) {
        const refreshToken = getRefreshToken();

        if (refreshToken) {
          if (!refreshPromise) {
            refreshPromise = sendTokenRefreshRequest(refreshToken);
            refreshPromise.then(
              () => {
                refreshPromise = null;
              },
              (refreshError) => {
                // refreash failed, redirect to the pit
                removeTokens();
                if (error.config?.meta.shouldDropToLogin) {
                  redirectToAuth();
                }
                throw refreshError;
              },
            );
          }

          console.log('Promising retry with config:', error.config);

          return refreshPromise.then(() => {
            if (error.config) {
              console.log('Sending retry with config:', injectHeaders(error.config));

              return clearApiClient(injectHeaders(error.config));
            }
          });
        }
        // no refresh token, but go unauthenticated, redirect to the pit
        if (error.config?.meta.shouldDropToLogin) {
          redirectToAuth();
        }
      }

      logApiError(error);
      throw error;
    } else {
      removeTokens();
      if (error.config?.meta.shouldDropToLogin) {
        redirectToAuth();
      }

      logApiError(error as AxiosError);
      throw error;
    }
  },
);

const isApiError = (error: unknown): error is AxiosError<DefaulClearAPIError> =>
  isAxiosError<DefaulClearAPIError>(error);

export { clearApiClient, isApiError, traceIDHeaderKey };
