import LocalizationSelect from '../common/LocalizationSelect';
import logoImage from '../../../assets/logo-long.png';
import styles from './MinimalLayout.scss';

interface MinimalLayoutProps {
  children: React.ReactNode;
}

const MinimalLayout = ({ children }: MinimalLayoutProps) => (
  <div>
    <header className={styles.header}>
      <div className={styles.logoLine}>
        <a href="https://clearvpn.com">
          <img src={logoImage} alt="ClearVPN" />
        </a>

        <LocalizationSelect />
      </div>
    </header>
    <main className={styles.container}>{children}</main>
  </div>
);

export default MinimalLayout;
