import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Cookie from 'js-cookie';
import useQuery from './useQuery';

const maxCycles = 3;

const runGACallback = (onSuccess, onFail) => {
  let interval;

  let cycleNumber = 0;

  if (window.ga && typeof window.ga === 'function') {
    onSuccess(window.ga);
  } else {
    const command = () => {
      if (window.ga && window.ga.getAll) {
        clearInterval(interval);
        onSuccess(window.ga);
      } else {
        // timeout on third attempt
        cycleNumber += 1;
        if (cycleNumber >= maxCycles) {
          clearInterval(interval);

          if (onFail) {
            onFail();
          }
        }
      }
    };

    interval = setInterval(command, 200);
  }
};

/**
 * Returns a promise that resolves into the Google Analytics object
 * @returns {Promise}
 */
// eslint-disable-next-line no-promise-executor-return -- what??
const runGACommand = () => new Promise((resolve, reject) => runGACallback((ga) => resolve(ga), reject));

/**
 * React hook, that calls URL update function for Google Analytics.
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
 */
const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    runGACallback((ga) => {
      ga('set', 'page', location.pathname);
      ga('send', 'pageview');
    });
  }, [location]);
};

const useClientID = () => {
  const { cid } = useQuery();

  useEffect(() => {
    // I once messed up, and has written `null` in lots of cookies, this is meant to clean this up
    // can be safely removed soon, per @antonahill confirmation
    if (Cookie.get('_ga')?.includes('null')) {
      Cookie.remove('_ga');
    }
    if (Cookie.get('cid_alt')?.includes('null')) {
      Cookie.remove('cid_alt');
    }

    if (cid) {
      Cookie.set('cid_alt', cid, {
        expires: 730, // in days - 2 years
        domain: '.clearvpn.com',
      });
    }
  }, []);
};

/**
 * React hook, that parses query params from `/profile` route, and sends them to Google Analytics
 */
const useQueryAnalytics = () => {
  const { cid, product, bundle, version, os, lang } = useQuery();

  useEffect(() => {
    if (cid) {
      window.dataLayer.push({
        cid,
        product,
        bundle,
        version,
        os,
        lang,
      });
      runGACallback((ga) => {
        ga('set', 'cid', cid);
        ga('send', 'pageview');
      });
    }
  }, []);
};

export { usePageViews, useClientID, useQueryAnalytics, runGACommand };
