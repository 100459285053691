import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Checkbox from '../../Enter/fields/Checkbox';
import { sendUpdateSettingsRequest } from '../../../utils/api/accountApi';
import { type UserProfile } from '../../../utils/api/schema/profileSchema';
import BlockTitle from '../layout/BlockTitle';
import GrayBox from '../layout/GrayBox';

const Notifications = ({ profile }: { profile: UserProfile }) => {
  const intl = useIntl();
  const { newsletters_enabled: newslettersEnabled } = profile.user_settings;

  const [isEnabled, setEnabled] = useState(newslettersEnabled);
  const [isLoading, setLoading] = useState(false);

  const updateNewsletterSetting = (newValue: boolean) => {
    setLoading(true);
    sendUpdateSettingsRequest(newValue).then(() => {
      setLoading(false);
      setEnabled(newValue);
    });
  };

  return (
    <section>
      <BlockTitle>
        <FormattedMessage
          id="profile.notifications.title"
          defaultMessage="Newsletter notifications"
          description="Title of the notification settings section"
        />
      </BlockTitle>

      <GrayBox>
        <Checkbox
          isNoError
          value={isEnabled}
          isLoading={isLoading}
          label={intl.formatMessage({
            id: 'profile.notifications.newsletterDescription',
            defaultMessage: 'Important updates, tips & offers',
            description: 'Description of the newsletter subscription checkbox',
          })}
          onChange={updateNewsletterSetting}
        />
      </GrayBox>
    </section>
  );
};

export default Notifications;
