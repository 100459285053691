import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { browserDeeplink } from '../../../lib/browser-deeplink';
import EnterLayout from '../../components/layout/EnterLayout';
import Alert from '../../components/common/Alert';
import { resolvePlatform, isMobile } from '../../utils/platformResolver';
import { defaultLinks, fetchPersonalizedLinks } from '../../utils/downloadLinks';
import { logDownloadStarted } from '../../utils/analytics/downloadEvents';
import { sanitizeUrl } from '../../utils/sanitizeUrl';

/**
 * Takes `app_url` param as base URL, and appends all the remaining params to it
 * @returns {string}
 */
const createAppLink = () => {
  const params = new URLSearchParams(window.location.search);

  const appUrlParam = params.get('app_url');

  let appUrl = 'clearvpn://';

  if (
    appUrlParam?.startsWith('clearvpn://') ||
    appUrlParam?.startsWith('clearvpnsite://') ||
    appUrlParam?.startsWith('clearvpnmas://')
  ) {
    appUrl = sanitizeUrl(appUrlParam);
  }

  params.delete('app_url');

  const query = params.toString();

  return `${appUrl || 'clearvpn://'}${query ? `?${query}` : ''}`;
};

/**
 * Static alert with "To App" button. The link is constructed from query params.
 * @returns {JSX.Element}
 */
const RedirectToApp = ({ title }) => {
  // this must be correct on FIRST RENDER, or `autoClickAction` will brake app login
  const [appLink] = useState(createAppLink());

  const [isMobileLink] = useState(isMobile());

  const [downloadLink, setDownloadLink] = useState();

  useEffect(() => {
    const platform = resolvePlatform();

    setDownloadLink(defaultLinks[platform]);

    const params = new URLSearchParams(window.location.search).toString();

    fetchPersonalizedLinks(params).then((downloadLinks) => setDownloadLink(downloadLinks[platform]));
  }, []);

  const [hasFailedToOpenApp, setHasFailedToOpenApp] = useState(false);
  const onClick = () => {
    console.log('Go to App URL:', appLink);
    browserDeeplink(appLink).catch((error) => {
      console.error('Deeplink did’t open', error);
      setHasFailedToOpenApp(true);
    });
  };

  const intl = useIntl();

  return (
    <EnterLayout verticalPaddings={[90, 60]}>
      <Alert
        autoClickAction
        href={isMobileLink ? appLink : undefined}
        title={title}
        icon="success"
        actionTitle={intl.formatMessage({
          id: 'redirectToApp.cta',
          defaultMessage: 'Go to App',
          description: 'Button that opens up ClearVPN app',
        })}
        footerChild={
          hasFailedToOpenApp ? (
            <FormattedMessage
              id="redirectToApp.downloadApp"
              defaultMessage="It seems, you don’t have the app installed yet, please, <link>download here.</link>"
              description="Banner on `To app` page shown, when user can't open the deeplink, because they does’t have it installed"
              values={{
                link: (chunk) => (
                  <a
                    href={downloadLink}
                    target={isMobile() ? '_blank' : '_self'}
                    rel="noreferrer"
                    onClick={() => logDownloadStarted(resolvePlatform())}
                  >
                    {chunk}
                  </a>
                ),
              }}
            />
          ) : null
        }
        onClick={isMobileLink ? undefined : onClick}
      />
    </EnterLayout>
  );
};

export default RedirectToApp;
