import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import EnterLayout from '../../components/layout/EnterLayout';
import Alert from '../../components/common/Alert';
import { isWebAppIdentity } from '../../utils/consts/appIdentity';
import OTCForApps from '../../components/h/otc/OTCForApps';
import Loader from '../../components/common/Loader';
import OTCForWeb from '../../components/h/otc/OTCForWeb';
import { paramsKeys } from '../../utils/consts/params';

const OTCPageStates = {
  LOADING: 'loading',
  APP: 'app',
  WEB: 'web',
  ERROR: 'error',
} as const;

const OneTimeCodeRedirect = () => {
  const [pageState, setPageState] = useState<(typeof OTCPageStates)[keyof typeof OTCPageStates]>(OTCPageStates.LOADING);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const codeParam = params.get(paramsKeys.OTC_CODE);
    const appIdentityParam = params.get(paramsKeys.APP_IDENTITY);

    if (!codeParam || !/^\d{6}$/.test(codeParam)) {
      setPageState(OTCPageStates.ERROR);
    } else if (appIdentityParam && isWebAppIdentity(appIdentityParam)) {
      setPageState(OTCPageStates.WEB);
    } else {
      setPageState(OTCPageStates.APP);
    }
  }, []);

  const intl = useIntl();

  switch (pageState) {
    case OTCPageStates.LOADING: {
      return (
        <EnterLayout verticalPaddings={[90, 60]}>
          <Loader />
        </EnterLayout>
      );
    }

    case OTCPageStates.WEB: {
      return <OTCForWeb />;
    }

    case OTCPageStates.APP: {
      return <OTCForApps />;
    }

    default: {
      return (
        <EnterLayout verticalPaddings={[90, 60]}>
          <Alert
            title={intl.formatMessage({ id: 'otcProxyPage.error.title', defaultMessage: 'Something went wrong' })}
            icon="error"
            description={intl.formatMessage({
              id: 'otcProxyPage.error.description',
              defaultMessage: 'Please, try inputting the code from the email manually',
            })}
          />
        </EnterLayout>
      );
    }
  }
};

export default OneTimeCodeRedirect;
