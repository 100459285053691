import React, { useEffect, useState } from 'react';
import { sendOneTimeCodeEmailRequest } from '../../../utils/api/accountApi';
import { clearOTCEmail, getOTCEmail, saveOTCEmail } from '../../../utils/otcStorage';
import { getParsedParams } from '../../../utils/consts/params';
import { logOTCCodeSubmitSuccess } from '../../../utils/analytics/authEvents';
import EmailSignUpForm from './EmailSignUpForm';
import OneTimeCodeForm from './OneTimeCodeForm';

const forms = {
  emailSignUpForm: 'emailSignUpForm',
  oneTimeCodeForm: 'oneTimeCodeForm',
} as const;

const AuthFormSwitch = () => {
  const [currentForm, setCurrentForm] = useState<(typeof forms)[keyof typeof forms]>(forms.emailSignUpForm);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const savedOTCEmail = getOTCEmail();

    const params = getParsedParams();

    if (savedOTCEmail && params.OTC_CODE) {
      setEmail(savedOTCEmail);
      setCurrentForm(forms.oneTimeCodeForm);
    }
  }, []);

  switch (currentForm) {
    case forms.emailSignUpForm: {
      const onEmailSignUpFormSubmit = (emailValue: string) => {
        saveOTCEmail(emailValue);
        setEmail(emailValue);
        setCurrentForm(forms.oneTimeCodeForm);
      };

      return (
        <EmailSignUpForm
          presetEmail={email}
          sendOTCRequest={sendOneTimeCodeEmailRequest}
          onSubmit={onEmailSignUpFormSubmit}
        />
      );
    }

    case forms.oneTimeCodeForm: {
      const onAuthSuccess = () => {
        logOTCCodeSubmitSuccess();
        clearOTCEmail();
        window.location.href = '/profile';
      };

      const onReturnToEmailForm = () => {
        setCurrentForm(forms.emailSignUpForm);
      };

      return (
        <OneTimeCodeForm
          email={email}
          sendOTCRequest={sendOneTimeCodeEmailRequest}
          onBackButtonClick={onReturnToEmailForm}
          onAuthSuccess={onAuthSuccess}
        />
      );
    }

    default: {
      return null;
    }
  }
};

export default AuthFormSwitch;
