import clsx from 'clsx';
import styles from './GrayBox.scss';

interface GrayBoxProps {
  children: React.ReactNode;
  className?: string;
}

const GrayBox = ({ children, className }: GrayBoxProps) => (
  <div className={clsx(styles.box, className)}>{children}</div>
);

export default GrayBox;
