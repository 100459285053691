import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import type { Extras } from '@sentry/types';
import { type AxiosError } from 'axios';
import { traceIDHeaderKey } from '../utils/api/clearApiClient';

// these have spammed my Sentry project, I will no longer tolerate this
// GTM -- Google Analytics and friends
// FBQ -- Facebook Pixel tracker, fails too often
const errorBlockList = ['gtm', 'fbq'].map((item) => item.toLowerCase());

const initializeSentry = () => {
  Sentry.init({
    dsn: 'https://3ad88f33d9394f2fbf6906d45faff8ff@o36975.ingest.sentry.io/5860925',
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    beforeSend(event, hint) {
      const exception = hint.originalException;

      // discarding spam from GTM
      let haystack: string;

      if (exception instanceof Error) {
        haystack = exception.message;
      } else if (typeof exception === 'string') {
        haystack = exception;
      } else {
        return event;
      }

      haystack = haystack.toLowerCase();

      if (errorBlockList.some((item) => haystack.includes(item))) {
        return null;
      }

      return event;
    },
  });
};

const logError = (error: Error, extra?: Extras) => {
  Sentry.captureException(error, {
    extra,
  });
};

const logApiError = (error: AxiosError) => {
  if (!error.config) {
    Sentry.captureException(new Error('API Error w/o config', { cause: error }));

    return;
  }

  if (error.status && (error.status < 400 || error.status >= 500)) {
    Sentry.captureException(
      new Error(`API Exception ${error.config.method} ${error.config.url}: ${error.status}`, { cause: error }),
      {
        extra: {
          traceId: error.config.headers[traceIDHeaderKey],
          response: error.response?.data ? JSON.stringify(error.response.data) : null,
        },
      },
    );
  } else {
    Sentry.captureMessage(`API Exception ${error.config.method} ${error.config.url}: ${error.status}`, {
      extra: {
        traceId: error.config.headers[traceIDHeaderKey],
        response: error.response?.data ? JSON.stringify(error.response.data) : null,
      },
    });
  }
};

const logMessage = (message: string, extra: Extras) => Sentry.captureMessage(message, { extra });

export { initializeSentry, logError, logApiError, logMessage };
