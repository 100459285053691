import React, { useEffect, useState, useContext } from 'react';
import { defaultLinks, type DownloadLinks, fetchPersonalizedLinksWithAppUrl } from '../../utils/downloadLinks';

interface DownloadLinksContextState {
  links: DownloadLinks;
  refreshDownloadToken: () => Promise<void>;
}

const DownloadLinksContext = React.createContext<DownloadLinksContextState>({
  links: defaultLinks,
  refreshDownloadToken: () => Promise.resolve(),
});

interface DownloadLinksContextProviderProps {
  children: React.ReactNode;
  refreshDownloadToken: () => Promise<void>;
  downloadToken?: string;
}

const DownloadLinksContextProvider = ({
  children,
  downloadToken,
  refreshDownloadToken,
}: DownloadLinksContextProviderProps) => {
  const [state, setState] = useState<DownloadLinksContextState>({ links: defaultLinks, refreshDownloadToken });

  useEffect(() => {
    if (downloadToken) {
      const appUrl = new URL('clearvpn://go/register');

      appUrl.searchParams.append('permanent_token', downloadToken);
      fetchPersonalizedLinksWithAppUrl(appUrl).then((links) => {
        const mobileLink = `https://clearvpn.onelink.me/uPP4/1q2xto27?deep_link_sub1=${downloadToken}`;

        // https://macpaw.atlassian.net/wiki/spaces/ClearVPN/pages/578455217/AppsFlyer+universal+links
        setState({ links: { ...links, ios: mobileLink, android: mobileLink }, refreshDownloadToken });
      });
    }
  }, [downloadToken]);

  return <DownloadLinksContext.Provider value={state}>{children}</DownloadLinksContext.Provider>;
};

export default DownloadLinksContextProvider;

export const useDownloadLinks = () => {
  return useContext(DownloadLinksContext);
};
