const { CAPTCHA_KEY } = process.env;

const getCaptchaToken = () =>
  new Promise((resolve) => {
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(CAPTCHA_KEY, { action: 'login' }).then((token) => resolve(token));
    });
  });

export { CAPTCHA_KEY, getCaptchaToken };
