import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { initializeSentry } from './services/errorReporting';
import App from './App';
import LocalizationProvider from './components/lang/LocalizationProvider';
import ErrorBoundary from './components/error/ErrorBoundary';

import './index.static.scss';

initializeSentry();

createRoot(document.querySelector('#root')).render(
  <ErrorBoundary>
    <BrowserRouter>
      <LocalizationProvider>
        <App />
      </LocalizationProvider>
    </BrowserRouter>
  </ErrorBoundary>,
);
