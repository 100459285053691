import { type DownloadLinks } from '../downloadLinks';

const logDownloadStarted = (platform?: keyof DownloadLinks) => {
  window.dataLayer?.push({
    event: 'download_started',
    'clear-eventCategory': 'download',
    'clear-eventAction': 'download_started',
    'clear-eventLabel': platform,
  });
};

export { logDownloadStarted };
