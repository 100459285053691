import { clearApiClient } from './clearApiClient';

interface GeoIpResponse {
  country: {
    iso_code: string;
    names: Record<string, string>;
  };
  city: {
    geo_name_id: string;
    names: Record<string, string>;
    long: number;
    lat: number;
  };
  client_ip: string;
}

export const sendGeoIpRequest = () => clearApiClient.get<GeoIpResponse>('/web/v1/geoip/ip/info');
