import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ExtensionPagesLayout from '../../components/layout/ExtensionPagesLayout';
import { isChrome } from '../../utils/browserResolver';
import Loader from '../../components/common/Loader';
import Spacer from '../../components/common/Spacer';
import WebStoreButton from '../../components/extension/WebStoreButton';
import { checkIfChromeExtensionAPIExposed, openExtensionSettingsPage, webStoreLink } from '../../utils/extensionStatus';
import { getAccessToken, getRefreshToken, isLoggedIn } from '../../utils/auth';
import { genericErrorMessage } from '../../utils/lang/commonErrors';
import chromeLogo from '../../../assets/icons/chrome-icon.svg';
import fixelWatImg from '../../../assets/icons/fixel-wat.svg';
import styles from './ChromeAdblock.scss';

const adblockScreenStates = {
  LOADING: 'loading',
  WRONG_BROWSER: 'wrongBrowser',
  NO_EXTENSION: 'correctBrowserNoExtensionInstalled',
  NOT_FOR_MOBILE: 'notAvailableForMobile',
} as const;

type OneOfAdblockScreenStates = (typeof adblockScreenStates)[keyof typeof adblockScreenStates];

const PERMANENT_TOKEN_PARAM_NAME = 'perm_token';

const ChromeAdblockRoute = () => {
  const [state, setState] = useState<OneOfAdblockScreenStates>(adblockScreenStates.LOADING);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const appIdentity = params.get('app-identity');
    const isAppMobile = ['ios', 'android'].some((lookup) => appIdentity?.includes(lookup));

    if (isAppMobile) {
      setState(adblockScreenStates.NOT_FOR_MOBILE);
    } else {
      checkIfChromeExtensionAPIExposed()
        .then((isInstalled) => {
          if (isInstalled) {
            const settingsParams = new URLSearchParams();

            const permToken = params.get(PERMANENT_TOKEN_PARAM_NAME);

            if (permToken) {
              settingsParams.append(PERMANENT_TOKEN_PARAM_NAME, permToken);
            } else if (!params.has(PERMANENT_TOKEN_PARAM_NAME) && isLoggedIn()) {
              // if there is no auth token, but the user is logged in, we can add tokens to URL
              settingsParams.append('accessToken', getAccessToken() as string);

              const refreshToken = getRefreshToken();

              if (refreshToken) {
                settingsParams.append('refreshToken', refreshToken);
              }
            }

            openExtensionSettingsPage(settingsParams);
          }
        })
        .finally(() => {
          if (state === adblockScreenStates.LOADING) {
            if (isChrome()) {
              setState(adblockScreenStates.NO_EXTENSION);
            } else {
              setState(adblockScreenStates.WRONG_BROWSER);
            }
          }
        });
    }
  }, []);

  switch (state) {
    case adblockScreenStates.LOADING: {
      return (
        <ExtensionPagesLayout>
          <Spacer height={28} />
          <Loader size={40} />
        </ExtensionPagesLayout>
      );
    }

    case adblockScreenStates.NO_EXTENSION: {
      return (
        <ExtensionPagesLayout>
          <h1 className={styles.title}>
            <FormattedMessage id="toChrome.notInstalled.title" defaultMessage="Install ClearWeb Chrome extention!" />
          </h1>
          <span className={styles.subtitle}>
            <FormattedMessage
              id="toChrome.notInstalled.subtitle"
              defaultMessage="*Please, keep this tab open until the installation is complete for seemless authorization."
            />
          </span>
          <WebStoreButton />
        </ExtensionPagesLayout>
      );
    }

    case adblockScreenStates.WRONG_BROWSER: {
      return (
        <ExtensionPagesLayout>
          <img src={chromeLogo} width={101} height={101} alt="" className={styles.icon} />
          <h1 className={styles.title}>
            <FormattedMessage
              id="toChrome.wrongBrowser.title"
              defaultMessage="This extension is compatible only with Chrome. Please ensure you are using the Chrome browser for optimal
            functionality."
            />
          </h1>
          <a href={webStoreLink} target="_blank" className={styles.button} rel="noreferrer">
            <FormattedMessage id="toChrome.wrongBrowser.button" defaultMessage="Download ClearWeb for Chrome" />
          </a>
        </ExtensionPagesLayout>
      );
    }

    case adblockScreenStates.NOT_FOR_MOBILE: {
      return (
        <ExtensionPagesLayout>
          <img src={fixelWatImg} width={162} height={154} alt="" className={styles.icon} />
          <h1 className={styles.title}>
            <FormattedMessage
              id="toChrome.notForMobile.title"
              defaultMessage="This extension is only available for desktop Chrome browser yet."
            />
          </h1>
          <span className={styles.subtitle}>
            <FormattedMessage
              id="toChrome.notForMobile.subtitle"
              defaultMessage="If you have desktop with Chrome nearby, you can install the extension using the link below"
            />
          </span>
          <a href={webStoreLink} target="_blank" className={styles.button} rel="noreferrer">
            <FormattedMessage id="toChrome.wrongBrowser.button" defaultMessage="Download ClearWeb for Chrome" />
          </a>
        </ExtensionPagesLayout>
      );
    }

    default: {
      return (
        <ExtensionPagesLayout>
          <img src={fixelWatImg} width={162} height={154} alt="" className={styles.icon} />
          <h1 className={styles.title}>
            <FormattedMessage {...genericErrorMessage} />
          </h1>
        </ExtensionPagesLayout>
      );
    }
  }
};

export default ChromeAdblockRoute;
