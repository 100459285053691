import React from 'react';
import t from 'prop-types';

const Spacer = ({ height }) => <div style={{ height }} className="spacer" />;

Spacer.propTypes = {
  height: t.number.isRequired,
};

export default Spacer;
