import React from 'react';
import t from 'prop-types';

const white = '#fff';
const darkWhite = '#ddd';

const GooglePayIcon = ({ isSelected, whiteFont }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="20" viewBox="0 0 30 12" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4409 9.38666V5.86508H15.2585C16.0033 5.86508 16.6319 5.61552 17.1444 5.12333L17.2673 4.99855C18.2035 3.97951 18.142 2.39202 17.1444 1.44924C16.6455 0.950116 15.9623 0.679759 15.2585 0.693623H12.3408V9.38666H13.4409ZM13.441 4.79744V1.76112H15.2862C15.6826 1.76112 16.0585 1.91363 16.3387 2.19092C16.9332 2.77323 16.9469 3.74374 16.3728 4.34685C16.0926 4.64494 15.6963 4.81131 15.2862 4.79744H13.441ZM22.3989 3.90322C21.9274 3.46649 21.2851 3.24466 20.472 3.24466C19.4266 3.24466 18.6408 3.63287 18.1215 4.40235L19.0918 5.01931C19.4471 4.49246 19.9322 4.22904 20.5472 4.22904C20.9366 4.22904 21.3125 4.37462 21.6063 4.63804C21.8932 4.8876 22.0572 5.24808 22.0572 5.62935V5.88585C21.6336 5.65015 21.1006 5.52537 20.4447 5.52537C19.6794 5.52537 19.0644 5.70561 18.6066 6.07302C18.1488 6.44043 17.9165 6.92568 17.9165 7.54265C17.9028 8.10417 18.142 8.63795 18.5656 8.99842C18.9961 9.38663 19.5427 9.58073 20.185 9.58073C20.9435 9.58073 21.5448 9.24105 22.0026 8.56169H22.0504V9.38663H23.1027V5.71947C23.1027 4.94999 22.8704 4.33995 22.3989 3.90322ZM19.4131 8.29136C19.1876 8.12498 19.0509 7.85462 19.0509 7.56347C19.0509 7.23766 19.2012 6.9673 19.4951 6.7524C19.7958 6.5375 20.1717 6.42658 20.6159 6.42658C21.2309 6.41965 21.7093 6.5583 22.051 6.83559C22.051 7.30698 21.8665 7.71598 21.5043 8.06259C21.1763 8.39534 20.732 8.58251 20.2673 8.58251C19.9598 8.58944 19.6591 8.48546 19.4131 8.29136ZM25.4669 12.0001L29.143 3.43876H27.9472L26.2458 7.70902H26.2253L24.4829 3.43876H23.2872L25.6992 9.00535L24.3326 12.0001H25.4669Z"
      fill={isSelected || whiteFont ? white : '#3C4043'}
    />

    <path
      d="M9.64292 5.10244C9.64292 4.76276 9.61559 4.42308 9.56093 4.09033H4.92139V6.01056H7.57939C7.47006 6.62753 7.11475 7.18211 6.59545 7.52873V8.77653H8.18068C9.10996 7.91 9.64292 6.62753 9.64292 5.10244Z"
      fill={isSelected ? white : '#4285F4'}
    />
    <path
      d="M4.92163 9.98281C6.24722 9.98281 7.36781 9.53915 8.18093 8.7766L6.59569 7.52879C6.15156 7.83381 5.58442 8.00712 4.92163 8.00712C3.63705 8.00712 2.55062 7.12672 2.16114 5.94824H0.528076V7.23764C1.36169 8.92218 3.06308 9.98281 4.92163 9.98281Z"
      fill={isSelected ? darkWhite : '#34A853'}
    />
    <path
      d="M2.16125 5.94821C1.95623 5.33124 1.95623 4.65881 2.16125 4.03491V2.75244H0.527926C-0.175975 4.15969 -0.175975 5.82343 0.527926 7.23067L2.16125 5.94821Z"
      fill={isSelected ? white : '#FBBC04'}
    />
    <path
      d="M4.92163 1.97599C5.62542 1.96213 6.30188 2.23249 6.80751 2.72467L8.21509 1.29663C7.31998 0.450898 6.14472 -0.0135628 4.92163 0.000301698C3.06308 0.000301698 1.36169 1.06787 0.528076 2.7524L2.16114 4.0418C2.55062 2.85639 3.63705 1.97599 4.92163 1.97599Z"
      fill={isSelected ? darkWhite : '#EA4335'}
    />
  </svg>
);

GooglePayIcon.propTypes = {
  isSelected: t.bool,
  whiteFont: t.bool,
};

export default GooglePayIcon;
