import React from 'react';

const isAnonAccount = (profile) => profile?.email?.endsWith('@anonymous.device');

const isDiiaUser = (profile) => profile.diia_verified || profile.subscription.vendor === 'diia';

// eslint-disable-next-line sonarjs/cognitive-complexity -- switch casing is good enough
const resolvePlanName = (profile, intl) => {
  if (isDiiaUser(profile)) {
    return intl.formatMessage({
      id: 'profile.billing.diiaSubscription',
      defaultMessage: 'Together to Victory 🇺🇦',
      description: 'Subscription acquired via Diia',
    });
  }
  if (profile.type === 'subscription') {
    if (profile.subscription.sku.startsWith('clearvpn.alternative.v2.')) {
      if (profile.subscription.sku.startsWith('clearvpn.alternative.v2.tourist.month')) {
        return 'E-Tourist Subscription';
      }
      if (profile.subscription.sku.startsWith('clearvpn.alternative.v2.tourist.3month')) {
        return 'E-Tourist Subscription (3mo)';
      }
      if (profile.subscription.sku.startsWith('clearvpn.alternative.v2.binge.month')) {
        return 'Binge Watcher Subscription';
      }
      if (profile.subscription.sku.startsWith('clearvpn.alternative.v2.binge.3month')) {
        return 'Binge Watcher Subscription (3mo)';
      }
      if (profile.subscription.sku.startsWith('clearvpn.alternative.v2.poweruser.month')) {
        return 'VPN Power User Subscription';
      }
      if (profile.subscription.sku.startsWith('clearvpn.alternative.v2.poweruser.3month')) {
        return 'VPN Power User Subscription (3mo)';
      }
    } else {
      return profile.subscription.name;
    }
  } else if (profile.type === 'setapp') {
    return intl.formatMessage({
      id: 'profile.billing.setappSubscription',
      defaultMessage: 'Setapp subscription',
      description: 'Subscription provided from SetApp',
    });
  } else if (profile.type === 'promo' && profile.subscription.vendor === 'promocode') {
    if (isAnonAccount(profile)) {
      return (
        <span>
          {intl.formatMessage({
            id: 'profile.billing.promocodeSubscriptionLabel.anon',
            defaultMessage: 'Activation code:',
            description: 'Label for account code used to activate it, is followed by the code applied',
          })}{' '}
          <b>{profile.subscription.name}</b>
        </span>
      );
    }

    return (
      <span>
        {intl.formatMessage({
          id: 'profile.billing.promocodeSubscriptionLabel',
          defaultMessage: 'Promocode applied:',
          description: 'Label for the promocode subscription, is followed by the code applied',
        })}{' '}
        <b>{profile.subscription.name}</b>
      </span>
    );
  } else {
    return `${profile.type.slice(0, 1).toUpperCase()}${profile.type.slice(1)}`;
  }
};

const resolveSpecialPeriodBubble = (profile, intl) => {
  if (profile.subscription.subscriptionActivationInProgress) {
    return {
      isShown: true,
      text: intl.formatMessage({
        id: 'profile.billing.subscriptionSyncing',
        defaultMessage: 'Syncing',
        description: 'Label shown next to plan name, when subscription setup is not yet finalized',
      }),
      tooltip: intl.formatMessage({
        id: 'profile.billing.subscriptionSyncingTooltip',
        defaultMessage: 'It took us a bit longer to sync up your subscription, it should be fine in a minute',
        description: 'Tooltip on label shown next to plan name, when subscription setup is not yet finalized',
      }),
    };
  }
  if (profile.state === 'trial') {
    return {
      isShown: true,
      text: intl.formatMessage({
        id: 'profile.billing.trialPeriod7Day',
        defaultMessage: 'Trial period',
        description: 'Label shown next to plan name, while trial period is active',
      }),
    };
  }
  if (profile.state === 'grace_period') {
    // expiration date mills - current mills / divide by number of mills in a single day
    const graceDaysLeft = Math.floor((new Date(profile.subscription.expiration_date).getTime() - Date.now()) / 8.64e7);

    return {
      isShown: true,
      text: intl.formatMessage(
        {
          id: 'profile.billing.gracePeriodLabel',
          defaultMessage: `{graceDays, plural,
                              =0 {0 days}
                              one {# day}
                              few {# days}
                              other {# days}
                          } of grace period left`,
          description:
            'Label shown next to plan name, when renewal has failed, but subscription has not been turned off',
        },
        { graceDays: graceDaysLeft },
      ),
    };
  }

  return { isShown: false };
};

const resolveMainButton = (profile) => {
  const params = new URLSearchParams(location.search);

  if (
    isDiiaUser(profile) ||
    profile.subscription.subscriptionActivationInProgress ||
    isAnonAccount(profile) ||
    params.get('diia_success_url')
  ) {
    return {};
  }
  if (profile.type === 'free') {
    return {
      isUpgradeCta: true,
    };
  }
  if (profile.type === 'setapp') {
    return {
      isSetappManageSubCta: true,
    };
  }
  if (profile?.subscription?.subscriptionRenewable) {
    return {
      isRenewCta: true,
    };
  }
  if (profile?.subscription?.subscriptionCancellable) {
    return {
      isCancelCta: true,
      isMobileStoreCancelDialog: ['app_store_vendor', 'play_marked_vendor'].includes(
        profile?.payment_data?.payment_method?.vendor,
      ),
    };
  }

  return {};
};

const currencySymbolMap = {
  EUR: '€',
  USD: '$',
  GBP: '£',
  CAD: 'CAD $',
  NZD: 'NZD $',
  AUD: 'AUD $',
};

const formatPrice = ({ amount, currency }) =>
  `${currencySymbolMap[currency]}${amount.slice(0, -2) || '0'}.${amount.slice(-2)}`;

const resolveDateLine = (profile, intl) => {
  if (profile.subscription.subscriptionActivationInProgress) {
    return {
      isDateShown: false,
    };
  }
  if (isDiiaUser(profile)) {
    return {
      isDateShown: true,
      dateLabel: intl.formatMessage({
        id: 'profile.billing.dateLabel.expires',
        defaultMessage: 'Expires on:',
        description: 'Label for the expiration date of subscription',
      }),
      dateValue: '∞',
    };
  }
  if (profile.subscription.expiration_date) {
    const result = {
      isDateShown: true,
      dateValue: intl.formatDate(profile.subscription.expiration_date, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    };

    if (profile.state === 'grace_period') {
      result.dateLabel = intl.formatMessage({
        id: 'profile.billing.dateLabel.nextBilling',
        defaultMessage: 'Next billing attempt:',
        description: 'Label for the date of the next charge attempt',
      });

      if (profile.subscription?.price?.amount && profile.subscription?.price?.currency) {
        result.dateValue = intl.formatMessage(
          {
            id: 'profile.billing.gracePeriodUpcomingCharge',
            defaultMessage: '{price} scheduled on {chargeDate, date, long}',
            description: 'Info line for grace period, i.e. "$25.99 scheduled 13 June, 2020"',
          },
          {
            price: formatPrice(profile.subscription.price),
            chargeDate: new Date(profile.subscription.expiration_date),
          },
        );
      }
    } else if (profile.state === 'trial') {
      result.dateLabel = intl.formatMessage({
        id: 'profile.billing.dateLabel.postTrialCharge',
        defaultMessage: 'Post trial billing:',
        description: 'Label for date of charge coming after the trial period',
      });
    } else if (profile.state === 'canceled' || profile.type === 'promo') {
      result.dateLabel = intl.formatMessage({
        id: 'profile.billing.dateLabel.expires',
        defaultMessage: 'Expires on:',
        description: 'Label for the expiration date of subscription',
      });
    } else if (profile.state === 'active') {
      result.dateLabel = intl.formatMessage({
        id: 'profile.billing.dateLabel.nextCharge',
        defaultMessage: 'Next billing:',
        description: 'Label for date of next charge',
      });
    }

    return result;
  }

  return {};
};

const resolveDiscountBanner = (profile, coupon, intl) => {
  if (profile.type === 'free') {
    if (coupon?.coupon_code && coupon?.coupon_dicount_percent) {
      return {
        isDiscountBannerShown: true,
        discountBannerText: intl.formatMessage(
          {
            id: 'profile.billing.discountBanner',
            defaultMessage: 'You have a {discount, number}% discount on your first order!',
            description: 'Banner shown next to upgrade button, featuring a discount from a discount coupon',
          },
          {
            discount: coupon?.coupon_dicount_percent,
          },
        ),
      };
    }
    if (profile.referral.referrals_count > 0) {
      const referralCountToDiscountPercent = {
        0: 0,
        1: 20,
        2: 30,
        3: 50,
        4: 70,
        5: 90,
      };

      return {
        isDiscountBannerShown: true,
        discountBannerText: intl.formatMessage(
          {
            id: 'profile.billing.referralBanner.discountForReferrers',
            defaultMessage: 'Upgrade your plan to get a {discount} invite discount for your first payment.',
            description:
              'Banner shown next to upgrade button, featuring a discount for inviting users via referral program',
          },
          {
            discount: `${referralCountToDiscountPercent[profile.referral.referrals_count]}%`,
          },
        ),
      };
    }
    if (profile.referral.referrer_id) {
      return {
        isDiscountBannerShown: true,
        discountBannerText: intl.formatMessage({
          id: 'profile.billing.referralBanner.discountForReferrals',
          defaultMessage: 'You received an invite discount! Upgrade your plan to apply it.',
          description: 'Banner shown next to upgrade button, featuring a discount for signing up via referral link',
        }),
      };
    }
  }

  return {
    isDiscountBannerShown: false,
  };
};

const resolvePromocodeRedeem = (profile, coupon, intl) => {
  const params = new URLSearchParams(location.search);

  return (
    !isDiiaUser(profile) &&
    !resolveDiscountBanner(profile, coupon, intl).isReferralBannerShown &&
    (profile.type === 'free' || profile.type === 'promo') &&
    !isAnonAccount(profile) &&
    !params.get('diia_success_url')
  );
};

const resolvePaymentMethod = (profile) => {
  return {
    isPaymentMethodShown:
      profile?.payment_data?.payment_method_exists &&
      profile?.payment_data?.payment_method?.vendor !== 'unknown_payment_vendor' &&
      profile.type !== 'free' &&
      profile.type !== 'promo' &&
      profile.type !== 'setapp' &&
      profile.state !== 'canceled' &&
      !profile.subscription.subscriptionActivationInProgress,
    isMethodEditable: profile?.payment_data?.payment_method?.can_edited,
    hasPaymentIssue:
      profile.state === 'billing_retry_period' ||
      profile?.payment_data?.payment_status === 'failed' ||
      profile.state === 'grace_period',
  };
};

const hasPayPalDeprecationWarning = (profile) =>
  resolvePaymentMethod(profile).isMethodEditable &&
  profile?.subscription?.vendor === 'chargebee' &&
  profile?.payment_data?.payment_method?.vendor === 'paypal_vendor';

export {
  resolvePlanName,
  resolveSpecialPeriodBubble,
  resolveMainButton,
  resolveDateLine,
  resolvePromocodeRedeem,
  resolvePaymentMethod,
  resolveDiscountBanner,
  hasPayPalDeprecationWarning,
  isDiiaUser,
};
