import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Footer from '../../Footer/Footer';
import { Button } from '../../common/Button';
import LocalizationSelect from '../../common/LocalizationSelect';
import { logOut } from '../../../utils/auth';
import { lockBackgroundScroll, unlockBackgroundScroll } from '../../../utils/lockBackgroundScroll';
import { type OneOfProfileSections } from '../profileSections';
import ProfileSectionSelect from './SectionSelect';
import ToggleMenuButton from './ToggleMenuButton';
import Logo from '../../../../assets/logo-long.png';
import styles from './ProfileLayout.scss';

interface ProfileLayoutProps {
  children: React.ReactNode;
  activeSection: OneOfProfileSections;
  onSectionSelected: (section: OneOfProfileSections) => void;
  isReferralSectionAvailable?: boolean;
}

const ProfileLayout = ({
  children,
  activeSection,
  onSectionSelected,
  isReferralSectionAvailable,
}: ProfileLayoutProps) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      lockBackgroundScroll();
    } else {
      unlockBackgroundScroll();
    }
  }, [isMenuOpen]);

  const handleSectionSelect = (section: OneOfProfileSections) => {
    onSectionSelected(section);
    setMenuOpen(false);
  };

  return (
    <>
      <div className={styles.container}>
        {isMenuOpen && (
          <div className={styles.mobileMenuOverlay}>
            <ProfileSectionSelect
              activeSection={activeSection}
              className={styles.mobileSectionSelect}
              isReferralSectionAvailable={isReferralSectionAvailable}
              onSectionSelected={handleSectionSelect}
            >
              <Button kind="secondary" buttonSize="small" className={styles.logOutButton} onClick={logOut}>
                <FormattedMessage
                  id="profile.accountDetails.logOut"
                  defaultMessage="Log out"
                  description="Log out button, user will be dropped back to the login page"
                />
              </Button>
            </ProfileSectionSelect>
          </div>
        )}

        <header className={clsx(styles.header, isMenuOpen && styles.headerWithMenu)}>
          <a href="https://clearvpn.com/" className={styles.logo}>
            <img src={Logo} width="119" height="32" alt="" />
          </a>

          <div className={styles.headerButtons}>
            <LocalizationSelect />

            <Button
              kind="secondary"
              buttonSize="small"
              className={clsx(styles.logOutButton, styles.desktopLogoutButton)}
              onClick={logOut}
            >
              <FormattedMessage
                id="profile.accountDetails.logOut"
                defaultMessage="Log out"
                description="Log out button, user will be dropped back to the login page"
              />
            </Button>

            <ToggleMenuButton
              isOpen={isMenuOpen}
              className={styles.mobileMenuToggle}
              onClick={() => {
                setMenuOpen((isOpen) => !isOpen);
              }}
            />
          </div>
        </header>

        {children}

        <Footer className={styles.footer} />
      </div>

      <div className={clsx(styles.container, styles.containerShadow)}>
        <header className={styles.header}>
          <a href="https://clearvpn.com/" className={styles.logo}>
            <img src={Logo} width="119" height="32" alt="" />
          </a>

          <div className={styles.headerButtons}>
            <LocalizationSelect />

            <Button kind="secondary" buttonSize="small" className={styles.logOutButton} onClick={logOut}>
              <FormattedMessage
                id="profile.accountDetails.logOut"
                defaultMessage="Log out"
                description="Log out button, user will be dropped back to the login page"
              />
            </Button>
          </div>
        </header>

        <div id="profile-height-benchmark" />

        <Footer />
      </div>
    </>
  );
};

export default ProfileLayout;
