import React from 'react';
import t from 'prop-types';
import Loader from '../common/Loader';
import styles from './PendingAlert.scss';

const PendingAlert = ({ title, description }) => (
  <div className={styles.box}>
    {title ? <h2 className={styles.title}>{title}</h2> : null}

    {description ? <p className={styles.description}>{description}</p> : null}

    <Loader colored size={50} />
  </div>
);

PendingAlert.propTypes = {
  title: t.string,
  description: t.string,
};

export default PendingAlert;
