import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import EnterLayout from '../../layout/EnterLayout';
import Alert from '../../common/Alert';
import { type OneOfWebAppIdentities, appIdentity, isWebAppIdentity } from '../../../utils/consts/appIdentity';
import { paramsKeys } from '../../../utils/consts/params';

const webAppIdentityToAuthMap: Record<OneOfWebAppIdentities, string> = {
  [appIdentity.SITE]: '/enter', // is altered to /redeem landing page if promocode is present
  [appIdentity.CABINET]: '/enter',
  [appIdentity.ORDER_DEFAULT]: '/enter', // this order page does not contain auth flow
  [appIdentity.ORDER_AFFILIATE]: `${process.env.ORDER_PAGE_URL}/aff-buy/`,
  [appIdentity.ORDER_AFFILIATE_TRIALS]: `${process.env.ORDER_PAGE_URL}/aff-trial/`,
  [appIdentity.ORDER_INFLUENCERS]: `${process.env.ORDER_PAGE_URL}/influencer/`,
};

const createWebOTCLink = (): string => {
  const params = new URLSearchParams(window.location.search);

  const appIdentityParam = params.get(paramsKeys.APP_IDENTITY);

  if (appIdentityParam && isWebAppIdentity(appIdentityParam)) {
    // if there is a promocode in the params, send the user back to the redeem page, where it can be applied
    if (appIdentityParam === appIdentity.SITE && params.get(paramsKeys.PROMOCODE)) {
      return `https://clearvpn.com/redeem/${window.location.search}`;
    }

    return `${webAppIdentityToAuthMap[appIdentityParam]}${window.location.search}`;
  }

  return `${webAppIdentityToAuthMap[appIdentity.CABINET]}${window.location.search}`;
};

const OTCForWeb = () => {
  const [link] = useState(createWebOTCLink());

  const intl = useIntl();

  return (
    <EnterLayout verticalPaddings={[90, 60]}>
      <Alert
        href={link}
        icon="success"
        title={intl.formatMessage({
          id: 'otc.web.title',
          defaultMessage: 'Back to ClearVPN',
        })}
        actionTitle={intl.formatMessage({
          id: 'otc.web.cta',
          defaultMessage: 'Continue',
          description: 'Button that opens up ClearVPN app',
        })}
      />
    </EnterLayout>
  );
};

export default OTCForWeb;
