import React from 'react';
import { FormattedMessage } from 'react-intl';
import issueIcon from '../../../../../assets/icons/payment-methods/payment-issue-icon.svg';
import styles from './PaymentIssue.scss';

const PaymentIssue = () => (
  <div className={styles.wrapper}>
    <img src={issueIcon} width="24" height="24" alt="Exclamation mark icon" />

    <div className={styles.textColumn}>
      <h4 className={styles.textColumnTitle}>
        <FormattedMessage
          id="profile.billing.paymentIssue.title"
          defaultMessage="Payment issue"
          description="Title of a payment issue view"
        />
      </h4>

      <p className={styles.textColumnPara}>
        <FormattedMessage
          id="profile.billing.paymentIssue.text"
          defaultMessage="Something went wrong during billing, please check availability of your payment method or change it to other to continue using ClearVPN."
          description="Text of a payment issue view"
        />
      </p>

      <p className={styles.textColumnPara}>
        <FormattedMessage
          id="profile.billing.paymentIssue.links"
          defaultMessage="You can also <faqLink>Visit our FAQ page</faqLink> or <supportLink>Contact support</supportLink>"
          description="Links block to direct user to support for help with resolving a billing issue"
          values={{
            faqLink: (chunk) => <a href="https://support.clearvpn.com">{chunk}</a>,
            supportLink: (chunk) => (
              <a href="https://support.clearvpn.com/hc/en-us/requests/new?open_chat=1">{chunk}</a>
            ),
          }}
        />
      </p>
    </div>
  </div>
);

export default PaymentIssue;
