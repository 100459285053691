const DevicesIcon = ({ isSelected }: { isSelected: boolean }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="multiple-devices">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.50001 5.00004C2.50001 4.07957 3.24621 3.33337 4.16668 3.33337H14.5833C15.5038 3.33337 16.25 4.07957 16.25 5.00004V7.50004L17.5 7.50004C18.4205 7.50004 19.1667 8.24623 19.1667 9.16671V15.8334C19.1667 16.7538 18.4205 17.5 17.5 17.5H14.1667C13.2462 17.5 12.5 16.7538 12.5 15.8334H2.50001C1.12661 15.8334 0.342637 14.2654 1.16668 13.1667L2.50001 11.3889V5.00004ZM12.5 14.1667V12.5H3.75002L2.50001 14.1667L12.5 14.1667ZM12.5 10.8334L12.5 9.16671C12.5 8.24623 13.2462 7.50004 14.1667 7.50004L14.5833 7.50004V5.83337C14.5833 5.37314 14.2103 5.00004 13.75 5.00004H5.00001C4.53978 5.00004 4.16668 5.37314 4.16668 5.83337V10.8334H12.5ZM14.1667 9.16671H17.5V15.8334H14.1667V9.16671Z"
        fill={isSelected ? '#ED3984' : '#212231'}
        fillOpacity={isSelected ? 1 : 0.64}
      />
    </g>
  </svg>
);

export default DevicesIcon;
