import React from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch, Switch } from 'react-router-dom';
import Route from '../common/Route';
import SuccessRoute from './Success';

const PaymentRoutes = () => {
  const { path } = useRouteMatch();
  const intl = useIntl();

  return (
    <Switch>
      <Route
        exact
        path={`${path}/success`}
        title={intl.formatMessage({
          id: 'paymentSuccess.seo.title',
          defaultMessage: 'ClearVPN | Success',
          description: 'SEO title of the page, where user ends after purchase from 3rd party service',
        })}
      >
        <SuccessRoute />
      </Route>
    </Switch>
  );
};

export default PaymentRoutes;
