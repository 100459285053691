import React, { type ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import Loader from './Loader';
import styles from './Button.scss';

const BUTTON_STYLES = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  white: 'white',
} as const;

type OneOfButtonStyles = (typeof BUTTON_STYLES)[keyof typeof BUTTON_STYLES];

const BUTTON_SIZES = {
  small: 'small',
  large: 'large',
} as const;

type OneOfButtonSizes = (typeof BUTTON_SIZES)[keyof typeof BUTTON_SIZES];

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
  kind?: OneOfButtonStyles;
  buttonSize?: OneOfButtonSizes;
  type?: 'button' | 'submit';
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      onClick,
      kind = BUTTON_STYLES.secondary,
      buttonSize = BUTTON_SIZES.large,
      className = '',
      type = 'button',
      loading,
      ...props
    },
    ref,
  ) => (
    <button
      className={clsx(styles.button, styles[kind], styles[buttonSize], className)}
      type={type}
      onClick={onClick}
      {...props}
      ref={ref}
    >
      {loading ? <Loader size={24} colored={kind !== BUTTON_STYLES.primary} /> : children}
    </button>
  ),
);

Button.displayName = 'Button';

export interface LinkButtonProps extends React.HTMLProps<HTMLAnchorElement> {
  children: ReactNode;
  className?: string;
  kind?: OneOfButtonStyles;
  buttonSize?: OneOfButtonSizes;
  loading?: boolean;
}

const LinkButton = ({
  children,
  kind = BUTTON_STYLES.secondary,
  buttonSize = BUTTON_SIZES.large,
  className = '',
  loading,
  ...props
}: LinkButtonProps) => (
  <a className={clsx(styles.button, styles[kind], styles[buttonSize], className)} {...props}>
    {loading ? <Loader size={24} colored={kind !== BUTTON_STYLES.primary} /> : children}
  </a>
);

export { Button, LinkButton };
