import { FormattedMessage } from 'react-intl';
import { type UserProfile } from '../../../utils/api/schema/profileSchema';
import SectionTitle from '../layout/SectionTitle';
import DeleteAccountBlock from './DeleteAccountBlock';
import EmailBlock from './EmailSection';
import NotificationsBlock from './NotificationsBlock';

interface AccountDetailsSectionProps {
  profile: UserProfile;
}

const AccountDetailsSection = ({ profile }: AccountDetailsSectionProps) => (
  <>
    <SectionTitle>
      <FormattedMessage id="profile.accountDetails.title" defaultMessage="Account details" />
    </SectionTitle>
    <EmailBlock profile={profile} />
    <NotificationsBlock profile={profile} />
    <DeleteAccountBlock />
  </>
);

export default AccountDetailsSection;
