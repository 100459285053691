import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../components/common/Button';
import BackButton from '../../components/Enter/otc/components/BackButton';
import InputError from '../../components/Enter/otc/components/InputError';
import { getParsedParams } from '../../utils/consts/params';
import { constructSniperLink } from '../../utils/emailSniperLinks';
import OTCInput from '../../components/Enter/otc/components/OTCInput';
import TimeOutResendButton from '../../components/Enter/otc/components/TimeOutResendButton';
import { sendChangeEmailApplyRequest } from '../../utils/api/accountApi';
import { isApiError } from '../../utils/api/clearApiClient';
import { genericErrorMessage } from '../../utils/lang/commonErrors';
import { httpResponseCodes } from '../../utils/consts/apiCodes';
import { genereteProfileLinkWithNotification } from '../../utils/consts/profileNotifications';
import ChangeEmailFailedModal from './modals/ChangeEmailFailedModal';
import styles from './ConfirmChangeEmailRoute.scss';

const codeLength = 6;

const OneTimeCodeForm = () => {
  const intl = useIntl();

  const [isErrorModalOpen, setErrorModalOpen] = useState(false);

  const { EMAIL: email, TOKEN: token, OTC_CODE: otcCode } = getParsedParams();

  const codeInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const sniperLink = email ? constructSniperLink(email) : null;

  const [error, setError] = useState<string>('');
  const [isSubmitting, setSubmitting] = useState(false);

  const onCodeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.value.length === codeLength) {
      submitButtonRef.current?.focus();
    }
  };

  const onSubmit = (e?: React.SyntheticEvent) => {
    e?.preventDefault();

    const code = codeInputRef.current?.value;
    const isValid = code && codeInputRef.current.validity.valid;

    if (isValid) {
      setSubmitting(true);
      setError('');
      sendChangeEmailApplyRequest(code)
        .then(
          () => {
            window.location.href = genereteProfileLinkWithNotification('emailChanged');
          },
          (apiError) => {
            if (isApiError(apiError)) {
              if (
                apiError.response?.status === httpResponseCodes.BAD_REQUEST &&
                apiError.response.data.message === 'code invalid'
              ) {
                setError(
                  intl.formatMessage({ id: 'authScreen.otcForm.errors.wrongCode', defaultMessage: 'Wrong code' }),
                );
              } else {
                setErrorModalOpen(true);
              }
            } else {
              setError(intl.formatMessage(genericErrorMessage));
            }
          },
        )
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const onResendEmailClick = token ? () => Promise.resolve(email) : null;

  useEffect(() => {
    codeInputRef.current?.focus();

    if (otcCode && codeInputRef.current) {
      codeInputRef.current.value = otcCode;
      onSubmit();
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={onSubmit}>
        {token && <BackButton onClick={() => {}} />}
        <h3 className={styles.title}>
          <FormattedMessage id="authScreen.otcForm.title" defaultMessage="Enter the verification code" />
        </h3>
        <span className={styles.subtitle}>
          <FormattedMessage
            id="authScreen.otcForm.subtitle"
            defaultMessage="Check your inbox and sign in with the magic link or paste the activation 6-digit activation code below"
          />
          <br />
          {email && (
            <>
              <b>{email}</b>
              {sniperLink ? (
                <a className={styles.subtitleButton} href={sniperLink}>
                  <FormattedMessage
                    id="authScreen.otcForm.sniperLink"
                    defaultMessage="Open inbox"
                    description="Link that will magically open user's email client with our email highlighted"
                  />
                </a>
              ) : null}
            </>
          )}
        </span>

        <OTCInput disabled={isSubmitting} ref={codeInputRef} onCodeChange={onCodeChange} />
        {error ? <InputError error={error} /> : null}

        <Button type="submit" kind="primary" ref={submitButtonRef} loading={isSubmitting}>
          <FormattedMessage
            id="authScreen.otcForm.submitCode"
            defaultMessage="Apply the code"
            description="Button that submits the OTC code"
          />
        </Button>

        {onResendEmailClick && <TimeOutResendButton onResendClick={onResendEmailClick} />}
      </form>

      <ChangeEmailFailedModal
        isOpen={isErrorModalOpen}
        onClose={() => {
          setErrorModalOpen(false);
        }}
      />
    </div>
  );
};

export default OneTimeCodeForm;
