import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../common/Button';
import backArrow from '../../../../../assets/icons/back-black-arrow.svg';
import styles from './BackButton.scss';

interface BackButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const BackButton = ({ disabled, onClick }: BackButtonProps) => (
  <Button kind="tertiary" className={styles.wrapper} disabled={disabled} onClick={onClick}>
    <img src={backArrow} width="16" height="16" alt="" />
    <FormattedMessage
      id="layout.backButton"
      defaultMessage="Back"
      description="Layout button that switches to the previous step"
    />
  </Button>
);

export default BackButton;
