import { paramsKeys } from './params';

const profileNotifications = {
  EMAIL_CHANGED_NOTIFICATION: 'emailChanged',
} as const;

const genereteProfileLinkWithNotification = (
  notification: (typeof profileNotifications)[keyof typeof profileNotifications],
) => {
  return `/profile?${paramsKeys.PROFILE_NOTIFICATION}=${notification}`;
};

export { profileNotifications, genereteProfileLinkWithNotification };
