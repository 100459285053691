import React from 'react';
import { useIntl } from 'react-intl';
import EnterLayout from '../../components/layout/EnterLayout';
import Alert from '../../components/common/Alert';
import useQuery from '../../utils/useQuery';

const AlreadyUsedRoute = () => {
  const { email } = useQuery();

  const intl = useIntl();

  return (
    <EnterLayout>
      <Alert
        description={email}
        href="/enter"
        title={intl.formatMessage({
          id: 'alreadyUsed.title',
          defaultMessage: 'This email is already used',
          description: '',
        })}
        actionTitle={intl.formatMessage({
          id: 'alreadyUsed.cta',
          defaultMessage: 'Log in',
          description: 'Link to log in page, suggesting that user should log in with this email instead of signing up',
        })}
      />
    </EnterLayout>
  );
};

export default AlreadyUsedRoute;
