import { FormattedMessage } from 'react-intl';
import Modal from '../../../components/common/Modal';
import { Button } from '../../../components/common/Button';
import hooverNoImage from '../../../../assets/icons/account-termination/hoover-no.svg';
import styles from './ChangeEmailFailedModal.scss';

interface ChangeEmailFailedModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const onSignInClick = () => {
  window.location.href = '/profile';
};

const ChangeEmailFailedModal = ({ isOpen, onClose }: ChangeEmailFailedModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <img src={hooverNoImage} width="196" height="147" alt="" />

      <h3 className={styles.title}>
        <FormattedMessage id="changeEmail.failModal.title" defaultMessage="You were not verified" />
      </h3>

      <p className={styles.text}>
        <FormattedMessage
          id="changeEmail.failModal.text"
          defaultMessage="Code verification failed. Do you want to try Sign in?"
        />
      </p>

      <div className={styles.buttonContainer}>
        <Button onClick={onClose}>
          <FormattedMessage id="changeEmail.failModal.cancelButton" defaultMessage="Cancel" />
        </Button>
        <Button kind="primary" className={styles.signInButton} onClick={onSignInClick}>
          <FormattedMessage id="changeEmail.failModal.signInButton" defaultMessage="Sign in" />
        </Button>
      </div>
    </Modal>
  );
};

export default ChangeEmailFailedModal;
