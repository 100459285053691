import React, { useRef } from 'react';
import InputError from './InputError';
import styles from './TextInput.scss';

interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  /** current email value */
  value: string;
  handleChange: ({ value, isValid }: { value: string; isValid: boolean }) => void;
  onBlur?: () => void;
  placeholder?: string;
  /** text of the error, ignored if empty string */
  error?: string;
  className?: string;
  /** element to be placed at the right end of the input */
  rightSideSlot?: React.ReactNode;
}

/**
 * Text input according to the design system.
 * https://www.figma.com/file/cKU9Z0zAvOHkVJNCoWlSCs/%F0%9F%96%A5-Clear-DS-(Web-Kit)?node-id=11%3A10412
 */
const TextInput = ({
  value,
  placeholder,
  handleChange,
  onBlur,
  error,
  className = '',
  rightSideSlot = null,
  ...props
}: TextInputProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    handleChange({
      value: event.target.value,
      isValid: inputRef.current?.checkValidity() ?? false,
    });
  };

  const handleBlur = () => {
    // avoid calling onBlur, when focus switches to element within wrapper
    // i.e. prevent validation callback, when side button within input is clicked
    if (onBlur && !wrapperRef.current?.matches(':focus-within')) {
      onBlur();
    }
  };

  return (
    <div className={`${styles.field} ${className || ''}`} ref={wrapperRef}>
      <div className={`${styles.wrapper} ${error ? styles.errorWrapper : ''}`}>
        <input
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          value={value}
          className={styles.input}
          onChange={onChange}
          onBlur={handleBlur}
          {...props}
        />

        {rightSideSlot ? <div className={styles.rightSideSlotWrapper}>{rightSideSlot}</div> : null}
      </div>

      <InputError error={error} />
    </div>
  );
};

export default TextInput;
