import Cookies from 'js-cookie';
import { type UserProfile } from '../api/schema/profileSchema';
import { resolvePlatform } from '../platformResolver';

const AUTH_PROVIDER_STORAGE_KEY = 'auth_provider';

// if the user signed up less than 5 minutes ago, we consider it a new account
const MAX_NEW_ACCOUNT_SIGN_UP_GAP_MILLS = 1000 * 60 * 5;

const OAUTH_PROVIDERS = ['google', 'apple-id', 'setapp'] as const;
const OTC_AUTH_PROVIDER = 'otc';

const isValidOauthProvider = (provider: string): provider is (typeof OAUTH_PROVIDERS)[number] =>
  (OAUTH_PROVIDERS as unknown as string[]).includes(provider);

const logOAuthClick = (provider: (typeof OAUTH_PROVIDERS)[number]) => {
  Cookies.set(AUTH_PROVIDER_STORAGE_KEY, provider, { domain: '.clearvpn.com' });
};

const isUserNew = (userRegistrationDate: string) => {
  const registrationDate = new Date(userRegistrationDate).getTime();

  return Date.now() - registrationDate < MAX_NEW_ACCOUNT_SIGN_UP_GAP_MILLS;
};

const logAuthSuccess = (userRegistrationDate: string) => {
  const storedProvider = Cookies.get(AUTH_PROVIDER_STORAGE_KEY);

  const isNew = isUserNew(userRegistrationDate);

  if (storedProvider && isValidOauthProvider(storedProvider)) {
    window.dataLayer?.push({
      event: isNew ? 'sign-up' : 'sign-in',
      'clear-eventCategory': isNew ? 'register-auth' : 'login-auth',
      'clear-eventAction': `sign-${isNew ? 'up' : 'in'}-${storedProvider}`,
      'clear-eventLabel': resolvePlatform(),
    });
  } else if (storedProvider === OTC_AUTH_PROVIDER) {
    window.dataLayer?.push({
      event: 'user-type',
      'clear-eventCategory': 'user-type',
      'clear-eventAction': isNew ? 'new' : 'return',
      'clear-eventLabel': resolvePlatform(),
    });
  }

  Cookies.remove(AUTH_PROVIDER_STORAGE_KEY);
};

const logOTCEmailSubmit = () => {
  window.dataLayer?.push({
    event: 'enter-email',
    'clear-eventCategory': 'login-form-main',
    'clear-eventAction': 'enter-email',
    'clear-eventLabel': resolvePlatform(),
  });
};

const logOTCCodeSubmitSuccess = () => {
  window.dataLayer?.push({
    event: 'verification-code',
    'clear-eventCategory': 'login-form-main',
    'clear-eventAction': 'verification-code',
    'clear-eventLabel': resolvePlatform(),
  });

  Cookies.set(AUTH_PROVIDER_STORAGE_KEY, OTC_AUTH_PROVIDER, { domain: '.clearvpn.com' });
};

const logOTCEmailResend = () => {
  window.dataLayer?.push({
    event: 'profile',
    'clear-eventCategory': 'profile',
    'clear-eventAction': 'resend_click',
    'clear-eventLabel': resolvePlatform(),
  });
};

const handleAuthSuccessEvents = (profile: UserProfile) => {
  window.dataLayer?.push({ account_id: profile.account_id });
  logAuthSuccess(profile.registration_date);
};

export { logOAuthClick, handleAuthSuccessEvents, logOTCEmailSubmit, logOTCCodeSubmitSuccess, logOTCEmailResend };
