const appIdentity = {
  MACOS_MAS: 'com.macpaw.clearvpn.macosmas',
  MACOS_SETAPP: 'com.macpaw.clearvpn.macos-setapp',
  MACOS_SITE: 'com.macpaw.clearvpn.macos-site-ver',
  WINDOWS: 'com.macpaw.clearvpn.windows',
  ANDROID: 'com.macpaw.clearvpn.android',
  IOS: 'com.macpaw.clearvpn.ios',

  CABINET: 'my.clearvpn.com',

  SITE: 'clearvpn.com',

  ORDER_DEFAULT: 'order.clearvpn.com',
  ORDER_AFFILIATE: 'clearvpn2.affiliate',
  ORDER_AFFILIATE_TRIALS: 'clearvpn2.affiliate.trials',
  ORDER_INFLUENCERS: 'clearvpn2.influencer',
} as const;

const webAppIdentities = [
  appIdentity.CABINET,
  appIdentity.SITE,
  appIdentity.ORDER_DEFAULT,
  appIdentity.ORDER_AFFILIATE,
  appIdentity.ORDER_AFFILIATE_TRIALS,
  appIdentity.ORDER_INFLUENCERS,
] as const;

type OneOfWebAppIdentities = (typeof webAppIdentities)[number];

const isWebAppIdentity = (identity: string): identity is OneOfWebAppIdentities =>
  (webAppIdentities as unknown as string[]).includes(identity);

export { appIdentity, isWebAppIdentity, type OneOfWebAppIdentities };
