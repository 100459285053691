import React from 'react';
import { useRouteMatch, Switch, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Route from '../common/Route';
import { errorParams } from '../../utils/consts/errorParams';
import AlreadyUsedRoute from './AlreadyUsedRoute';
import SubscriptionConfirmed from './SubscriptionConfirmed';

const EmailRoutes = () => {
  const { path } = useRouteMatch();

  const intl = useIntl();

  return (
    <Switch>
      <Route exact path={`${path}/verify`}>
        <Redirect to={`/enter?error=${errorParams.depreactedEmailVerify}`} />
      </Route>

      <Route
        exact
        path={`${path}/already-used`}
        title={intl.formatMessage({
          id: 'alreadyUsed.seo.title',
          defaultMessage: 'ClearVPN | Email already used',
          description:
            'SEO title of the error page, that user gets onto by trying to sign up using an already registered email',
        })}
      >
        <AlreadyUsedRoute />
      </Route>

      <Route
        exact
        path={`${path}/subscription-confirmed`}
        title={intl.formatMessage({
          id: 'newsletterSubConfirmed.seo.title',
          defaultMessage: 'ClearVPN | Newsletter subscription confirmed',
          description: 'SEO title of the page, that subscribes the user to the email newsletter',
        })}
      >
        <SubscriptionConfirmed />
      </Route>
    </Switch>
  );
};

export default EmailRoutes;
