import React from 'react';
import t from 'prop-types';
import textColBg from '../../../assets/icons/feedback/text-col-bg.svg';
import logoImg from '../../../assets/icons/feedback/logo.png';
import logoImg2x from '../../../assets/icons/feedback/logo@2x.png';
import logoImgMobile from '../../../assets/icons/feedback/logo-mobile.png';
import logoImgMobile2x from '../../../assets/icons/feedback/logo-mobile@2x.png';
import styles from './FeedbackLayout.scss';

const FeedbackPage = ({ copy, children }) => {
  return (
    <div className={styles.wrapper}>
      <aside className={styles.textCol}>
        <img src={textColBg} alt="" width="1287" height="1042" className={styles.textColBackground} />

        <div className={styles.textColContainer}>
          <header>
            <a href="/">
              <picture className={styles.textColLogo}>
                <source
                  media="(max-width: 700px)"
                  srcSet={`${logoImgMobile} 1x, ${logoImgMobile2x} 2x`}
                  type="image/png"
                />
                <img src={logoImg} srcSet={`${logoImg2x} 2x`} width="240" height="66" alt="" />
              </picture>
            </a>
          </header>

          <div>
            <h1 className={styles.textColTitle}>{copy.title}</h1>

            {copy.text ? <p className={styles.textColText}>{copy.text}</p> : null}

            {copy.button ? (
              <a href={copy.button.href} className={styles.textColButton}>
                {copy.button.text}
              </a>
            ) : null}
          </div>

          <nav className={styles.textColLinks}>
            <a href="https://clearvpn.com/terms-of-service/">Terms & Conditions</a>
            <a href="https://clearvpn.com/privacy-policy/">Privacy Policy</a>
            <a href="https://support.clearvpn.com/hc/en-us">Contact support</a>
          </nav>
        </div>
      </aside>

      <main className={styles.formCol}>{children}</main>

      <footer className={styles.mobileFooter}>
        <a href="https://clearvpn.com/terms-of-service/">Terms & Conditions</a>
        <a href="https://clearvpn.com/privacy-policy/">Privacy Policy</a>
        <a href="https://support.clearvpn.com/hc/en-us">Contact support</a>
      </footer>
    </div>
  );
};

FeedbackPage.propTypes = {
  copy: t.shape({
    title: t.string.isRequired,
    text: t.string,
    button: t.shape({
      text: t.string.isRequired,
      href: t.string.isRequired,
    }),
  }).isRequired,
  children: t.element,
};

export default FeedbackPage;
