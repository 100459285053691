import { FormattedMessage } from 'react-intl';
import BlockTitle from '../layout/BlockTitle';
import styles from './ReferralInstructionsBlock.scss';

const ReferralInstructionsBlock = () => (
  <div>
    <BlockTitle>
      <FormattedMessage
        id="profile.referral.instructions.title"
        defaultMessage="How program works"
        description="Title of the block explaining how the referral system works"
      />
    </BlockTitle>

    <ol className={styles.instructions}>
      <li>
        <FormattedMessage
          id="profile.referral.instructions.step1"
          defaultMessage="Invite up to 5 people to purchase ClearVPN."
        />
      </li>
      <li>
        <FormattedMessage
          id="profile.referral.instructions.step2"
          defaultMessage="Each friend gets 20% off their first subscription payment."
        />
      </li>
      <li>
        <FormattedMessage
          id="profile.referral.instructions.step3"
          defaultMessage="Get up to 90% off your first Premium payment."
        />
      </li>
    </ol>
  </div>
);

export default ReferralInstructionsBlock;
