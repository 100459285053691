import React from 'react';
import { useRouteMatch, Switch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import AuthRoute from '../common/AuthRoute';
import Profile from './Profile';

const ProfileRoutes = () => {
  const { path } = useRouteMatch();
  const intl = useIntl();

  return (
    <Switch>
      <AuthRoute
        exact
        path={path}
        title={intl.formatMessage({
          id: 'profile.seo.title',
          defaultMessage: 'ClearVPN | Profile',
          description: 'SEO title of the main user cabinet page',
        })}
      >
        <Profile />
      </AuthRoute>
    </Switch>
  );
};

export default ProfileRoutes;
