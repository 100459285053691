import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import t from 'prop-types';
import { isLoggedIn } from '../../utils/auth';
import { useRedirectUrl } from '../../utils/redirectAfterLogin';
import Route from './Route';

/**
 * Route that renders the `children` if the user is logged in or redirects to `/enter` otherwise.
 * @param props
 * @param {string} props.path - path to the route
 * @param {JSX.Element} props.children
 * @param {string} [props.title] - title of the page
 * @param {boolean} [props.exact]
 * @returns {JSX.Element}
 */
const AuthRoute = ({ path, children, title, exact }) => {
  const { pathname, search } = useLocation();
  const { updateRedirectUrl } = useRedirectUrl();
  const history = useHistory();

  useEffect(() => {
    if (!isLoggedIn() && !location.search.includes('token')) {
      // remembering original URL
      // they will be redirected to `path` after login
      updateRedirectUrl(pathname + search);
      console.log('Redirecting from AuthRoute');
      history.replace('/enter');
    }
  }, []);

  return (
    <Route path={path} title={title} exact={exact}>
      {children}
    </Route>
  );
};

AuthRoute.propTypes = {
  path: t.string.isRequired,
  children: t.element.isRequired,
  title: t.string,
  exact: t.bool,
};

export default AuthRoute;
