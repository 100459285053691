import Cookies from 'js-cookie';

const cookieDomain = process.env.COOKIE_DOMAIN;

const ACCESS_TOKEN = process.env.ACCESS_TOKEN_COOKIE_NAME;
const REFRESH_TOKEN = process.env.REFRESH_TOKEN_COOKIE_NAME;

/**
 * Checks whether the `'access_token'` cookie is present
 * @returns {boolean}
 */
const isLoggedIn = () => Boolean(Cookies.get(ACCESS_TOKEN));

/**
 * Saves Auth tokens to Cookies
 * @param accessToken
 * @param refreshToken
 */
const saveTokens = (accessToken, refreshToken = '') => {
  Cookies.set(ACCESS_TOKEN, accessToken, {
    domain: cookieDomain,
    path: '/',
    secure: true,
  });
  Cookies.set(REFRESH_TOKEN, refreshToken, {
    domain: cookieDomain,
    path: '/',
    secure: true,
  });

  if (location.hostname === 'localhost') {
    Cookies.set(ACCESS_TOKEN, accessToken, {
      domain: 'localhost',
      path: '/',
      secure: true,
    });
    Cookies.set(REFRESH_TOKEN, refreshToken, {
      domain: 'localhost',
      path: '/',
      secure: true,
    });
  }
};

/**
 * Clears Access and Refresh tokens from cookies
 */
const removeTokens = () => {
  Cookies.remove(ACCESS_TOKEN, {
    domain: cookieDomain,
    path: '/',
  });
  Cookies.remove(REFRESH_TOKEN, {
    domain: cookieDomain,
    path: '/',
  });

  if (location.hostname === 'localhost') {
    Cookies.remove(ACCESS_TOKEN, {
      domain: 'localhost',
      path: '/',
    });
    Cookies.remove(REFRESH_TOKEN, {
      domain: 'localhost',
      path: '/',
    });
  }
};

/**
 * Gets the access token from cookies
 * @returns {?string}
 */
const getAccessToken = () => Cookies.get(ACCESS_TOKEN);

/**
 * Gets the refresh token from cookies
 * @returns {?string}
 */
const getRefreshToken = () => Cookies.get(REFRESH_TOKEN);

const redirectToAuth = () => {
  document.location = '/enter';
};

const logOut = () => {
  removeTokens();
  window.location.href = '/enter';
};

export { isLoggedIn, saveTokens, removeTokens, getAccessToken, getRefreshToken, redirectToAuth, logOut, cookieDomain };
