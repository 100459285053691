import React from 'react';
import { useRouteMatch, Switch, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Route from '../common/Route';
import EmailRoutes from '../Email';
import EnterForm from '../../components/Enter/EnterForm';
import { paramsKeys } from '../../utils/consts/params';
import { errorParams } from '../../utils/consts/errorParams';
import AccountCode from './AccountCode';

const EnterRoutes = () => {
  const { path } = useRouteMatch();

  const intl = useIntl();

  return (
    <Switch>
      <Route
        exact
        path={path}
        title={intl.formatMessage({
          id: 'enter.seo.title',
          defaultMessage: 'ClearVPN | Enter',
          description: 'SEO title of the Enter page, that provides user with available login options',
        })}
      >
        <EnterForm />
      </Route>

      <Route exact path={`${path}/restore`}>
        <Redirect to={`/enter/${paramsKeys.ERROR}=${errorParams.accountTerminated}`} />
      </Route>

      <Route exact path={`${path}/referral`}>
        <Redirect to="https://clearvpn.com/redeem/" />
      </Route>

      <Route
        exact
        path={`${path}/account-code`}
        title={intl.formatMessage({
          id: 'accountCode.seo.title',
          defaultMessage: 'ClearVPN | Account Code',
          description: 'SEO title of the account code sign up page',
        })}
      >
        <AccountCode />
      </Route>

      <Route path={`${path}/email`}>
        <EmailRoutes />
      </Route>

      <Route path={`${path}/password`}>
        <Redirect to="/enter" />
      </Route>

      <Route exact path={`${path}/login`}>
        <Redirect to={{ pathname: path, search: window.location.search }} />
      </Route>
    </Switch>
  );
};

export default EnterRoutes;
