import React from 'react';
import { appIdentity } from '../../../../utils/consts/appIdentity';
import { useLocale } from '../../../lang/LocalizationProvider';
import { logOAuthClick } from '../../../../utils/analytics/authEvents';
import googleOauthIcon from '../../../../../assets/icons/oauth/google-oauth-icon.svg';
import appleOauthIcon from '../../../../../assets/icons/oauth/apple-oauth-icon.svg';
import setappOauthIcon from '../../../../../assets/icons/oauth/setapp-oauth-icon.svg';
import styles from './SocialButton.scss';

interface SocialButtonProps {
  type: 'google' | 'apple' | 'setapp';
}

/**
 * https://www.figma.com/file/cKU9Z0zAvOHkVJNCoWlSCs/%F0%9F%96%A5-Clear-DS-(Web-Kit)?node-id=7%3A3007
 */
const SocialButton = ({ type }: SocialButtonProps) => {
  const { selectedLocalization } = useLocale();

  const onClick = () => {
    if (type === 'apple') {
      logOAuthClick('apple-id');
    } else {
      logOAuthClick(type);
    }

    window.location.href = `${
      process.env.API_BASE_URL
    }/web/v1/account/oauth/${type}/login?app-identity=${appIdentity.CABINET}&locale=${selectedLocalization.toLocaleLowerCase()}`;
  };

  switch (type) {
    case 'setapp':
    case 'google':
    case 'apple': {
      return (
        <button
          title={`Sign up with ${type.slice(0, 1).toUpperCase()}${type.slice(1)}`}
          className={`${styles.socialButton} social-button-${type}`}
          type="button"
          onClick={onClick}
        >
          {type === 'apple' && <img src={appleOauthIcon} width="40" height="40" alt={`${type} icon`} />}
          {type === 'google' && <img src={googleOauthIcon} width="40" height="40" alt={`${type} icon`} />}
          {type === 'setapp' && <img src={setappOauthIcon} width="40" height="40" alt={`${type} icon`} />}
        </button>
      );
    }

    default: {
      return null;
    }
  }
};

export default SocialButton;
