import React from 'react';
import Loader, { type LoaderProps } from '../../common/Loader';
import styles from './SubmitButton.scss';

interface SubmitButtonProps {
  text: string;
  onSubmit?: () => void;
  /** whether to display a loader instead of `text` */
  isLoading?: boolean;
  className?: string;
  /** props to pass down to the <Loader> view inside */
  loaderProps?: LoaderProps;
}

/**
 * @deprecated use `Button` from `@/components/common/Button` instead
 */
const SubmitButton = ({ text, onSubmit, isLoading, className, loaderProps }: SubmitButtonProps) => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <button
      className={`${styles.submitButton} ${className} ${isLoading ? styles.hasLoader : ''}`}
      type="submit"
      data-tabable-field="submit"
      onClick={onSubmit ? onClick : undefined}
    >
      {isLoading ? <Loader {...loaderProps} /> : text}
    </button>
  );
};

export default SubmitButton;
