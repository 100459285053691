import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './reCaptchaDisclaimer.scss';

const ReCaptchaDisclaimer = () => (
  <span className={styles.disclaimer}>
    <FormattedMessage
      id="enter.reCaptchaDisclaimer"
      defaultMessage="This site is protected by reCAPTCHA and the Google <policyLink>Privacy Policy</policyLink> and <termsLink>Terms of Service</termsLink> apply."
      description="Disclaimer on login and signup forms about reCaptcha."
      values={{
        policyLink: (chunk) => (
          <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
            {chunk}
          </a>
        ),
        termsLink: (chunk) => (
          <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
            {chunk}
          </a>
        ),
      }}
    />
  </span>
);

export default ReCaptchaDisclaimer;
