import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { getParsedParams } from '../../../utils/consts/params';
import { profileNotifications } from '../../../utils/consts/profileNotifications';
import checkImage from '../../../../assets/icons/check-green.svg';
import styles from './FloatingNotification.scss';

const FloatingNotification = () => {
  const [isShown, setShown] = useState(false);

  useEffect(() => {
    const { PROFILE_NOTIFICATION } = getParsedParams();

    if (PROFILE_NOTIFICATION === profileNotifications.EMAIL_CHANGED_NOTIFICATION) {
      setShown(true);
      window.history.replaceState({}, document.title, '/profile');
    }
  }, []);

  if (!isShown) {
    return null;
  }

  return (
    <aside className={styles.container}>
      <button
        className={styles.notification}
        onClick={() => {
          setShown(false);
        }}
      >
        <img src={checkImage} alt="" width={24} height={24} />
        <span className={styles.text}>
          <FormattedMessage
            id="profile.notifictions.emailChanged"
            defaultMessage="Email has been changed successfully"
          />
        </span>
      </button>
    </aside>
  );
};

export default FloatingNotification;
