import { getParsedParams, paramsKeys } from './consts/params';

const carryThroughKeys: Readonly<Array<keyof typeof paramsKeys>> = [
  'FUNNEL',
  'CLICK_ID',
  'UTM_SOURCE',
  'UTM_MEDIUM',
  'UTM_CAMPAIGN',
  'AD_SET',
  'SHORTCUT',
  'AD_GROUP_ID',
  'APP_IDENTITY',
  // these few below are non URL-params ones, should get them from state
  'PROMOCODE',
  'PLAN',
] as const;

export const collectCarryThroughParams = ({
  code,
  plan,
}: {
  code?: string;
  plan?: string;
}): Record<(typeof paramsKeys)[(typeof carryThroughKeys)[number]], string> => {
  const urlParams = getParsedParams();

  const returnParams = carryThroughKeys.reduce<Record<(typeof paramsKeys)[(typeof carryThroughKeys)[number]], string>>(
    (acc, cur) => {
      const param = urlParams[cur];

      if (param) {
        acc[paramsKeys[cur]] = param;
      }

      return acc;
    },
    {},
  );

  if (!returnParams.clickid && urlParams.IR_CLICK_ID) {
    returnParams.clickid = urlParams.IR_CLICK_ID;
  }

  if (code) {
    returnParams.code = code;
  }

  if (plan) {
    returnParams.plan = plan;
  }

  return returnParams;
};
