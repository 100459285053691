import { useElements, CardNumberElement } from '@stripe/react-stripe-js';
import { sendPaymentSetupIntentRequest, sendUpdatePaymentMethodRequest } from '../api/storeApi';

export const useCardFormListeners = (updateValidation) => {
  const elements = useElements();

  return {
    handleNumberChange: ({ complete, empty, error }) => {
      updateValidation((v) => ({ ...v, card: { complete, empty, error } }));
      if (complete) {
        elements.getElement('cardExpiry').focus();
      }
    },
    handleExpirationChange: ({ complete, empty, error }) => {
      updateValidation((v) => ({ ...v, expiration: { complete, empty, error } }));
      if (complete) {
        elements.getElement('cardCvc').focus();
      }
    },
    handleCSCChange: ({ complete, empty, error }) => {
      updateValidation((v) => ({ ...v, cvc: { complete, empty, error } }));
    },
  };
};

const processMethodUpdate = (stripe, paymentMethod, paymentMethodType) =>
  sendPaymentSetupIntentRequest(paymentMethod.id)
    .then(
      ({
        data: {
          requires_action: requiresAction,
          stripe_payment_setup_intent_id: setupIntentId,
          stripe_payment_setup_intent_client_secret: setupIntentSecret,
        },
      }) => {
        if (requiresAction) {
          return stripe
            .confirmCardSetup(setupIntentSecret, { payment_method: paymentMethod.id })
            .then(({ error, setupIntent }) => {
              if (error) {
                throw error;
              }

              return setupIntent.id;
            });
        }

        return setupIntentId;
      },
    )
    .then((setupIntendId) => sendUpdatePaymentMethodRequest(setupIntendId, paymentMethodType));

export const onGooglePaySubmit = (stripe, paymentMethod, onComplete) =>
  processMethodUpdate(stripe, paymentMethod, 'google_pay').then(() => onComplete('success'));
export const onApplePaySubmit = (stripe, paymentMethod, onComplete) =>
  processMethodUpdate(stripe, paymentMethod, 'apple_pay').then(() => onComplete('success'));

export const onChangeMethodCardSubmit = (elements, stripe) => {
  const cardElement = elements.getElement(CardNumberElement);

  return stripe
    .createPaymentMethod({
      type: 'card',
      card: cardElement,
    })
    .then((res) => {
      if (res.error) {
        throw res;
      }

      return res.paymentMethod;
    })
    .then((paymentMethod) => processMethodUpdate(stripe, paymentMethod, 'card'));
};
