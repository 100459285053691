import { FormattedMessage } from 'react-intl';
import { type UserProfile } from '../../../utils/api/schema/profileSchema';
import SectionTitle from '../layout/SectionTitle';
import ReferralBlock from './ReferralLinkBlock';
import ReferralInstructions from './ReferralInstructionsBlock';
import ReferralInfo from './ReferralInfoBlock';

const ReferralSection = ({ profile }: { profile: UserProfile }) => (
  <>
    <SectionTitle>
      <FormattedMessage id="profile.referral.title" defaultMessage="Refer a friend" />
    </SectionTitle>
    <ReferralInfo />
    <ReferralBlock profile={profile} />
    <ReferralInstructions />
  </>
);

export default ReferralSection;
