import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { type UserProfile } from '../../../utils/api/schema/profileSchema';
import { Button } from '../../common/Button';
import BlockTitle from '../layout/BlockTitle';
import GrayBox from '../layout/GrayBox';
import ChangeEmailModal from './components/ChangeEmailModal';
import EmailLabel from './components/EmailLabel';
import styles from './EmailSection.scss';

const UnknownEmailPlaceholder = () => (
  <FormattedMessage
    id="profile.accountDetails.unknownEmail"
    defaultMessage="Unknown Email"
    description="Placeholder, for when don’t know user’s email, i.e. signed up via social network"
  />
);

interface AccountDetailsProps {
  profile: UserProfile;
}

const EmailBlock = ({ profile }: AccountDetailsProps) => {
  const { is_email_set: isEmailSet, oauth_credentials: oAuthCredentials } = profile;

  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false);

  return (
    <section>
      <BlockTitle>
        <FormattedMessage id="profile.accountDetails.emailBlock.title" defaultMessage="Email" />
      </BlockTitle>

      <GrayBox className={styles.nameBlock}>
        <div className={styles.nameWrapper} key="email">
          <span className={styles.name}>{isEmailSet ? profile.email : <UnknownEmailPlaceholder />}</span>
          <span className={styles.nameFullView}>{isEmailSet ? profile.email : <UnknownEmailPlaceholder />}</span>
        </div>

        {oAuthCredentials.vendor === 'account_oauth_default' ? (
          <Button
            className={styles.changeEmailButton}
            kind="primary"
            buttonSize="small"
            onClick={() => {
              setIsChangeEmailModalOpen(true);
            }}
          >
            <FormattedMessage id="profile.accountDetails.emailBlock.changeEmailButton" defaultMessage="Change email" />
          </Button>
        ) : (
          <EmailLabel oAuthVendor={oAuthCredentials.vendor} isSetappUser={profile.type === 'setapp'} />
        )}
      </GrayBox>

      <ChangeEmailModal
        isOpen={isChangeEmailModalOpen}
        onConfirm={() => {
          setIsChangeEmailModalOpen(false);
        }}
      />
    </section>
  );
};

export default EmailBlock;
