import { debounce } from 'lodash';

const targetHeightContainerId = 'profile-height-benchmark';
const scrollContainerId = 'profile-scroll-container';

const fixateScrollContainerHeight = () => {
  const setScrollContainerHeight = () => {
    const targetHeightContainer = document.querySelector<HTMLDivElement>(`#${targetHeightContainerId}`);
    const scrollContainer = document.querySelector<HTMLDivElement>(`#${scrollContainerId}`);

    if (targetHeightContainer && scrollContainer) {
      console.log('setScrollContainerHeight', targetHeightContainer.clientHeight);
      scrollContainer.style.maxHeight = `${targetHeightContainer.clientHeight}px`;
    }
  };

  setScrollContainerHeight();

  const debouncedListener = debounce(setScrollContainerHeight, 150) as () => void;

  window.addEventListener('resize', debouncedListener);

  window.addEventListener('unload', () => {
    window.removeEventListener('resize', debouncedListener);
  });
};

export default fixateScrollContainerHeight;
