const defaultSystemEmailAddress = 'team@clearvpn.com';

const isGmail = (email: string) => email.includes('@gmail.') || email.includes('@google');

const isOutlook = (email: string) =>
  email.includes('@hotmail') ||
  email.includes('@outlook') ||
  email.includes('@live.') ||
  email.includes('@windowslive');

const isYahoo = (email: string) =>
  email.includes('@yahoo') || email.includes('@yahoomail') || email.includes('@rocketmail') || email.includes('@ymail');

const isProtonEmail = (email: string) =>
  email.includes('@proton') || email.includes('@protonmail') || email.includes('@pm.');

const isICloudMail = (email: string) => email.includes('@icloud') || email.includes('@me.') || email.includes('@mac.');

export const constructSniperLink = (userEmail: string, senderEmail = defaultSystemEmailAddress) => {
  const encodedSenderEmail = encodeURIComponent(senderEmail);
  const encodedUserEmail = encodeURIComponent(userEmail);

  if (isGmail(userEmail)) {
    return `https://mail.google.com/mail/u/${userEmail}/#search/from%3A(${encodedSenderEmail})+in%3Aanywhere`;
  }
  if (isOutlook(userEmail)) {
    return `https://outlook.live.com/mail/?login_hint=${encodedUserEmail}`;
  }
  if (isYahoo(userEmail)) {
    return `https://mail.yahoo.com/d/search/keyword=from%253A${encodedSenderEmail}`;
  }
  if (isProtonEmail(userEmail)) {
    return `https://mail.proton.me/u/0/all-mail#from=${encodedSenderEmail}`;
  }
  if (isICloudMail(userEmail)) {
    return 'https://www.icloud.com/mail/';
  }

  return null;
};
