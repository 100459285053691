import React from 'react';
import { type OneOfLocalizations, useLocale } from '../lang/LocalizationProvider';
import dropDownArrowImage from '../../../assets/icons/localization/dropdown-arrow.svg';
import styles from './LocalizationSelect.scss';

const localeCodeToLabel: Record<OneOfLocalizations, string> = {
  en: 'EN',
  uk: 'UA', // marketing request
  de: 'DE',
  fr: 'FR',
  es: 'ES',
  pt: 'PT',
} as const;

const localeToLabel: Record<OneOfLocalizations, string> = {
  en: 'English',
  uk: 'Українська',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  pt: 'Português',
} as const;

const LocalizationSelect = ({ className = '' }) => {
  const { selectedLocalization, availableLocalizations, changeLocalization } = useLocale();

  const onChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    changeLocalization(e.target.value);
  };

  return (
    <label className={`${styles.label} ${className}`}>
      {localeCodeToLabel[selectedLocalization]}
      <img src={dropDownArrowImage} width="24" height="24" alt="" />

      <select
        name="Localization"
        id="localization-select"
        value={selectedLocalization}
        className={styles.select}
        onChange={onChange}
      >
        {availableLocalizations.map((locale) => (
          <option value={locale} key={locale}>
            {localeToLabel[locale]}
          </option>
        ))}
      </select>
    </label>
  );
};

export default LocalizationSelect;
