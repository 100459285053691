import React, { type ReactNode } from 'react';
import Footer from '../Footer/Footer';
import LocalizationSelect from '../common/LocalizationSelect';
import Logo from '../../../assets/icons/clearWebLogo.svg';
import styles from './ExtensionPagesLayout.scss';

interface ExtensionPagesLayoutProps {
  children: ReactNode;
}

const ExtensionPagesLayout = ({ children }: ExtensionPagesLayoutProps) => {
  return (
    <div className={styles.container}>
      <main>
        <div className={styles.logoLine}>
          <LocalizationSelect className={styles.localeSelect} />
        </div>

        <div className={styles.innerContainer}>
          <section className={styles.formWrapper} style={{ paddingTop: 45, paddingBottom: 45 }}>
            <img src={Logo} alt="ClearVPN" width={97} height={24} />
            {children}
          </section>
        </div>

        <Footer className={styles.footer} />
      </main>
    </div>
  );
};

export default ExtensionPagesLayout;
