import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { Button } from '../../../common/Button';
import styles from './TimeOutResendButton.scss';

const initialCountdownTimeSeconds = 59;
const intervalMills = 1000;
const secondsStringLength = 2;

let interval: NodeJS.Timeout;
const launchCountDownTimer = (
  setCountdownResetTimer: React.Dispatch<React.SetStateAction<number>>,
  setResetButtonEnabled: (enabled: boolean) => void,
) => {
  interval = setInterval(() => {
    setCountdownResetTimer((value) => {
      if (value <= 1) {
        clearInterval(interval);
        setResetButtonEnabled(true);

        return 0;
      }

      return value - 1;
    });
  }, intervalMills);
};

interface TimeOutResendButtonProps {
  onResendClick: () => Promise<unknown>;
}

const TimeOutResendButton = ({ onResendClick }: TimeOutResendButtonProps) => {
  const [countdownResetTimer, setCountdownResetTimer] = useState(initialCountdownTimeSeconds);
  const [isResetButtonEnabled, setResetButtonEnabled] = useState(false);
  const [isResendButtonLoading, setResendButtonLoading] = useState(false);

  useEffect(() => {
    launchCountDownTimer(setCountdownResetTimer, setResetButtonEnabled);
  }, []);

  const handleResendClick = () => {
    setResendButtonLoading(true);

    onResendClick().finally(() => {
      setResetButtonEnabled(false);
      setCountdownResetTimer(initialCountdownTimeSeconds);
      launchCountDownTimer(setCountdownResetTimer, setResetButtonEnabled);

      setResendButtonLoading(false);
    });
  };

  return (
    <div className={styles.resendEmailBlock}>
      {isResetButtonEnabled ? (
        <Button
          kind="tertiary"
          loading={isResendButtonLoading}
          className={styles.resendEmailButton}
          onClick={handleResendClick}
        >
          <FormattedMessage
            id="authScreen.otcForm.resendEmailButton"
            defaultMessage="Resend email"
            description="Button that resends OTC email"
          />
        </Button>
      ) : (
        <span className={styles.resendEmailCountdown}>
          <FormattedMessage
            id="authScreen.otcForm.resendEmailCountdown"
            defaultMessage="Resend email in"
            description="Countdown timer for the resend email button"
          />{' '}
          {countdownResetTimer > 0 && (
            <b className={styles.resendEmailTimer}>
              0:{countdownResetTimer.toString().padStart(secondsStringLength, '0')}
            </b>
          )}
        </span>
      )}
    </div>
  );
};

export default TimeOutResendButton;
