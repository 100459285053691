import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import EnterLayout from '../components/layout/EnterLayout';
import { logDiiaSuccess } from '../utils/analytics/diiaEvents';
import successHighFiveImage from '../../assets/icons/diia/high-five.svg';
import styles from './DiiaSuccessRoute.scss';

const DiiaSuccessRoute = () => {
  useEffect(() => {
    logDiiaSuccess();
  }, []);

  return (
    <EnterLayout verticalPaddings={[48, 40]}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          <FormattedMessage
            id="profile.diia.modal.successTitle"
            defaultMessage="You passed the 'palianytsia' test!"
            description="Title of the Diia modal, once the citizenship has been verified"
          />
        </h2>
        <span className={styles.subtitle}>
          <FormattedMessage
            id="profile.diia.modal.successSubtitle"
            defaultMessage="Glory to Ukraine! Welcome to the family."
            description="Subtitle of the Diia modal, once the citizenship has been verified"
          />
        </span>

        <img src={successHighFiveImage} width="270" height="240" alt="" />

        <a className={styles.cta} href="/profile">
          <FormattedMessage
            id="profile.diia.modal.successCTA"
            defaultMessage="Continue"
            description="Button of the Diia modal, once the citizenship has been verified, leads to app or refreshes the profile"
          />
        </a>
      </div>
    </EnterLayout>
  );
};

export default DiiaSuccessRoute;
