import React, { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import Footer from '../Footer/Footer';
import LocalizationSelect from '../common/LocalizationSelect';
import Logo from '../../../assets/logo-long.png';
import BackArrow from '../../../assets/icons/back-arrow.svg';
import styles from './EnterLayout.scss';

interface EnterLayoutProps {
  children: ReactNode;
  showTermsAndPolicy?: boolean;
  showBackToProfile?: boolean;
  verticalPaddings?: number | [number, number];
}

/**
 * Layout for the sign up, log in, enter routes. Creates a container, top logo, wrapper for the form and a footer.
 */
const EnterLayout = ({ children, showTermsAndPolicy, showBackToProfile, verticalPaddings = 45 }: EnterLayoutProps) => {
  let paddingTop;
  let paddingBottom;

  if (Array.isArray(verticalPaddings)) {
    [paddingTop, paddingBottom] = verticalPaddings;
  } else {
    paddingTop = verticalPaddings;
    paddingBottom = verticalPaddings;
  }

  return (
    <div className={styles.container}>
      <main>
        <div className={styles.logoLine}>
          <a href="https://clearvpn.com">
            <img src={Logo} alt="ClearVPN" />
          </a>

          <LocalizationSelect />
        </div>

        <div className={styles.innerContainer}>
          <section className={styles.formWrapper} style={{ paddingTop, paddingBottom }}>
            {showBackToProfile ? (
              <a href="/profile" className={styles.backToProfile}>
                <img src={BackArrow} alt="" />
                <FormattedMessage
                  id="layout.backToProfile"
                  defaultMessage="Back to profile"
                  description="Link in the corner of the screen, leading to the main account page"
                />
              </a>
            ) : null}

            {children}
          </section>

          {showTermsAndPolicy ? (
            <p className={styles.privacy}>
              <FormattedMessage
                id="layout.termsPolicyLinks"
                defaultMessage="By signing in, you agree to the <terms>Terms</terms> and <policy>Privacy Policy</policy>"
                description="Link in the corner of the screen, leading to the main account page"
                values={{
                  terms: (chunk) => (
                    <a href="https://macpaw.com/clearvpn-terms-of-service" target="_blank" rel="noreferrer">
                      {chunk}
                    </a>
                  ),
                  policy: (chunk) => (
                    <a href="https://macpaw.com/clearvpn-privacy-policy" target="_blank" rel="noreferrer">
                      {chunk}
                    </a>
                  ),
                }}
              />
            </p>
          ) : null}
        </div>

        <Footer className={styles.footer} />
      </main>
    </div>
  );
};

export default EnterLayout;
