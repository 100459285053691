import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import EnterLayout from '../layout/EnterLayout';
import { getParsedParams } from '../../utils/consts/params';
import { saveFromOrderAuthParams } from '../../utils/authInterceptor';
import { sendPermTokenRequest } from '../../utils/api/accountApi';
import { genericErrorMessage } from '../../utils/lang/commonErrors';
import { errorParams } from '../../utils/consts/errorParams';
import RestoreAccountModal from './RestoreAccountModal';
import AuthFormSwitch from './otc/AuthFormSwitch';
import styles from './EnterForm.scss';

const EnterForm = () => {
  const intl = useIntl();

  const [error, setError] = useState<string | null>(null);

  const [restoreModalOpen, setRestoreModalOpen] = useState(false);

  useEffect(() => {
    const params = getParsedParams();

    saveFromOrderAuthParams();

    if (params.ERROR === errorParams.accountTerminated) {
      setRestoreModalOpen(true);
    } else {
      setError(params.ERROR);
    }

    if (params.PERMANENT_TOKEN) {
      sendPermTokenRequest(params.PERMANENT_TOKEN, { shouldDropToLogin: false }).then(() => {
        window.location.href = '/profile';
      });
    }
  }, []);

  return (
    <EnterLayout showTermsAndPolicy verticalPaddings={45}>
      {error ? (
        <span className={styles.errorInfo}>
          {error === errorParams.tooManyDevices
            ? intl.formatMessage({
                id: 'enter.error.deviceLimitExceeded',
                defaultMessage: 'Devices limit exceeded, please log in to manage your devices',
                description:
                  'Error message show to user with exceeded maximum number of devices; ' +
                  'user should log in to remove unneeded devices from their profile page.',
              })
            : error === errorParams.depreactedEmailVerify
              ? intl.formatMessage(
                  {
                    id: 'enter.error.deprecatedEmailVerify',
                    defaultMessage: 'Email verification is no longer required.{br}Thank you for your diligence!',
                    description: 'Error message shown, when user tries to use an email verification link',
                  },
                  {
                    br: <br />,
                  },
                )
              : intl.formatMessage(genericErrorMessage)}
        </span>
      ) : null}

      <AuthFormSwitch />

      <RestoreAccountModal isOpen={restoreModalOpen} />
    </EnterLayout>
  );
};

export default EnterForm;
