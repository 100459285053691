import { FormattedMessage } from 'react-intl';
import BlockTitle from '../layout/BlockTitle';
import { LinkButton } from '../../common/Button';
import GrayBox from '../layout/GrayBox';
import styles from './ReferralInfoBlock.scss';

const ReferralInfoBlock = () => (
  <div>
    <BlockTitle>
      <FormattedMessage id="profile.referral.info.title" defaultMessage="ClearVPN’s win-win invites" />
    </BlockTitle>

    <GrayBox className={styles.infoBlock}>
      <p className={styles.text}>
        <FormattedMessage
          id="profile.referral.info.text"
          defaultMessage="Inviting a friend will give them a subscription discount and get you up to 90% off or 75 days of free Premium!"
        />
      </p>
      <LinkButton
        href="https://clearvpn.com/refer-a-friend/"
        target="_blank"
        rel="noreferrer"
        kind="primary"
        buttonSize="small"
      >
        <FormattedMessage
          id="profile.referral.info.moreInfoLink"
          defaultMessage="Learn more"
          description="Link to the referral landing"
        />
      </LinkButton>
    </GrayBox>
  </div>
);

export default ReferralInfoBlock;
