import React, { useEffect } from 'react';
import { Route as RouterRoute } from 'react-router-dom';
import t from 'prop-types';

/**
 * Wrapper around the `Route` from `react-router-dom` that also passes the page title for the route.
 * @param props
 * @param {string} props.path - path to the route
 * @param {JSX.Element} props.children
 * @param {string} [props.title] - title of the page
 * @param {boolean} [props.exact]
 * @returns {JSX.Element}
 */
const Route = ({ path, children, title = 'ClearVPN', exact }) => {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <RouterRoute path={path} exact={exact}>
      {children}
    </RouterRoute>
  );
};

Route.propTypes = {
  path: t.string.isRequired,
  children: t.element.isRequired,
  title: t.string,
  exact: t.bool,
};

export default Route;
