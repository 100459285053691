import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { Button } from '../../common/Button';
import BlockTitle from '../layout/BlockTitle';
import GrayBox from '../layout/GrayBox';
import DeleteAccountModal from './components/DeleteAccountModal';
import styles from './DeleteAccountBlock.scss';

const DeleteAccountBlock = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <section>
      <BlockTitle>
        <FormattedMessage id="profile.deleteAccount.title" defaultMessage="Delete account" />
      </BlockTitle>

      <GrayBox className={styles.block}>
        <p className={styles.blockDescription}>
          <FormattedMessage
            id="profile.deleteAccount.text"
            defaultMessage="You won't be able to use our app, but your subscription will remain active. You can restore it within 30 days if you decide to come back"
          />
        </p>

        <Button
          kind="secondary"
          buttonSize="small"
          type="button"
          className={styles.deleteButton}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <FormattedMessage id="profile.manageAccount.deleteAccountButton" defaultMessage="Delete" />
        </Button>
      </GrayBox>

      <DeleteAccountModal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </section>
  );
};

export default DeleteAccountBlock;
