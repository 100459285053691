import { useLocation } from 'react-router-dom';
import 'polyfill-object.fromentries';

/**
 * React hook for parsing query params from current URL.
 * @returns {Object} query object
 */
const useQuery = () => {
  const { search } = useLocation();

  return Object.fromEntries(new URLSearchParams(search).entries());
};

export default useQuery;
