import { clearApiClient } from './clearApiClient';

interface ESputnikEvent {
  event_type: string;
  event_params: Array<{ name: string; value?: string }>;
  email?: string;
}

export const sendESputnikTrackingEventRequest = (event: ESputnikEvent) =>
  clearApiClient.post('/web/v1/marketing/emails/events/register', event);

export const sendDownloadAppInstallerRequest = (appIdentity: string, analyticsParams?: string) =>
  clearApiClient.post<{ download_url: string }>('/web/v1/marketing/download-app-installer', {
    app_identity: appIdentity,
    analytics_data: analyticsParams,
  });
