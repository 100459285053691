const createTimer = (duration, onTick, onTimeOut) => {
  const startTime = Date.now();

  const interval = setInterval(() => {
    const currentTime = Date.now();

    if (startTime + duration * 1e3 <= currentTime) {
      onTimeOut();
      clearInterval(interval);

      return;
    }

    const secondsElapsed = Math.floor((currentTime - startTime) / 1e3);

    onTick(duration - secondsElapsed);
  }, 1000);

  return { clearTimer: () => clearInterval(interval) };
};

export { createTimer };
