import { forwardRef, useState } from 'react';
import clsx from 'clsx';
import styles from './OTCInput.scss';

const codeLength = 6;

interface OTCInputProps {
  disabled: boolean;
  onCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const OTCInput = forwardRef<HTMLInputElement, OTCInputProps>(({ disabled, onCodeChange }, ref) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [focusedCellIndex, setFocusedCellIndex] = useState<number>(0);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);
    onCodeChange(event);
    setFocusedCellIndex(event.target.value.length);
  };

  // input may be focused from parent via `ref`
  const onFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
    if (event.target.value !== inputValue) {
      setInputValue(event.target.value);
      setFocusedCellIndex(event.target.value.length);
    }
  };

  return (
    <label className={styles.wrapper}>
      <input
        type="text"
        autoComplete="one-time-code"
        inputMode="numeric"
        minLength={codeLength}
        maxLength={codeLength}
        pattern="\d{6,6}"
        className={styles.input}
        disabled={disabled}
        ref={ref}
        onChange={handleChange}
        onFocus={onFocus}
      />

      {Array.from({ length: codeLength }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key -- it's just the index here =(
        <span key={index} className={clsx(styles.cell, focusedCellIndex === index && styles.cellCurrent)}>
          {inputValue.charAt(index)}
        </span>
      ))}
    </label>
  );
});

OTCInput.displayName = 'OTCInput';

export default OTCInput;
