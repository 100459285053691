import { useHistory } from 'react-router-dom';

const DEFAULT_REDIRECT = '/profile';
const LOCALSTORAGE_KEY = 'redirect_after_login_url';

/**
 * `useState` wrapper that holds the URL to redirect to after login
 * @returns {{updateRedirectUrl: string, resolveRedirectInProfile: function}}
 */
const useRedirectUrl = () => {
  const history = useHistory();

  const getRedirectUrl = () => localStorage.getItem(LOCALSTORAGE_KEY);

  const updateRedirectUrl = (path) => {
    const redirectUrl = getRedirectUrl();

    console.log('Hook`s saved redirect:', redirectUrl);
    console.log('Hook`s param redirect:', path);
    if (redirectUrl !== DEFAULT_REDIRECT && path.startsWith(DEFAULT_REDIRECT)) {
      return;
    }
    console.log('Setting redirect URL to:', path);
    localStorage.setItem(LOCALSTORAGE_KEY, path);
  };

  const clearRedirectUrl = () => localStorage.removeItem(LOCALSTORAGE_KEY);

  const resolveRedirectInProfile = () => {
    const redirectUrl = getRedirectUrl();

    clearRedirectUrl();

    console.log('redirectUrl in /profile', redirectUrl);
    if (redirectUrl) {
      console.log('Redirecting from /profile');
      history.replace(redirectUrl);
    }
  };

  return {
    resolveRedirectInProfile,
    updateRedirectUrl,
  };
};

export { useRedirectUrl };
