import Cookies from 'js-cookie';

const OTC_COOKIE_KEY = 'otc-email';

/**
 * Saves the email address to cookies,
 * hoping that the user will open OTC email link in the same browser,
 * so we can continue the purchase flow, without asking for plan selection and the email twice.
 */
const saveOTCEmail = (email: string) => {
  Cookies.set(OTC_COOKIE_KEY, email);
};

const getOTCEmail = () => Cookies.get(OTC_COOKIE_KEY);

const clearOTCEmail = () => {
  Cookies.remove(OTC_COOKIE_KEY);
};

export { saveOTCEmail, getOTCEmail, clearOTCEmail };
