import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { sendCancelAccountDeletionRequest } from '../../utils/api/accountApi';
import { genericErrorMessage } from '../../utils/lang/commonErrors';
import { lockBackgroundScroll, unlockBackgroundScroll } from '../../utils/lockBackgroundScroll';
import { Button } from '../common/Button';
import Modal from '../common/Modal';
import fixelWatImage from '../../../assets/icons/fixel-wat.svg';
import styles from '../Profile/accountDetails/components/DeleteAccountModal.scss';

interface RestoreAccountModalProps {
  isOpen: boolean;
}

const RestoreAccountModal = ({ isOpen }: RestoreAccountModalProps) => {
  const intl = useIntl();

  const [isLoading, setLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    if (!isLoading) {
      window.location.href = '/enter';
    }
  };

  const onConfirmClick = () => {
    setLoading(true);
    setError(null);

    sendCancelAccountDeletionRequest()
      .then(
        () => {
          window.location.href = '/profile';
        },
        () => {
          setError(intl.formatMessage(genericErrorMessage));
        },
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      lockBackgroundScroll();
    } else {
      unlockBackgroundScroll();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} className={`${styles.dialog} ${error ? styles.dialogWithError : ''}`} onClose={() => {}}>
      <img src={fixelWatImage} width="162" height="154" alt="" className={styles.hooverImg} />

      <h3 className={styles.title}>
        <FormattedMessage id="profile.restoreAccountModal.title" defaultMessage="Wait a minute..." />
      </h3>

      <p className={styles.description}>
        <FormattedMessage
          id="profile.restoreAccountModal.description"
          defaultMessage="Looks like you already have an account, but it was deleted. To restore it at once, just click the button below or use other credentials instead"
        />
      </p>

      <div className={styles.buttonsLine}>
        <Button className={styles.goBackButton} kind="secondary" disabled={isLoading} onClick={handleClose}>
          <FormattedMessage
            id="profile.restoreAccountModal.tryOtherCredentialsButton"
            defaultMessage="Try other credentials"
          />
        </Button>

        <Button className={styles.restoreButton} loading={isLoading} disabled={isLoading} onClick={onConfirmClick}>
          <FormattedMessage id="profile.restoreAccountModal.restoreAccountButton" defaultMessage="Restore account" />
        </Button>
      </div>

      {error ? <span className={styles.error}>{error}</span> : null}
    </Modal>
  );
};

export default RestoreAccountModal;
