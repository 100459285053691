import React from 'react';
import webStoreLogo from '../../../assets/icons/extension/web-store-badge.png';
import styles from './WebStoreButton.scss';

const WebStoreButton = () => (
  <a
    href="https://chromewebstore.google.com/detail/clearweb/pcjcjlcjmooighgkjafnogbidgmdhbdi"
    target="_blank"
    rel="noreferrer"
    className={styles.link}
  >
    <img src={webStoreLogo} width={248} height={75} alt="Available in the Chrome Web Store" />
  </a>
);

export default WebStoreButton;
