import { sanitizeUrl as brainTreeSanitizeUrl } from '@braintree/sanitize-url';

const sanitizeUrl = (url) => {
  // the method from the package returns `about:blank` if URL is not clear
  const sanitizedUrl = brainTreeSanitizeUrl(url);

  return sanitizedUrl === 'about:blank' ? '' : sanitizedUrl;
};

export { sanitizeUrl };
