import { FormattedMessage, useIntl } from 'react-intl';
import { type UserProfile } from '../../../utils/api/schema/profileSchema';
import BlockTitle from '../layout/BlockTitle';
import copyIcon from '../../../../assets/icons/copy-icon.svg';
import facebookIcon from '../../../../assets/icons/oauth/facebook-oauth-icon.svg';
import twitterIcon from '../../../../assets/icons/referral/twitter-icon.svg';
import plate0Img from '../../../../assets/icons/referral/achievement/plate-0.svg';
import plate1Img from '../../../../assets/icons/referral/achievement/plate-1.svg';
import plate2Img from '../../../../assets/icons/referral/achievement/plate-2.svg';
import plate3Img from '../../../../assets/icons/referral/achievement/plate-3.svg';
import plate4Img from '../../../../assets/icons/referral/achievement/plate-4.svg';
import styles from './ReferralLinkBlock.scss';

const achievementPlates = [plate0Img, plate1Img, plate2Img, plate3Img, plate4Img];

const ReferralLinkBlock = ({ profile }: { profile: UserProfile }) => {
  const inviteUrl = profile.referral.referral_url;
  const encodedInviteUrl = encodeURIComponent(inviteUrl);

  const isAffiliateBanner =
    profile.state === 'active' && profile.referral.referrals_count >= profile.referral.referrals_limit;
  const isUpgradeBanner =
    profile.state === 'new_account' && profile.referral.referrals_count >= profile.referral.referrals_limit;
  const isAchievementPlates = !(isAffiliateBanner || isUpgradeBanner);

  const onCopyButtonClick = () => {
    const clipboardPromise = navigator.permissions.query({ name: 'clipboard-write' as PermissionName });

    if (typeof clipboardPromise.then === 'function') {
      clipboardPromise
        .then((result) => {
          if (result.state === 'granted') {
            navigator.clipboard.writeText(inviteUrl);
          }
        })
        .catch((error: unknown) => {
          console.log('Getting clipboard permission failed:', error);
          navigator.clipboard.writeText(inviteUrl);
        });
    }
  };

  const intl = useIntl();

  return (
    <div className={styles.block}>
      <BlockTitle>
        <FormattedMessage id="profile.referral.shareLinkLabel" defaultMessage="Share the referral link" />
      </BlockTitle>

      <div className={styles.shareLine}>
        <div className={styles.linkField}>
          <span>{inviteUrl}</span>
          <button
            id="profile-copy-referral-code-button"
            title={intl.formatMessage({
              id: 'profile.referral.copyReferralLinkButton',
              defaultMessage: 'Copy your referral link to clipboard',
              description: 'Hover tooltip of the button that copies user’s referral link to the clipboard',
            })}
            onClick={onCopyButtonClick}
          >
            <img src={copyIcon} width="16" height="16" id="profile-copy-referral-code-button-img" alt="" />
          </button>
        </div>

        <span className={styles.orSpan}>
          <FormattedMessage
            id="profile.referral.orLabel"
            defaultMessage="or"
            description="Label separating copying link block with share on socials"
          />
        </span>

        <div className={styles.shareButtons}>
          <a
            href={`https://twitter.com/intent/tweet?url=Check%20out%20ClearVPN%21%20A%20beautifully%20designed%20and%20powerful%20VPN%20app%20you%27d%20love%20to%20use.%20Get%20it%20now%20with%20a%2020%25%20discount%3A%20${encodedInviteUrl}`}
            target="_blank"
            rel="noreferrer"
            title={intl.formatMessage({
              id: 'profile.referral.shareOnTwitter',
              defaultMessage: 'Share your referral link on Twitter',
              description: 'Hover tooltip of the "share on Twitter" link',
            })}
          >
            <img src={twitterIcon} width="18" height="18" alt="" />
          </a>

          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodedInviteUrl}`}
            target="_blank"
            rel="noreferrer"
            title={intl.formatMessage({
              id: 'profile.referral.shareOnFacebook',
              defaultMessage: 'Share your referral link on Facebook',
              description: 'Hover tooltip of the "share on Facebook" link',
            })}
          >
            <img src={facebookIcon} width="18" height="18" alt="" />
          </a>
        </div>
      </div>

      {isAffiliateBanner ? (
        <div className={styles.referralStatusCta}>
          <span>
            <FormattedMessage
              id="profile.referral.goAffiliate.text"
              defaultMessage="You’ve used up all 5 invites. To invite more people, go affiliate."
              description="Banner shown to the user with maximum number of invites, promotes affiliate"
            />
          </span>
          <a href="https://clearvpn.com/affiliates/" target="_blank" rel="noreferrer">
            <FormattedMessage
              id="profile.referral.goAffiliate.link"
              defaultMessage="Tell me more"
              description="Banner shown to the user with maximum number of invites, links to affiliate landing page"
            />
          </a>
        </div>
      ) : null}

      {isUpgradeBanner ? (
        <div className={styles.referralStatusCta}>
          <span>
            <FormattedMessage
              id="profile.referral.maxDiscount.text"
              defaultMessage="Congratulations! You have invited 5 friends and get the maximum discount!"
              description="Banner shown to the user who got maximum discount from referral program"
            />
          </span>
          <a href={profile.referral.referral_url}>
            <FormattedMessage
              id="profile.referral.maxDiscount.link"
              defaultMessage="Upgrade"
              description="Banner shown to the user who got maximum discount from referral program, links to order page"
            />
          </a>
        </div>
      ) : null}

      {isAchievementPlates ? (
        <div className={styles.achievementRow}>
          {Array.from({ length: profile.referral.referrals_limit }, (v, i) => i).map((value) => (
            <div className={profile.referral.referrals_count > value ? styles.achieved : ''} key={value}>
              <img src={achievementPlates[value]} width="20" height="20" alt="" />
            </div>
          ))}

          {profile.referral.referrals_count < profile.referral.referrals_limit && (
            <span className={styles.inviteMoreLabel}>
              <FormattedMessage
                id="profile.referral.inviteMoreLabel"
                values={{ invites: profile.referral.referrals_limit - profile.referral.referrals_count }}
                defaultMessage="{invites, plural, =1 {One more invite available} other {# more invites available}}"
              />
            </span>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ReferralLinkBlock;
