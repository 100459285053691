const logDiiaQRShown = () => {
  window?.dataLayer?.push({
    event: 'diia_qr_code_shown',
    'clear-eventCategory': 'profile',
    'clear-eventAction': 'diia_qr_code_shown',
  });
};

const logDiiaSuccess = () => {
  window?.dataLayer?.push({
    event: 'diia_signin_completed',
    'clear-eventCategory': 'profile',
    'clear-eventAction': 'diia_signin_completed',
  });
};

export { logDiiaQRShown, logDiiaSuccess };
