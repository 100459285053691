import React, { useRef, useEffect, type ReactNode } from 'react';
import { isAndroid } from '../../utils/platformResolver';
import Spacer from './Spacer';
import successIcon from '../../../assets/icons/success.svg';
import errorIcon from '../../../assets/icons/error.svg';
import styles from './Alert.scss';

const matchIcon = (icon: string) => {
  switch (icon) {
    case 'success': {
      return successIcon;
    }

    case 'error': {
      return errorIcon;
    }

    default: {
      return '';
    }
  }
};

interface AlertLayoutProps {
  title: string;
  description?: string;
  actionTitle?: string;
  footerChild?: ReactNode;
  href?: string;
  onClick?: () => void;
  icon?: 'success' | 'error';
  autoClickAction?: boolean;
  customIcon?: ReactNode | null;
}

const Alert = ({
  title,
  description,
  actionTitle,
  footerChild = null,
  href,
  onClick,
  icon,
  autoClickAction,
  customIcon = null,
}: AlertLayoutProps) => {
  const actionRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (autoClickAction && !isAndroid()) {
      // attempt at fixing the https://jira.macpaw.com/browse/CVPN-759
      actionRef.current?.click();
    }
  }, []);

  return (
    <div className={styles.box}>
      <div>
        {icon ? <img src={matchIcon(icon)} className={styles.icon} alt={`${icon} icon`} /> : null}
        {customIcon}

        {title ? <h2 className={styles.title}>{title}</h2> : null}
      </div>

      {description ? (
        <>
          <Spacer height={10} />
          <p className={`${styles.description} ${description.length > 50 ? styles.descriptionLong : ''}`}>
            {description}
          </p>
        </>
      ) : null}

      {actionTitle ? (
        <>
          {description && description.length > 50 ? <Spacer height={30} /> : <Spacer height={50} />}
          <a href={href} className={styles.link} ref={actionRef} onClick={onClick}>
            {actionTitle}
          </a>
        </>
      ) : null}

      {footerChild ? (
        <>
          <Spacer height={10} />
          <span className={styles.footerAction}>{footerChild}</span>
        </>
      ) : null}
    </div>
  );
};

export default Alert;
