import { FormattedMessage } from 'react-intl';
import Modal from '../../../components/common/Modal';
import { Button } from '../../../components/common/Button';
import hooverNoImage from '../../../../assets/icons/account-termination/hoover-no.svg';
import styles from './ChangeEmailFailedModal.scss';

interface SameEmailErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SameEmailErrorModal = ({ isOpen, onClose }: SameEmailErrorModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <img src={hooverNoImage} width="196" height="147" alt="" />

      <h3 className={styles.title}>
        <FormattedMessage id="changeEmail.sameEmailErrorModal.title" defaultMessage="You are already logged in" />
      </h3>

      <p className={styles.text}>
        <FormattedMessage
          id="changeEmail.sameEmailErrorModal.text"
          defaultMessage="If you want to sign in into a different account, please sign out first"
        />
      </p>

      <Button className={styles.confirmButton} onClick={onClose}>
        <FormattedMessage id="changeEmail.sameEmailErrorModal.cancelButton" defaultMessage="OK" />
      </Button>
    </Modal>
  );
};

export default SameEmailErrorModal;
