/**
 * Validates string for being within the given length boundaries
 * @param {string} value
 * @param {number} [minLength]
 * @param {number} [maxLength]
 * @returns {?string} return string with the error or `undefined`
 */
const validateLength = (value, minLength, maxLength) => {
  if (value.length === 0) {
    return 'This field is required';
  }

  if (minLength && value.length < minLength) {
    return `Must be at least ${minLength} characters long`;
  }

  if (maxLength && value.length > maxLength) {
    return `Should not exceed ${maxLength} characters`;
  }

  return '';
};

export { validateLength };
