import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import EnterLayout from '../../components/layout/EnterLayout';
import Alert from '../../components/common/Alert';
import useQuery from '../../utils/useQuery';
import { sendPermTokenRequest, sendUpdateSettingsRequest } from '../../utils/api/accountApi';

const SubscriptionConfirmedRoute = () => {
  const { permanent_access_token: permanentToken } = useQuery();

  useEffect(() => {
    // login user and set his newsletter option to `true`, so it can be unchecked in profile
    sendPermTokenRequest(permanentToken).then(() => sendUpdateSettingsRequest());
  }, []);

  const intl = useIntl();

  return (
    <EnterLayout verticalPaddings={[70, 80]}>
      <Alert
        icon="success"
        title={intl.formatMessage({
          id: 'newsletterSubConfirmed.title',
          defaultMessage: 'Thank you!',
          description: 'Title of the page',
        })}
        description={intl.formatMessage({
          id: 'newsletterSubConfirmed.text',
          defaultMessage: 'Subscription successfully confirmed',
          description: 'Main message of the page',
        })}
      />
    </EnterLayout>
  );
};

export default SubscriptionConfirmedRoute;
