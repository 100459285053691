const rootElementOptions = {
  fonts: [
    {
      cssSrc: 'https://static.provpn.world/assguard/fonts/MarkPro-100-900.css',
    },
  ],
};

const desktopFieldStyles = {
  base: {
    fontFamily: "'Mark Pro', sans-serif",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    color: '#212231',
    '::placeholder': {
      color: 'rgba(33, 34, 49, 0.32)',
    },
  },
  invalid: {
    color: '#181F26',
  },
};

export { rootElementOptions, desktopFieldStyles };
