import React from 'react';
import { useRouteMatch, Switch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Route from '../common/Route';
import RedirectToApp from './RedirectToApp';
import AppErrorRoute from './Error';
import ChromeAdblockRoute from './ChromeAdblock';
import OneTimeCodeRedirect from './OneTimeCodeRedirect';

const RedirectRoutes = () => {
  const { path } = useRouteMatch();
  const intl = useIntl();

  return (
    <Switch>
      {/* this one builds App link from query params */}
      <Route
        exact
        path={`${path}/redirect-to-app`}
        title={intl.formatMessage({
          id: 'toApp.seo.title',
          defaultMessage: 'ClearVPN | Open App',
          description: 'SEO title of the page that opens ClearVPN app on user’s device',
        })}
      >
        <RedirectToApp
          title={intl.formatMessage({
            id: 'redirectToApp.title',
            defaultMessage: 'You’re successfully logged in!',
            description:
              'Title of the page, that brings the user back into App, after completing login flow in browser',
          })}
        />
      </Route>

      {/* and this one has a static clearvpn:// */}
      <Route
        exact
        path={`${path}/to-app`}
        title={intl.formatMessage({
          id: 'toApp.seo.title',
          defaultMessage: 'ClearVPN | Open App',
          description: 'SEO title of the page that opens ClearVPN app on user’s device',
        })}
      >
        <RedirectToApp
          title={intl.formatMessage({
            id: 'toApp.title',
            defaultMessage: 'Open ClearVPN app',
            description: 'Title of the page, that opens up a predefined screen of the app',
          })}
        />
      </Route>

      <Route
        exact
        path={`${path}/to-chrome`}
        title={intl.formatMessage({
          id: 'toChromeExtension.seo.title',
          defaultMessage: 'ClearVPN | Open Chrome Extension',
        })}
      >
        <ChromeAdblockRoute />
      </Route>

      <Route
        exact
        path={`${path}/otc`}
        title={intl.formatMessage({
          id: 'otcProxyPage.seo.title',
          defaultMessage: 'ClearVPN | One time code',
          description: 'SEO title of the page that opens ClearVPN app on user’s device',
        })}
      >
        <OneTimeCodeRedirect />
      </Route>

      <Route
        exact
        path={`${path}/error`}
        title={intl.formatMessage({
          id: 'appError.seo.title',
          defaultMessage: 'ClearVPN | Something is wrong',
        })}
      >
        <AppErrorRoute />
      </Route>
    </Switch>
  );
};

export default RedirectRoutes;
