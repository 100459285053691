import React from 'react';
import { useIntl } from 'react-intl';
import FeedbackLayout from '../../components/Enter/FeedbackLayout';
import AccountCodeForm from '../../components/Enter/AccountCodeForm';

const AccountCodeEnterRoute = () => {
  const intl = useIntl();

  return (
    <FeedbackLayout
      copy={{
        title: intl.formatMessage({
          id: 'refSignUp.title',
          defaultMessage: 'Come on in!',
          description: 'Title of sign up page with referral code',
        }),
        text: intl.formatMessage({
          id: 'refSignUp.subtitle',
          defaultMessage: 'We’re so glad someone invited you to ClearVPN 😊',
          description: 'Subtitle of sign up page with referral code',
        }),
      }}
    >
      <AccountCodeForm />
    </FeedbackLayout>
  );
};

export default AccountCodeEnterRoute;
