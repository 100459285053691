import React from 'react';
import t from 'prop-types';
import styles from './TabsSelector.scss';

/**
 * Selector for shortcut categories. Will be a row of buttons on desktop and a dropdown on mobile.
 * @param {Object} props
 * @param {string[]} props.categories
 * @param {string} props.currentCategory
 * @param {function} props.onCategorySelected
 * @param {JSX.Element[]} [props.children] - React nodes to be rendered as category labels
 * @param {boolean} [props.disabled]
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
const TabsSelector = ({ categories, currentCategory, onCategorySelected, children, disabled, className }) => {
  const resolveButtonStyle = (category) =>
    category === currentCategory ? `${styles.item} ${styles.selected}` : styles.item;

  const onCategoryClick = (category) => {
    if (category !== currentCategory && !disabled) {
      onCategorySelected(category);
    }
  };

  return (
    <div className={className}>
      <div className={styles.categorySelector}>
        {categories.map((category, index) => (
          <button
            className={resolveButtonStyle(category)}
            key={category}
            id={`payment-method-tab-${index}`}
            onClick={() => onCategoryClick(category)}
          >
            {children?.[index] || category}
          </button>
        ))}
      </div>
    </div>
  );
};

TabsSelector.propTypes = {
  categories: t.arrayOf(t.string).isRequired,
  currentCategory: t.string.isRequired,
  onCategorySelected: t.func.isRequired,
  children: t.arrayOf(t.node),
  disabled: t.bool,
  className: t.string,
};

export default TabsSelector;
