import { defineMessage } from 'react-intl';

const facts = [
  defineMessage({
    id: 'loadingFact.1',
    defaultMessage: 'In the meantime, did you know, a single elephant tooth can weigh as much as 9 pounds?',
    description: 'A random fact shown to user during long loading',
  }),
  defineMessage({
    id: 'loadingFact.2',
    defaultMessage: 'In the meantime, did you know, the Sun’s mass takes up 99.86% of the solar system?',
    description: 'A random fact shown to user during long loading',
  }),
  defineMessage({
    id: 'loadingFact.3',
    defaultMessage: 'In the meantime, did you know, there is a volcano on Mars three times the size of Everest?',
    description: 'A random fact shown to user during long loading',
  }),
  defineMessage({
    id: 'loadingFact.4',
    defaultMessage:
      'In the meantime, did you know it is possible to hypnotize a frog by placing it on its back and gently stroking its stomach? A fun fact.',
    description: 'A random fact shown to user during long loading',
  }),
];

export const getRandomLoadingFact = (intl) => intl.formatMessage(facts[Math.floor(Math.random() * facts.length)]);
