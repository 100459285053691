import { FormattedMessage } from 'react-intl';
import SectionTitle from '../layout/SectionTitle';
import { type UserProfile } from '../../../utils/api/schema/profileSchema';
import DeviceManagementBlock from './DeviceManagementBlock';
import AutoLoginBlock from './AutoLoginBlock';
import styles from './DeviceManagementSection.scss';

interface DeviceManagementSectionProps {
  profile: UserProfile;
  onAddNewDeviceClick: () => void;
}

const DeviceManagementSection = ({ profile, onAddNewDeviceClick }: DeviceManagementSectionProps) => {
  const { devices } = profile;

  const maxDevicesPerAccount = profile.type === 'setapp' ? 8 : 6;
  const isSetappSubscription = profile.type === 'setapp';

  return (
    <>
      <SectionTitle className={styles.sectionTitle}>
        <FormattedMessage id="profile.deviceManagement.title" defaultMessage="Devices management" />
        <span className={styles.deviceCounter}>
          <FormattedMessage
            id="profile.deviceManagement.connectedDevicesCounter"
            defaultMessage="(Connected {devices, number} out of {maxDevices, number})"
            values={{ devices: devices.length, maxDevices: maxDevicesPerAccount }}
          />
        </span>
      </SectionTitle>
      <DeviceManagementBlock
        isSetappSubscription={isSetappSubscription}
        devices={devices}
        onAddNewDeviceClick={onAddNewDeviceClick}
      />
      <AutoLoginBlock isSetappSubscription={isSetappSubscription} />
    </>
  );
};

export default DeviceManagementSection;
