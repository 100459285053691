import React, { useState, useEffect, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { constructSniperLink } from '../../../utils/emailSniperLinks';
import { isApiError } from '../../../utils/api/clearApiClient';
import { sendOneTimeCodeVerifyRequest } from '../../../utils/api/accountApi';
import { httpResponseCodes } from '../../../utils/consts/apiCodes';
import { Button } from '../../common/Button';
import { getParsedParams } from '../../../utils/consts/params';
import { logOTCEmailResend } from '../../../utils/analytics/authEvents';
import ReCaptchaDisclaimer from '../fields/reCaptchaDisclaimer';
import { genericErrorMessage } from '../../../utils/lang/commonErrors';
import InputError from './components/InputError';
import BackButton from './components/BackButton';
import OTCInput from './components/OTCInput';
import TimeOutResendButton from './components/TimeOutResendButton';
import styles from './AuthForm.scss';

interface OneTimeCodeFormProps {
  email: string;
  sendOTCRequest: (emailAdress: string) => Promise<unknown>;
  onBackButtonClick: () => void;
  onAuthSuccess: () => void;
}

const OneTimeCodeForm = ({ email, sendOTCRequest, onBackButtonClick, onAuthSuccess }: OneTimeCodeFormProps) => {
  const intl = useIntl();

  const codeInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const sniperLink = constructSniperLink(email);

  const [error, setError] = useState<string>('');
  const [isSubmitting, setSubmitting] = useState(false);

  const onBackspacePress: React.KeyboardEventHandler<HTMLButtonElement> = (event) => {
    if (event.key === 'Backspace') {
      if (codeInputRef.current?.value.length && codeInputRef.current.value.length > 0) {
        codeInputRef.current.value = codeInputRef.current.value.slice(
          0,
          Math.max(0, codeInputRef.current.value.length - 1),
        );
      }
      codeInputRef.current?.focus();
    }
  };

  const onSubmit = (e?: React.SyntheticEvent) => {
    e?.preventDefault();

    const code = codeInputRef.current?.value;
    const isValid = code && codeInputRef.current.validity.valid;

    if (isValid) {
      setSubmitting(true);
      setError('');
      sendOneTimeCodeVerifyRequest(email, code)
        .then(onAuthSuccess)
        .catch((apiError: unknown) => {
          console.log(apiError);
          if (isApiError(apiError)) {
            if (
              apiError.response?.status === httpResponseCodes.BAD_REQUEST &&
              apiError.response.data.message === 'AccountDeleted'
            ) {
              /*
               this includes apiError.response?.data.message === 'AccountDeleted' condition
               this status will only be temporal, untill the job on the backend deletes the account properly
              */
              setError(intl.formatMessage(genericErrorMessage));
            } else if (apiError.response?.status === httpResponseCodes.BAD_REQUEST) {
              setError(intl.formatMessage({ id: 'authScreen.otcForm.errors.wrongCode', defaultMessage: 'Wrong code' }));
            } else {
              setError(intl.formatMessage(genericErrorMessage));
            }
          } else {
            setError(intl.formatMessage(genericErrorMessage));
          }
          setSubmitting(false);
        });
    }
  };

  const onCodeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.validity.valid) {
      submitButtonRef.current?.focus();
      onSubmit();
    }
  };

  const onResendEmailClick = () => {
    logOTCEmailResend();

    return sendOTCRequest(email);
  };

  useEffect(() => {
    codeInputRef.current?.focus();

    const params = getParsedParams();

    if (params.OTC_CODE && codeInputRef.current) {
      codeInputRef.current.value = params.OTC_CODE;
      onSubmit();
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={onSubmit}>
        <BackButton onClick={onBackButtonClick} />
        <h3 className={styles.title}>
          <FormattedMessage id="authScreen.otcForm.title" defaultMessage="Enter the verification code" />
        </h3>
        <span className={styles.subtitle}>
          <FormattedMessage
            id="authScreen.otcForm.subtitle"
            defaultMessage="Check your inbox and sign in with the magic link or paste the activation 6-digit activation code below"
          />
          <br />
          <b>{email}</b>
          {sniperLink ? (
            <a className={styles.subtitleButton} href={sniperLink}>
              <FormattedMessage
                id="authScreen.otcForm.sniperLink"
                defaultMessage="Open inbox"
                description="Link that will magically open user's email client with our email highlighted"
              />
            </a>
          ) : null}
        </span>

        <OTCInput disabled={isSubmitting} ref={codeInputRef} onCodeChange={onCodeChange} />
        {error ? <InputError error={error} /> : null}

        <Button type="submit" kind="primary" ref={submitButtonRef} loading={isSubmitting} onKeyUp={onBackspacePress}>
          <FormattedMessage
            id="authScreen.otcForm.submitCode"
            defaultMessage="Apply the code"
            description="Button that submits the OTC code"
          />
        </Button>

        <TimeOutResendButton onResendClick={onResendEmailClick} />
      </form>

      <ReCaptchaDisclaimer />
    </div>
  );
};

export default OneTimeCodeForm;
