import { type ClearApiRequestMeta } from '../../types/axios';
import { clearApiClient } from './clearApiClient';
import { type ProfileAPIResponse, profileReponseSchema } from './schema/profileSchema';
import { logSchemaMismatchError } from './schema/schemaUtils';

export const sendProfileRequest = (includePaymentData = false, requestMeta?: ClearApiRequestMeta) =>
  clearApiClient
    .get<ProfileAPIResponse>(
      `/web/v1/profile${includePaymentData ? '?include_payment_data=true' : ''}`,
      requestMeta && { meta: requestMeta },
    )
    .then((response) => {
      const parseResult = profileReponseSchema.safeParse(response.data);

      if (!parseResult.success) {
        logSchemaMismatchError(parseResult.error, 'Profile schema mismatch');

        throw parseResult.error;
      }

      return parseResult.data;
    });
