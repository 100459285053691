import { type ReactNode } from 'react';
import clsx from 'clsx';
import styles from './SectionTitle.scss';

interface SectionTitle {
  children: ReactNode;
  className?: string;
}

const SectionTitle = ({ children, className }: SectionTitle) => (
  <h1 className={clsx(styles.title, className)}>{children}</h1>
);

export default SectionTitle;
