import { useEffect, useState, type ReactNode } from 'react';
import Loader from '../../../common/Loader';
import { useDownloadLinks } from '../../DownloadLinksContext';
import { browserDeeplink } from '../../../../../lib/browser-deeplink';
import { isMacOS, isMobile, isWindows } from '../../../../utils/platformResolver';
import { logDownloadStarted } from '../../../../utils/analytics/downloadEvents';
import { LinkButton, type LinkButtonProps } from '../../../common/Button';

interface LinkCurrentDeviceButtonProps {
  children: ReactNode;
  refreshDownloadToken: () => Promise<void>;
  downloadToken?: string;
  kind?: LinkButtonProps['kind'];
  className?: string;
}

const LinkCurrentDeviceButton = ({
  children,
  downloadToken,
  refreshDownloadToken,
  kind = 'primary',
  className = '',
}: LinkCurrentDeviceButtonProps) => {
  const [appLink, setAppLink] = useState('/');
  const [isMobileLink] = useState(isMobile());

  const { links: downloadLinks } = useDownloadLinks();

  useEffect(() => {
    if (isMobile()) {
      setAppLink(downloadLinks.ios);
    } else {
      setAppLink(`clearvpn://go/register?permanent_token=${downloadToken}`);
    }
  }, [downloadToken, downloadLinks]);
  const onClick = () => {
    if (!appLink) {
      return;
    }
    if (isMobileLink) {
      refreshDownloadToken();

      return;
    }

    browserDeeplink(appLink)
      .catch(() => {
        if (isMacOS()) {
          logDownloadStarted('macos');
          location.href = downloadLinks.macos;
        } else if (isWindows()) {
          logDownloadStarted('windows');
          location.href = downloadLinks.windows;
        }
      })
      .then(refreshDownloadToken);
  };

  return (
    <LinkButton
      href={isMobileLink ? appLink : undefined}
      className={className}
      buttonSize="small"
      kind={kind}
      onClick={onClick}
    >
      {appLink ? children : <Loader />}
    </LinkButton>
  );
};

export default LinkCurrentDeviceButton;
