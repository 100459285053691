import clsx from 'clsx';
import { useEffect } from 'react';
import { lockBackgroundScroll, unlockBackgroundScroll } from '../../utils/lockBackgroundScroll';
import styles from './Modal.scss';

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

const Modal = ({ isOpen, children, onClose, className = '' }: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      lockBackgroundScroll();
    } else {
      unlockBackgroundScroll();
    }
  }, [isOpen]);

  return isOpen ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- modal overlay instinctive click
    <div className={styles.overlay} onClick={onClose}>
      <div className={clsx(styles.modal, className)}>{children}</div>
    </div>
  ) : null;
};

export default Modal;
