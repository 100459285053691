/**
 * https://stackoverflow.com/a/13348618/5562460 :guard:
 */

const isChromium = window.chrome;
const winNav = window.navigator;
const vendorName = winNav.vendor;
const isOpera = window.opr !== undefined;
const isIEedge = winNav.userAgent.includes('Edg');
const isIOSChrome = /CriOS/.exec(winNav.userAgent);

const isChrome = () =>
  isIOSChrome ||
  (isChromium !== null && isChromium !== undefined && vendorName === 'Google Inc.' && !isOpera && !isIEedge);

export { isChrome };
