import Loader from '../../common/Loader';
import styles from './Checkbox.scss';

interface CheckboxProps {
  onChange: (value: boolean) => void;
  label: string;
  value: boolean;
  isLoading?: boolean;
  isNoError?: boolean;
  className?: string;
  error?: string;
}

const Checkbox = ({ onChange, label, error, value, isLoading, isNoError, className = '' }: CheckboxProps) => {
  const handleChange = () => {
    onChange(!value);
  };

  return (
    <div className={`${styles.container} ${className}`}>
      {isLoading ? (
        <Loader colored size={14} />
      ) : (
        <input type="checkbox" id="checkbox" checked={value} className={styles.input} onChange={handleChange} />
      )}

      <label htmlFor="checkbox" className={`${styles.label} ${isLoading ? styles.noCheckbox : ''}`}>
        {label}
      </label>

      {!isNoError && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default Checkbox;
