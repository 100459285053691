import React, { useState, useEffect, useContext, type ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import Cookies from 'js-cookie';
import { cookieDomain } from '../../utils/auth';

const availableLocalizations = {
  UKRAINIAN: 'uk',
  ENGLISH: 'en',
  GERMAN: 'de',
  SPANISH: 'es',
  FRENCH: 'fr',
  PORTUGESE: 'pt',
} as const;

export type OneOfLocalizations = (typeof availableLocalizations)[keyof typeof availableLocalizations];

const availableLocalizationsList = Object.values(availableLocalizations);

const isValidLocalization = (locale?: string | null): locale is OneOfLocalizations =>
  Boolean(locale && (availableLocalizationsList as string[]).includes(locale));

const LOCALE_COOKIE_KEY = 'locale';
const saveLocaleToCookie = (locale: OneOfLocalizations) =>
  Cookies.set(LOCALE_COOKIE_KEY, locale, {
    domain: cookieDomain,
    path: '/',
  });

const LOCALE_PARAM_KEY = 'lang';

export const getLocaleFromCookie = () => {
  const cookieLocale = Cookies.get(LOCALE_COOKIE_KEY);

  if (isValidLocalization(cookieLocale)) {
    return cookieLocale;
  }
};

const resolveLocale = () => {
  const params = new URLSearchParams(window.location.search);
  const paramsLocale = params.get(LOCALE_PARAM_KEY);

  if (isValidLocalization(paramsLocale)) {
    saveLocaleToCookie(paramsLocale);

    return paramsLocale;
  }

  const cookieLocale = getLocaleFromCookie();

  if (cookieLocale) {
    return cookieLocale;
  }

  for (const availableLocale of availableLocalizationsList) {
    if (navigator.language.startsWith(availableLocale)) {
      saveLocaleToCookie(availableLocale);

      return availableLocale;
    }
  }

  saveLocaleToCookie(availableLocalizations.ENGLISH);

  return availableLocalizations.ENGLISH;
};

interface LocalizationContextType {
  selectedLocalization: OneOfLocalizations;
  availableLocalizations: typeof availableLocalizationsList;
  changeLocalization: (locale: string) => void;
}

const LocaleContext = React.createContext<LocalizationContextType>({
  selectedLocalization: availableLocalizations.ENGLISH,
  availableLocalizations: availableLocalizationsList,
  changeLocalization: () => {},
});

const LocalizationProvider = ({ children }: { children: ReactNode }) => {
  const [selectedLocalization, setSelectedLocale] = useState(resolveLocale());
  const changeLocalization = (newLocale: string) => {
    if (isValidLocalization(newLocale)) {
      setSelectedLocale(newLocale);
      saveLocaleToCookie(newLocale);
    }
  };

  const [messages, setMessages] = useState({});

  useEffect(() => {
    import(`/trans/${selectedLocalization}.json`)
      .then((localeModule) => {
        setMessages(localeModule as Record<string, string>);
      })
      .catch((error: unknown) => {
        console.error(`Error with loading "${selectedLocalization}": ${error}`);
      });
  }, [selectedLocalization]);

  const onProviderError = (err: any) => {
    if (err.code === 'MISSING_TRANSLATION') {
      return;
    }

    console.error(err);
  };

  return (
    <LocaleContext.Provider
      value={{
        selectedLocalization,
        availableLocalizations: availableLocalizationsList,
        changeLocalization,
      }}
    >
      <IntlProvider
        messages={messages}
        locale={selectedLocalization}
        defaultLocale={availableLocalizations.ENGLISH}
        onError={onProviderError}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default LocalizationProvider;

export const useLocale = () => useContext(LocaleContext);
