import { Switch, Redirect, Route } from 'react-router-dom';
import { isLoggedIn } from './utils/auth';
import { usePageViews, useClientID } from './utils/analyticsHooks';
import EnterRoutes from './routes/Enter';
import ProfileRoutes from './routes/Profile';
import RedirectRoutes from './routes/h';
import PaymentRoutes from './routes/Payment';
import SubscriptionConfirmedRoute from './routes/Email/SubscriptionConfirmed';
import DiiaSuccessRoute from './routes/DiiaSuccessRoute';
import AuthRoute from './routes/common/AuthRoute';
import ChangeEmailRoutes from './routes/change-email';

const App = () => {
  useClientID();
  usePageViews();

  return (
    <Switch>
      <Route path="/enter">
        <EnterRoutes />
      </Route>

      <Route path="/profile">
        <ProfileRoutes />
      </Route>

      <Route path="/h">
        <RedirectRoutes />
      </Route>

      <Route path="/payment">
        <PaymentRoutes />
      </Route>

      <Route path="/change-email">
        <ChangeEmailRoutes />
      </Route>

      <Route path="/promo">
        <Redirect to="https://clearvpn.com/redeem/" />
      </Route>

      <Route exact path="/email/subscription-confirmed">
        <SubscriptionConfirmedRoute />
      </Route>

      <Route exact path="/diia/success">
        <DiiaSuccessRoute />
      </Route>

      <AuthRoute exact path="/account-termination">
        <Redirect to="/profile" />
      </AuthRoute>

      <Route>
        <Redirect to={isLoggedIn() ? '/profile' : '/enter'} />
      </Route>
    </Switch>
  );
};

export default App;
