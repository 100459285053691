import React from 'react';
import t from 'prop-types';
import styles from './InputField.scss';

/**
 * @deprecated - use TextInput from '../otc/components/TextInput' instead
 * @param {string} value - current input value
 * @param {function} onChange
 * @param {string} [label]
 * @param {string} [placeholder]
 * @param {string} [error] - text of the error, ignored if empty string
 * @param {string} [errorClassName] - CSS class applied to error subtext
 * @returns {JSX.Element}
 */
const InputField = ({ value, onChange, label = '', placeholder = '', error, errorClassName = '' }) => (
  <div className={styles.field}>
    {label ? <label>{label}</label> : null}

    <div className={styles.wrapper}>
      <input type="text" placeholder={placeholder} value={value} onChange={onChange} />
    </div>

    <div className={`${styles.error} ${errorClassName}`}>{error}</div>
  </div>
);

InputField.propTypes = {
  value: t.string.isRequired,
  onChange: t.func.isRequired,
  label: t.string,
  placeholder: t.string,
  error: t.oneOfType([t.string, t.element]),
  errorClassName: t.string,
};

export default InputField;
