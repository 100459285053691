import { useIntl } from 'react-intl';
import { Switch, useRouteMatch } from 'react-router-dom';
import Route from '../common/Route';
import ChangeEmailRoute from './ChangeEmailRoute';
import ConfirmChangeEmailRoute from './ConfirmChangeEmailRoute';

const ChangeEmailRoutes = () => {
  const intl = useIntl();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={path}
        title={intl.formatMessage({
          id: 'changeEmail.seo.title',
          defaultMessage: 'ClearVPN | Change email',
        })}
      >
        <ChangeEmailRoute />
      </Route>

      <Route
        exact
        path={`${path}/confirm`}
        title={intl.formatMessage({
          id: 'changeEmailConfirm.seo.title',
          defaultMessage: 'ClearVPN | Confirm change email',
        })}
      >
        <ConfirmChangeEmailRoute />
      </Route>
    </Switch>
  );
};

export default ChangeEmailRoutes;
