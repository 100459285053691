import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import EnterLayout from '../../components/layout/EnterLayout';
import Alert from '../../components/common/Alert';
import useQuery from '../../utils/useQuery';
import { browserDeeplink } from '../../../lib/browser-deeplink';
import { logMessage } from '../../services/errorReporting';
import fixelWatPic from '../../../assets/icons/fixel-wat.svg';

const AppErrorRoute = () => {
  const intl = useIntl();
  const query = useQuery();

  const [title, setTitle] = useState(
    intl.formatMessage({
      id: 'appError.somethingWrongTitle',
      defaultMessage: 'Something went wrong',
    }),
  );
  const [text, setText] = useState(
    intl.formatMessage({
      id: 'appError.somethingWrongText',
      defaultMessage: 'Please, try again later or contact support',
    }),
  );

  const [appLink, setAppLink] = useState('clearvpn://');

  useEffect(() => {
    console.log(query.err_message);
    const error = query['err_message'];

    if (error === 'ErrAccountAlreadyExists') {
      setTitle(
        intl.formatMessage({
          id: 'appError.accountAlreadyExistsTitle',
          defaultMessage: 'This account already exists',
        }),
      );

      setText(
        intl.formatMessage({
          id: 'appError.accountAlreadyExistsText',
          defaultMessage:
            'There is an account with these credentials, and it might belong to you. Try using another email or contact support to transfer your Premium plan to your old account.',
        }),
      );
    }

    const appIdentity = query['app-identity'];

    if (appIdentity === 'com.macpaw.clearvpn.macosmas') {
      setAppLink('clearvpnmas://');
    }

    logMessage(`App error: ${error}`, { params: window.location.search });
  }, []);

  return (
    <EnterLayout verticalPaddings={[90, 60]}>
      <Alert
        href="https://support.clearvpn.com/hc/?open_chat=1"
        title={title}
        description={text}
        customIcon={<img src={fixelWatPic} width={162} height={154} alt="" />}
        actionTitle={intl.formatMessage({
          id: 'appError.supportCTA',
          defaultMessage: 'Contact support',
          description: 'Link to support portal',
        })}
        footerChild={
          <button onClick={() => browserDeeplink(appLink)}>
            <FormattedMessage id="appError.backToAppButton" defaultMessage="Back to app" />
          </button>
        }
      />
    </EnterLayout>
  );
};

export default AppErrorRoute;
