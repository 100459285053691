import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import Loader from '../../../common/Loader';
import Modal from '../../../common/Modal';
import { Button } from '../../../common/Button';
import { sendChangeEmailRequest } from '../../../../utils/api/accountApi';
import { genericErrorMessage } from '../../../../utils/lang/commonErrors';
import hooverOkayImage from '../../../../../assets/icons/change-email/hoover-okay.svg';
import hooverNoImage from '../../../../../assets/icons/account-termination/hoover-no.svg';
import styles from './ChangeEmailModal.scss';

interface ChangeEmailModalProps {
  isOpen: boolean;
  onConfirm: () => void;
}

const ChangeEmailModal = ({ isOpen, onConfirm }: ChangeEmailModalProps) => {
  const intl = useIntl();

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isOpen) {
      sendChangeEmailRequest().then(
        () => {
          setLoading(false);
        },
        () => {
          setLoading(false);
          setError(intl.formatMessage(genericErrorMessage));
        },
      );
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onConfirm}>
      {isLoading ? (
        <Loader colored />
      ) : error ? (
        <>
          <img src={hooverNoImage} width="196" height="147" alt="" />
          <span className={styles.error}>{error}</span>
          <Button className={styles.errorCloseButton} onClick={onConfirm}>
            <FormattedMessage id="profile.accountDetails.changeEmailModal.closeButton" defaultMessage="Close" />
          </Button>
        </>
      ) : (
        <>
          <img src={hooverOkayImage} width="162" height="130" alt="" />

          <h3 className={styles.title}>
            <FormattedMessage id="profile.accountDetails.changeEmailModal.title" defaultMessage="Change email" />
          </h3>

          <p className={styles.text}>
            <FormattedMessage
              id="profile.accountDetails.changeEmailModal.text"
              defaultMessage="We've sent a confirmation email to your new address. Please check your mailbox and confirm the change"
            />
          </p>

          <Button className={styles.confirmButton} onClick={onConfirm}>
            <FormattedMessage id="profile.accountDetails.changeEmailModal.confirmButton" defaultMessage="OK" />
          </Button>
        </>
      )}
    </Modal>
  );
};

export default ChangeEmailModal;
