import React from 'react';
import EnterLayout from '../../components/layout/EnterLayout';
import SuccessAlert from '../../components/Payment/SuccessAlert';

const Success = () => (
  <EnterLayout showBackToProfile>
    <SuccessAlert />
  </EnterLayout>
);

export default Success;
