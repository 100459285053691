import { type UserProfile } from '../../utils/api/schema/profileSchema';
import { isDiiaUser } from '../../utils/billingInfoParser';

export const profileSections = {
  ACCOUNT: 'ACCOUNT',
  BILLING: 'BILLING',
  DEVICES: 'DEVICES',
  REFERAL: 'REFERAL',
} as const;

export type OneOfProfileSections = (typeof profileSections)[keyof typeof profileSections];

export const isReferralSectionAvailable = (profile: UserProfile) => {
  if (isDiiaUser(profile) || profile.role === 'account_role_guest') {
    return false;
  }

  if (profile.type === 'setapp' || profile.type === 'guest') {
    return false;
  }

  if (profile.subscription?.hasActiveSubscription && profile.subscription.vendor !== 'chargebee') {
    return false;
  }

  // eslint-disable-next-line sonarjs/prefer-single-boolean-return,sonarjs/prefer-single-boolean-return, @typescript-eslint/prefer-optional-chain -- this becomes less readable if minified,
  if (profile.email && profile.email.endsWith('@anonymous.device')) {
    return false;
  }

  return true;
};
