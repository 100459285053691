import { FormattedMessage } from 'react-intl';
import { useDownloadToken } from '../../../utils/downloadToken';
import BlockTitle from '../layout/BlockTitle';
import GrayBox from '../layout/GrayBox';
import LinkCurrentDeviceButton from './components/LinkCurrentDeviceButton';
import styles from './AutoLoginBlock.scss';

interface AutoLoginBlockProps {
  isSetappSubscription: boolean;
}

const AutoLoginBlock = ({ isSetappSubscription }: AutoLoginBlockProps) => {
  const { downloadToken, refreshDownloadToken } = useDownloadToken();

  if (isSetappSubscription) {
    return null;
  }

  return (
    <div>
      <BlockTitle>
        <FormattedMessage
          id="profile.deviceManagement.addCurrentDevice.title"
          defaultMessage="Automatically log in to your account on a new device"
        />
      </BlockTitle>
      <GrayBox className={styles.autoLoginSection}>
        <p className={styles.autoLoginSectionText}>
          <FormattedMessage
            id="profile.deviceManagement.addCurrentDevice.text"
            defaultMessage="Auto login allows you to enjoy ClearVPN across devices without entering credentials every time."
          />
        </p>

        <LinkCurrentDeviceButton downloadToken={downloadToken} refreshDownloadToken={refreshDownloadToken}>
          <FormattedMessage
            id="profile.deviceManagement.addCurrentDevice.button"
            defaultMessage="Connect my device"
            description="Button that opens unlink device modal"
          />
        </LinkCurrentDeviceButton>
      </GrayBox>
    </div>
  );
};

export default AutoLoginBlock;
