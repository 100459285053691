import React from 'react';
import styles from './Loader.scss';

export interface LoaderProps {
  /** width and height of the radial router */
  size?: number;
  /** stroke width of the spinning line */
  stroke?: number;
  /** render main color loader instead of white */
  colored?: boolean;
  centered?: boolean;
}

const Loader = ({ size = 25, stroke = 3, colored, centered }: LoaderProps) => (
  <div
    style={{ width: `${size}px`, height: `${size}px` }}
    className={`${styles.loader} ${styles[`loaderStroke${stroke}`]} ${colored && styles.colored} ${
      centered && styles.centered
    }`}
  >
    <div style={{ width: `${size}px`, height: `${size}px` }} />
    <div style={{ width: `${size}px`, height: `${size}px` }} />
    <div style={{ width: `${size}px`, height: `${size}px` }} />
    <div style={{ width: `${size}px`, height: `${size}px` }} />
  </div>
);

export default Loader;
