import clsx from 'clsx';
import styles from './ToggleMenuButton.scss';

interface ToggleMenuButtonProps {
  isOpen: boolean;
  onClick: () => void;
  className?: string;
}

const ToggleMenuButton = ({ isOpen, onClick, className }: ToggleMenuButtonProps) => (
  <button className={clsx(styles.button, isOpen && styles.open, className)} onClick={onClick}>
    <div className={clsx(styles.stick, styles.stick1)} />
    <div className={clsx(styles.stick, styles.stick2)} />
    <div className={clsx(styles.stick, styles.stick3)} />
  </button>
);

export default ToggleMenuButton;
