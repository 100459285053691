import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultLinks as downloadLinks } from '../../../../../utils/downloadLinks';
import googlePayButton from '../../../../../../assets/icons/download-modal/google-play-button.svg';
import appStoreButton from '../../../../../../assets/icons/download-modal/app-store-button.svg';
import mobileMockUp from '../../../../../../assets/icons/download-modal/mobile.png';
import mobileMockUp2x from '../../../../../../assets/icons/download-modal/mobile@2x.png';
import styles from './MobileInstallCol.scss';

const MobileInstallCol = () => {
  return (
    <section className={styles.mobile}>
      <h3>
        <FormattedMessage
          id="profile.downloadModal.mobileSlide.install.title"
          defaultMessage="1. Get ClearVPN for Mobile"
          description="Title of the install column instructions for mobile slide of Download Modal"
        />
      </h3>
      <span>
        <FormattedMessage
          id="profile.downloadModal.mobileSlide.install.subtitle"
          defaultMessage="Available for Android And iPhone"
          description="Subtitle of the mobile half of Download Modal"
        />
      </span>

      <div className={styles.mobileStoreButtons}>
        <a href={downloadLinks.android} title="Download ClearVPN on Google Play">
          <img src={googlePayButton} width="106" height="32" alt="Download ClearVPN on Google Play" />
        </a>

        <a href={downloadLinks.ios} title="Download ClearVPN on AppStore">
          <img src={appStoreButton} width="106" height="32" alt="Download ClearVPN on AppStore" />
        </a>
      </div>

      <img
        src={mobileMockUp}
        srcSet={`${mobileMockUp2x}, 2x`}
        width="327"
        height="180"
        alt="An iPhone alongside an Android phone running ClearVPN"
        className={styles.mockUp}
      />
    </section>
  );
};

export default MobileInstallCol;
