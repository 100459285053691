import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { sendRemoveDeviceRequest } from '../../../utils/api/accountApi';
import { type UserProfile } from '../../../utils/api/schema/profileSchema';
import { Button } from '../../common/Button';
import UnlinkDeviceDialog from './components/UnlinkDeviceDialog';
import AndroidIcon from '../../../../assets/icons/platforms/Android.svg';
import iOSIcon from '../../../../assets/icons/platforms/iOS.svg';
import MacOSIcon from '../../../../assets/icons/platforms/macOS.png';
import WindowsIcon from '../../../../assets/icons/platforms/Windows.svg';
import iPadIcon from '../../../../assets/icons/platforms/iPad.png';
import AddDeviceIcon from '../../../../assets/icons/add-device-icon.svg';
import styles from './DeviceManagement.scss';

const getPlatformIcon = (platform: string, deviceName: string) => {
  switch (platform.toLowerCase()) {
    case 'android': {
      return AndroidIcon;
    }
    case 'ios': {
      if (deviceName.toLowerCase().includes('ipad')) {
        return iPadIcon;
      }

      return iOSIcon;
    }
    case 'macos': {
      return MacOSIcon;
    }
    case 'windows': {
      return WindowsIcon;
    }

    default: {
      return '';
    }
  }
};

type ProfileDevice = UserProfile['devices'][number];
interface RenderedDeviceItem extends ProfileDevice {
  isLoading?: boolean;
}

interface DeviceManagementProps {
  devices: RenderedDeviceItem[];
  isSetappSubscription: boolean;
  onAddNewDeviceClick: () => void;
}

const DeviceManagementBlock = ({ devices, isSetappSubscription, onAddNewDeviceClick }: DeviceManagementProps) => {
  const intl = useIntl();

  const [list, setList] = useState(devices);

  const [isUnlinkDialogVisible, setUnlinkDeviceVisible] = useState(false);
  const [unlinkError, setUnlinkError] = useState('');
  const [deletedDeviceId, setDeletedDeviceId] = useState('');

  const [maxDevicesPerAccount, setMaxDevicesPerAccount] = useState(isSetappSubscription ? 8 : 6);

  useEffect(() => {
    setMaxDevicesPerAccount(isSetappSubscription ? 8 : 6);
  }, [isSetappSubscription]);

  const onUnlinkDeviceClick = (id: string) => {
    setDeletedDeviceId(id);
    setUnlinkDeviceVisible(true);
  };

  const unlinkDevice = (id: string) => {
    setList(
      list.map((d) => {
        if (d.id === id) {
          d.isLoading = true;
        }

        return d;
      }),
    );

    return sendRemoveDeviceRequest(id)
      .then(() => {
        setList(list.filter((d) => d.id !== id));
        setUnlinkDeviceVisible(false);
        setUnlinkError('');
        setDeletedDeviceId('');
      })
      .catch(() => {
        setUnlinkError(
          intl.formatMessage({
            id: 'profile.deviceManagement.error.deviceUnlinking',
            defaultMessage: 'Unlinking failed, please try again later',
            description: 'Error message shown on a failed attempt to remove device from the account',
          }),
        );
      });
  };
  const onCancelUnlink = (id: string) => {
    setList(
      list.map((d) => {
        if (d.id === id) {
          d.isLoading = false;
        }

        return d;
      }),
    );
    setUnlinkDeviceVisible(false);
  };

  return (
    <section className={styles.section}>
      {list.map((device) => (
        <div className={styles.item} key={device.id}>
          <div className={styles.device}>
            <img src={getPlatformIcon(device.platform, device.name)} className={styles.itemImage} alt="" />

            <div className={styles.deviceTitle}>{device.name}</div>

            <div className={styles.unlinkLine}>
              <Button
                className={styles.unlinkButton}
                kind="white"
                buttonSize="small"
                onClick={() => {
                  onUnlinkDeviceClick(device.id);
                }}
              >
                <FormattedMessage
                  id="profile.deviceManagement.unpairButton"
                  defaultMessage="Unpair"
                  description="Button to remove selected device from the account"
                />
              </Button>
            </div>
          </div>
        </div>
      ))}

      {!isSetappSubscription && list.length < maxDevicesPerAccount && (
        <button
          className={`${styles.item} ${styles.addNewDevice} ${list.length === 0 ? styles.addNewItem : ''}`}
          onClick={onAddNewDeviceClick}
        >
          <div className={styles.device}>
            <img src={AddDeviceIcon} width="24" height="24" alt="" />
            <span>
              <FormattedMessage
                id="profile.deviceManagement.addNewDeviceButton"
                defaultMessage="Add new device"
                description="Button to add a device to the account"
              />
            </span>
          </div>
        </button>
      )}

      <UnlinkDeviceDialog
        visible={isUnlinkDialogVisible}
        error={unlinkError}
        onUnlink={() => unlinkDevice(deletedDeviceId)}
        onCancel={() => {
          onCancelUnlink(deletedDeviceId);
        }}
      />
    </section>
  );
};

export default DeviceManagementBlock;
