const EXTENTION_ID = 'pcjcjlcjmooighgkjafnogbidgmdhbdi';

const webStoreLink = `https://chromewebstore.google.com/detail/clearweb/${EXTENTION_ID}`;

const extensionSettingURL = `chrome-extension://${EXTENTION_ID}/dashboard.html`;

const checkIfChromeExtensionAPIExposed = () => fetch(extensionSettingURL).then((res) => res.ok);

const openExtensionSettingsPage = (params?: URLSearchParams) => {
  window.location.href = params?.toString() ? `${extensionSettingURL}?${params.toString()}` : extensionSettingURL;
};

export { webStoreLink, checkIfChromeExtensionAPIExposed, openExtensionSettingsPage };
