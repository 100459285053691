import { logError } from '../services/errorReporting';
import { sendDownloadAppInstallerRequest } from './api/marketingApi';
import { runGACommand } from './analyticsHooks';

export interface DownloadLinks {
  ios: string;
  macos: string;
  windows: string;
  android: string;
}

const defaultLinks: DownloadLinks = {
  ios: 'https://clearvpn.onelink.me/uPP4/1q2xto27',
  macos: 'https://dl.devmate.com/com.macpaw.clearvpn.macos-site-ver/ClearVPN.dmg',
  windows: 'https://s3.amazonaws.com/clearvpn.com/downloads/windows/SetupClearVPN.exe',
  android: 'https://clearvpn.onelink.me/uPP4/1q2xto27',
};

const fetchWindowsDownloadLink = (clientId?: string, query = '') => {
  const completeParams = new URLSearchParams(query);

  if (clientId) {
    completeParams.append('cid', clientId);
  }

  return sendDownloadAppInstallerRequest('com.macpaw.clearvpn.windows', completeParams.toString()).then((response) =>
    atob(response.data.download_url),
  );
};

/**
 * Attempts to resolve GA `cid` and fetch personalized download links,
 * that have `cid` and `queryString` params appended.
 */
const fetchPersonalizedLinks = (queryString = ''): Promise<DownloadLinks> => {
  let clientId: string;

  return runGACommand()
    .then(
      (ga) => ga.getAll()[0].get('clientId'),
      () => null,
    )
    .then((cid: string | null) => {
      clientId = cid ?? '';

      return fetchWindowsDownloadLink(clientId, queryString);
    })
    .catch((error: unknown) => {
      // in case fetching Windows download link fails, we can still append `cid` to the rest of the links
      logError(new Error('Windos download ling fetching failed:', { cause: error }));

      return defaultLinks.windows;
    })
    .then((windowsDownloadURL) => {
      const completeParams = new URLSearchParams(queryString);

      if (clientId) {
        completeParams.append('cid', clientId);
      }

      return {
        ios: `${defaultLinks.ios}?${completeParams}`,
        macos: `${defaultLinks.macos}?${completeParams}`,
        windows: windowsDownloadURL,
        android: `${defaultLinks.android}?${completeParams}`,
      };
    });
};

/**
 * Fetches personalized download links with appended `appUrl` deeplink
 */
const fetchPersonalizedLinksWithAppUrl = (appUrl: URL): Promise<DownloadLinks> =>
  fetchPersonalizedLinks(new URLSearchParams({ app_url: appUrl.toString() }).toString()).then((downloadLinks) => {
    const androidUrl = new URL(downloadLinks.android);

    androidUrl.searchParams.delete('app_url');
    androidUrl.searchParams.append('af_dp', appUrl.toString());
    androidUrl.searchParams.append('deep_link_value', appUrl.toString());

    downloadLinks.android = androidUrl.toString();

    return downloadLinks;
  });

export { defaultLinks, fetchPersonalizedLinks, fetchPersonalizedLinksWithAppUrl };
