import { getCaptchaToken, CAPTCHA_KEY } from '../captchaHelper';
import { clearApiClient } from './clearApiClient';

/**
 * @returns personalized link to order page
 */
export const sendPaymentLinkRequest = () => clearApiClient.get<{ link: string }>('/web/v1/store/payment-link');

/**
 * @returns discount coupon (if there is one) attached to user's account
 */
export const sendAccountCouponRequest = () =>
  clearApiClient.post<{ coupon?: string }>('/web/v1/store/coupon/get-account-coupon');

/**
 * Renews a previously discontinued subscription.
 */
export const sendRenewSubscriptionRequest = (subscriptionId: string) =>
  clearApiClient.post('/web/v1/store/subscription/renew', { subscription_id: subscriptionId });

/**
 * Cancels subscription.
 */
export const sendCancelSubscriptionRequest = (subscriptionId: string) =>
  clearApiClient.post('/web/v1/store/cancel-subscription', { subscription_id: subscriptionId });

interface PaymentIntentSetupResponse {
  requires_action: boolean;
  stripe_payment_setup_intent_id: string;
  stripe_payment_setup_intent_client_secret: string;
}

/**
 * Creates a Payment Setup Intent on backend.
 * Docs: https://stripe.com/docs/payments/setup-intents
 */
export const sendPaymentSetupIntentRequest = (paymendMethodId: string) =>
  getCaptchaToken().then((token) =>
    clearApiClient.post<PaymentIntentSetupResponse>('/web/v1/store/stripe/payment-setup-intent/create', {
      stripe_payment_method_id: paymendMethodId,
      product_id: 'product_id', // whatever but not an empty string
      recaptcha_token: token,
      recaptcha_site_key: CAPTCHA_KEY,
    }),
  );

/**
 * Updates payment method associated with the current user's subscription.
 */
export const sendUpdatePaymentMethodRequest = (paymentSetupIntentId: string, paymentMethodType: string) =>
  clearApiClient.post('/web/v1/store/chargebee/subscription/update-payment', {
    stripe_payment_intent_id: paymentSetupIntentId,
    payment_method: paymentMethodType,
  });
