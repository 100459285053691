const ReferralIcon = ({ isSelected }: { isSelected: boolean }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g
      id="gift"
      clipPath="url(#clip0_471_5054)"
      fill={isSelected ? '#ED3984' : '#212231'}
      fillOpacity={isSelected ? 1 : 0.64}
    >
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33333 9.16663C3.79357 9.16663 4.16667 9.53972 4.16667 9.99996V17.5H15.8333V9.99996C15.8333 9.53972 16.2064 9.16663 16.6667 9.16663C17.1269 9.16663 17.5 9.53972 17.5 9.99996V18.3333C17.5 18.7935 17.1269 19.1666 16.6667 19.1666H3.33333C2.8731 19.1666 2.5 18.7935 2.5 18.3333V9.99996C2.5 9.53972 2.8731 9.16663 3.33333 9.16663Z"
      />
      <path
        id="Vector (Stroke)_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833374 5.83333C0.833374 5.3731 1.20647 5 1.66671 5H18.3334C18.7936 5 19.1667 5.3731 19.1667 5.83333V10C19.1667 10.4602 18.7936 10.8333 18.3334 10.8333H1.66671C1.20647 10.8333 0.833374 10.4602 0.833374 10V5.83333ZM2.50004 6.66667V9.16667H17.5V6.66667H2.50004Z"
      />
      <path
        id="Vector (Stroke)_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99996 5C10.4602 5 10.8333 5.3731 10.8333 5.83333V18.3333C10.8333 18.7936 10.4602 19.1667 9.99996 19.1667C9.53972 19.1667 9.16663 18.7936 9.16663 18.3333V5.83333C9.16663 5.3731 9.53972 5 9.99996 5Z"
      />
      <path
        id="Vector (Stroke)_4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25004 2.50004C5.91852 2.50004 5.60058 2.63174 5.36616 2.86616C5.13174 3.10058 5.00004 3.41852 5.00004 3.75004C5.00004 4.08156 5.13174 4.3995 5.36616 4.63392C5.60058 4.86834 5.91852 5.00004 6.25004 5.00004H8.87839C8.77666 4.73389 8.64788 4.44122 8.48861 4.15164C7.96447 3.19866 7.24496 2.50004 6.25004 2.50004ZM10 5.83337C10.8172 5.66994 10.8171 5.66966 10.8171 5.66936L10.8169 5.66868L10.8166 5.66702L10.8157 5.66257L10.8129 5.64915C10.8106 5.63824 10.8074 5.62341 10.8033 5.60494C10.795 5.56802 10.783 5.51642 10.767 5.45222C10.7349 5.32401 10.6864 5.14456 10.6187 4.93076C10.4842 4.50614 10.2695 3.93116 9.94897 3.34844C9.32728 2.21809 8.17179 0.833374 6.25004 0.833374C5.47649 0.833374 4.73463 1.14066 4.18765 1.68765C3.64066 2.23463 3.33337 2.97649 3.33337 3.75004C3.33337 4.52359 3.64066 5.26545 4.18765 5.81244C4.73463 6.35942 5.47649 6.66671 6.25004 6.66671H10C10.2497 6.66671 10.4862 6.55477 10.6445 6.3617C10.8028 6.16862 10.8662 5.91476 10.8172 5.66994L10 5.83337Z"
      />
      <path
        id="Vector (Stroke)_5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.051 3.34844C10.6727 2.21809 11.8282 0.833374 13.75 0.833374C14.5235 0.833374 15.2654 1.14066 15.8124 1.68765C16.3593 2.23463 16.6666 2.97649 16.6666 3.75004C16.6666 4.52359 16.3593 5.26545 15.8124 5.81244C15.2654 6.35942 14.5235 6.66671 13.75 6.66671H9.99997C9.75031 6.66671 9.5138 6.55477 9.35551 6.3617C9.19723 6.16862 9.13385 5.91476 9.18282 5.66994L9.99997 5.83337C9.18282 5.66994 9.18287 5.66966 9.18293 5.66936L9.18307 5.66868L9.1834 5.66702L9.18431 5.66257L9.18711 5.64915C9.18942 5.63824 9.19261 5.62341 9.19674 5.60494C9.20499 5.56802 9.21697 5.51642 9.23302 5.45222C9.26507 5.32401 9.31359 5.14456 9.3813 4.93076C9.51576 4.50614 9.73054 3.93116 10.051 3.34844ZM11.1216 5.00004H13.75C14.0815 5.00004 14.3994 4.86834 14.6338 4.63392C14.8683 4.3995 15 4.08156 15 3.75004C15 3.41852 14.8683 3.10058 14.6338 2.86616C14.3994 2.63174 14.0815 2.50004 13.75 2.50004C12.755 2.50004 12.0355 3.19866 11.5114 4.15164C11.3521 4.44122 11.2233 4.73389 11.1216 5.00004ZM10.8168 5.99821C10.8168 5.99843 10.8168 5.99841 10.8168 5.99814L10.8168 5.99821Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_471_5054">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ReferralIcon;
