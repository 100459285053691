const destinations = { ORDER: 'order', CHROME: 'chrome' } as const;

// left old values here, in case some users are stuck midloop
const storageKeys = {
  DESTINATION: 'fromOrderDestination',
  PARAMS: 'fromOrderParams',
  PATHNAME: 'fromOrderPathname',
} as const;

export const saveFromOrderAuthParams = () => {
  // shredding previous save, 'cause it's Enter re-entry
  localStorage.removeItem(storageKeys.DESTINATION);
  localStorage.removeItem(storageKeys.PARAMS);
  localStorage.removeItem(storageKeys.PATHNAME);

  const params = new URLSearchParams(location.search);

  const fromParam = params.get('from');

  if (fromParam) {
    if (fromParam.startsWith(destinations.ORDER)) {
      params.delete('from');
      localStorage.setItem(storageKeys.DESTINATION, destinations.ORDER);
      localStorage.setItem(storageKeys.PARAMS, params.toString());
      localStorage.setItem(storageKeys.PATHNAME, fromParam.slice(5)); // cuts of `order` from `order?????`
    } else if (fromParam.startsWith(destinations.CHROME)) {
      params.delete('from');
      localStorage.setItem(storageKeys.DESTINATION, destinations.CHROME);
    }
  }
};

export const interceptAuthFromOrder = () => {
  const redirectDestination = localStorage.getItem(storageKeys.DESTINATION);
  const redirectParams = localStorage.getItem(storageKeys.PARAMS);

  localStorage.removeItem(storageKeys.DESTINATION);
  localStorage.removeItem(storageKeys.PARAMS);

  if (redirectDestination === destinations.ORDER) {
    const orderPagePath = localStorage.getItem(storageKeys.PATHNAME) || '/guest-upgrade/';

    localStorage.removeItem(storageKeys.PATHNAME);

    if (redirectParams) {
      window.location.href = `${process.env.ORDER_PAGE_URL}${orderPagePath}?${redirectParams}`;

      return true;
    }
  } else if (redirectDestination === destinations.CHROME) {
    window.location.href = redirectParams ? `/h/to-chrome?${redirectParams}` : `/h/to-chrome`;

    return true;
  }
};
