import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Cookies from 'js-cookie';
import useQuery from '../../utils/useQuery';
import { sendPermTokenRequest } from '../../utils/api/accountApi';
import { sendProfileRequest } from '../../utils/api/profileApi';
import { getAccessToken, getRefreshToken } from '../../utils/auth';
import { getRandomLoadingFact } from '../../utils/paymentSuccessFacts';
import Alert from '../common/Alert';
import PendingAlert from './PendingAlert';

const profileCheckInterval = 2000;
const maxCheckDuration = 30_000;

const SuccessAlert = () => {
  const query = useQuery();
  const intl = useIntl();

  const [emailLink, setEmailLink] = useState();

  useEffect(() => {
    const emailValue = Cookies.get('email');

    if (emailValue && emailValue.endsWith('@gmail.com')) {
      setEmailLink({
        href: 'https://mail.google.com/mail/u/0/#search/from%3A%40clearvpn.com+in%3Aanywhere',
        label: intl.formatMessage({
          id: 'paymentSuccess.cta.goToInbox',
          defaultMessage: 'Go to your inbox',
          description: 'Link to user’s inbox with applied search filter',
        }),
      });
    } else if (emailValue && emailValue.endsWith('@yahoo.com')) {
      setEmailLink({
        href: 'https://mail.yahoo.com/d/search/keyword=from%253Aclearvpn.com',
        label: intl.formatMessage({
          id: 'paymentSuccess.cta.goToInbox',
          defaultMessage: 'Go to your inbox',
          description: 'Link to user’s inbox with applied search filter',
        }),
      });
    }
  }, []);

  const appURL = `https://my.clearvpn.com/h/redirect-to-app?app_url=clearvpn://go/auth&access_token=${getAccessToken()}&refresh_token=${getRefreshToken()}`;

  const [isTimerRunning, setTimerRunning] = useState(false);
  const [isTimeoutDone, setTimeOutDone] = useState(false);
  const [isSubsSyncError, setSubsSyncError] = useState(false);

  useEffect(() => {
    const startFallbackTimeout = () => {
      setTimerRunning(true);
      setTimeout(() => setTimeOutDone(true), 12_000);
    };

    if (query.appIdentity) {
      try {
        const decodedParam = atob(unescape(query.appIdentity));
        const [appIdentity, accountId, permanentToken] = decodedParam.split('|');

        if (appIdentity && accountId && permanentToken) {
          // logging in with token
          sendPermTokenRequest(permanentToken)
            .then(() => {
              // setting up interval for requesting `/profile` every 2 secs
              let cycleCounter = 0;
              const maxCycles = maxCheckDuration / profileCheckInterval;

              const interval = setInterval(() => {
                sendProfileRequest(false, { shouldDropToLogin: false })
                  .then((data) => {
                    // if subs is active, clearing everything and showing `To App` page
                    const { profile } = data;

                    if (
                      profile.subscription.hasActiveSubscription ||
                      (profile.state === 'active' && profile.type === 'subscription')
                    ) {
                      clearInterval(interval);
                      setTimeOutDone(true);
                    }

                    cycleCounter += 1;
                    if (cycleCounter > maxCycles) {
                      clearInterval(interval);
                      setSubsSyncError(true);
                    }
                  })
                  // if profile fetching fails, clearing interval showing error
                  .catch(() => {
                    clearInterval(interval);
                    console.log('Failed to get profile');
                    setSubsSyncError(true);
                  });
              }, profileCheckInterval);
            })
            .catch(() => {
              console.log('Failed to get token');
              startFallbackTimeout();
            });
        } else {
          // if any of the params is missing, the token is automatically invalid, 'cause of array destructing
          console.log("Param doesn't have all the data");
          startFallbackTimeout();
        }
      } catch {
        // should only be here if Base64 decoding fails
        console.log('Failed to decode param');
        startFallbackTimeout();
      }
    }
  }, []);

  if (isSubsSyncError) {
    return (
      <Alert
        icon="error"
        title={intl.formatMessage({
          id: 'paymentSuccess.title.error.subscriptionStatusUpdateFailed',
          defaultMessage: 'Could not update subscription status',
          description: 'Error title of the page, in case sync of subscription status failed',
        })}
        footerChild={
          <FormattedMessage
            id="paymentSuccess.tryAgainOrContactSupport"
            defaultMessage="Please try again later or <link>contact support</link>"
            description="Banner shown under the error alert"
            values={{
              link: (chunk) => <a href="https://support.clearvpn.com/hc/en-us/requests/new">{chunk}</a>,
            }}
          />
        }
      />
    );
  }
  if (isTimeoutDone) {
    return (
      <Alert
        icon="success"
        href={appURL}
        title={intl.formatMessage({
          id: 'paymentSuccess.title.success',
          defaultMessage: 'Thanks for choosing ClearVPN!',
          description: 'Page title confirming successful purchase',
        })}
        description={intl.formatMessage({
          id: 'paymentSuccess.subtitle.success',
          defaultMessage: 'Get ready for the personalized, unlimited and secure internet experience.',
          description: 'Page subtitle confirming successful purchase',
        })}
        actionTitle={intl.formatMessage({
          id: 'paymentSuccess.cta.success',
          defaultMessage: 'Go to the app',
          description: 'Button opening up ClearVPN app',
        })}
      />
    );
  }
  if (isTimerRunning) {
    return (
      <PendingAlert
        description={getRandomLoadingFact(intl)}
        title={intl.formatMessage({
          id: 'paymentSuccess.title.loading',
          defaultMessage: 'We’re processing your payment.',
          description: 'Page title shown while payment status is syncing',
        })}
      />
    );
  } // should only be possible, when query.appIdentity is undefined

  return (
    <Alert
      icon="success"
      actionTitle={emailLink?.label}
      href={emailLink?.href}
      title={intl.formatMessage({
        id: 'paymentSuccess.title.fallback',
        defaultMessage: 'Congratulations!\n\rYou’re now a ClearVPN Premium user.',
        description: 'Fallback page title shown when subscription status has been confirmed beforehand',
      })}
      description={intl.formatMessage({
        id: 'paymentSuccess.subtitle.fallback',
        defaultMessage:
          'To finish your registration and download ClearVPN, please follow the steps in the email we’ve sent.',
        description:
          'Fallback page title shown when subscription status has been confirmed beforehand, directs user to email',
      })}
    />
  );
};

export default SuccessAlert;
